import actions from "./actions";

export default {
  namespaced: true,
  state: {
    profile: [],
    dataLoading: false
  },
  getters: {
    profile: state => {
      return state.profile;
    },

    dataLoading: state => {
      return state.dataLoading;
    }
  },
  mutations: {
    setProfile(state, value) {
      state.profile = value;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    }
  },
  actions
};