<template>
  <div class="footer">
    <div
      v-if="divider"
      class="divider"
    />
    <div class="info">
      <div class="copyright">
        © 2022 - {{ new Date().getFullYear() }} «Autentic»
      </div>

      <div class="links">
        <div class="media">
          <AuIcon
            icon="discord"
            class="btn"
            is-link
            href="https://discord.gg/8f854ZmVgK"
            target="_blank"
          />
          <AuIcon
            v-if="language == 'ru'"
            icon="vk"
            class="btn"
            is-link
            href="https://vk.com/public217319173"
            target="_blank"
          />
          <AuIcon
            icon="youtube"
            class="btn"
            is-link
            href="https://www.youtube.com/@Autentic-Capital"
            target="_blank"
          />
          <AuIcon
            icon="telegram"
            class="btn"
            is-link
            href="https://t.me/AUT_Community_EN"
            target="_blank"
          />
          <AuIcon
            icon="twitter"
            class="btn"
            is-link
            href="https://twitter.com/AutCapital"
            target="_blank"
          />
          <AuIcon
            v-if="language == 'ru'"
            icon="dzen"
            class="btn"
            is-link
            href="https://dzen.ru/autenticapital"
            target="_blank"
          />
        </div>

        <div class="policy">
          <a
            href="/privacy-policy/"
            target="_blank"
          >{{ $t("footer.privacyPolicy") }}</a>
          <a
            href="/user-agreement/ "
            target="_blank"
          >{{ $t("footer.termsOfUse") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AuDialog",
  props: {
    divider: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      language: "language"
    })
  }
};
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  gap: 10px;
}

.copyright {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  font-size: 13px;
  color: var(--text-color);
}
.links {
  display: flex;
  flex-direction: column;

  margin-bottom: 6px;

  @media (min-width:1250px) {
    flex-direction: row;
  }

  .media {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;

    margin-left: -15px;
  }

  .policy  {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    gap: 25px;

    margin: 5px 0px;

    @media (min-width:1250px) {
      margin-left: 60px;
    }

    @media (max-width:480px) {
      flex-direction: column;
      align-items: flex-start;

      gap: 5px;
    }

    a {
      text-decoration: underline;
      white-space: pre;
      color: var(--text-color)
    }
  }
}

.info {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  margin: 10px 5px;
}
</style>