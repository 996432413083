<template>
  <div>
    <AuBreadcrumb
      :key="key"
      class="myMt"
    />
    <div class="card ch-main">
      <div class="ch-header">
        <div class="filter">
          <div class="fs24 ch-title">
            {{ $t("auCapitals.capitalHistory.title") }}
          </div>
          <div>
            <AuSelect
              :value="type"
              class="type-select"
              :options="typeArray"
              :placeholder="$t('transactions.transactionType.placeholder')"
              @input="changeType"
            />
          </div>
        </div>

        <div class="ch-title fs15">
          {{ $t("auCapitals.capitalHistory.project") }}&nbsp;
          {{ currentCapital?.tokenBase?.nameEng }}
        </div>
      </div>

      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div v-if="transactions?.length">
        <LastTransactionsList
          :transactions="transactions"
        />
        <div
          v-if="itemPerPage < totalItems"
          class="ch-footer"
        >
          <AuButton
            center
            @click="getMore"
          >
            {{ $t("auCapitals.capitalHistory.more") }}
          </AuButton>
        </div>
      </div>
      <div
        v-else
        class="mt-2"
      >
        <p>{{ $t("auCapitals.capitalHistory.noInvestment") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import LastTransactionsList from "../components/lastTransactions/LastTransactionsList.vue";
import ProgressLoader from "@/loaders/progress-bar";

import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CapitalHistory",
  components: {
    LastTransactionsList,
    ProgressLoader
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      transactions: [],
      currentCapital: null,
      page: 1,
      totalItems: 0,
      itemPerPage: 30,
      morePage: 30,
      loading: true,
      key: 1,
      type: "",
      typeArray: [
        { display: this.$t("transactions.transactionType.all"), value: "all" },
        { display: this.$t("transactions.transactionType.my"), value: "my" }]
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses"
    })
  },

  async created() {
    this.currentCapital = await this.getAuCapitals(this.id);
    this.$route.matched[1].path = `/project-card/${this.id}`;
    this.$route.matched[1].meta.title = this.currentCapital.tokenBase.nameEng;
    this.key = 2;
    await this.getTransactions();
  },

  methods: {
    ...mapActions({
      getTransactionsHistory: "getTransactionsHistory",
      getAuCapitals: "capitals/getAuCapitals"
    }),

    async getTransactions() {
      this.loading = true;
      if (!this.arrEth[0]?.verifiedAt) {
        this.loading = false;
        return;
      }

      let params = {
        page: this.page,
        itemsPerPage: this.itemPerPage,
        auCapitalId: this.id,
        methodName: "investing"
      };

      if (this.type == "my") {
        params.fromUserId = this.$auth.user().id;
      }
      let resp = await this.getTransactionsHistory(params);
      let transfer = resp.data;
      this.totalItems = resp.totalItems;
      this.transactions = [];

      if (transfer.length == 0) {
        this.loading = false;
        return;
      }
      transfer.forEach(transaction => {
        const time = moment(transaction.createdAt).format("DD.MM.YYYY");
        let records = this.transactions.find(el => el.date == time)?.transactions;
        if (records === undefined) {
          this.transactions.push({ "date": time, "sort": transaction.createdAt, "transactions": [] });
          records = this.transactions.at(-1).transactions;
        }
        this.arrEth.forEach((eth) => {
          if (_.toLower(eth.value) === _.toLower(transaction?.addressFrom)) {
            this.addressEth = transaction?.addressFrom;
            transaction.isOwnerTransaction = true;
          }
          else if (_.toLower(eth.value) === _.toLower(transaction?.addressTo)) {
            this.addressEth = transaction?.addressTo;
          }
          else {
            this.addressEth = transaction?.addressFrom;
          }
        });

        records.push({
          icon: transaction?.auToken?.logoMain ?? transaction?.auToken?.mainImage?.name,
          name: _.isNil(this.addressEth) ? this.$t("transactions.unspecified") :
            this.addressEth.slice(0, 4) +
            "..." +
            this.addressEth.slice(-4),
          addressFrom: _.get(transaction, "addressFrom", "-"),
          addressTo: _.get(transaction, "addressTo", "-"),
          addressEth: this.addressEth,
          amount: _.get(transaction, "amount", null),
          isConfirmed: _.get(transaction, "isConfirmed", false),
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: _.toLower(this.ourEth) == _.toLower(transaction?.addressTo),
          type: _.get(transaction, "methodName", "transfer"),
          decimals: _.get(transaction, "auToken.decimals", 2),
          mnemonic: _.get(transaction, "auToken.mnemonicEng", "-"),
          createdAt: _.get(transaction, "createdAt", "-"),
          hash: _.get(transaction, "hash", "-"),
          isOwnerTransaction: _.get(transaction, "isOwnerTransaction", transaction?.isOwnerTransaction)
        });
      });

      this.transactions.sort((a, b) => {
        const diff = moment(b.sort).unix() - moment(a.sort).unix();
        if (diff > 0) {
          return 1;
        }
        if (diff < 0) {
          return -1;
        }
        return 0;
      });

      this.loading = false;
    },

    async changeType(type) {
      this.type = type;
      await this.getTransactions();
    },
    async getMore() {
      this.itemPerPage += this.morePage;
      await this.getTransactions();
    }
  }
};
</script>

<style scoped lang="scss">
.ch-main{
  padding: 20px;
}
.ch-title{
  margin-bottom: 20px;
}
.ch-header{
  border-bottom: 1px solid var(--color-70);
}
.ch-footer{
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 40px;
  border-top: 1px dashed var(--color-70);
}
.myMt {
  margin-top: 25px;
}
.filter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.type-select {
  width: 172px;
}
</style>