<template>
  <div class="menu__buttons">
    <RouterLink
      v-for="page in pages"
      :key="page.name"
      :to="{name: page.name}"
      class="menu-items"
    >
      <AuButton
        :icon="page.icon"
        :active="($route.name == page.name)"
        :hovered-bg-color="hovered"
        :active-color="active"
        border-color="true"
        font-size="16px"
        width="100%"
        class="distance"
      >
        <span class="menu-items">
          {{ $t(page.title) }}
        </span>
      </AuButton>
    </RouterLink>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    swiperMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    pages() {
      const general = [
        { name: "dashboard", icon: "grid_round_green", title: this.$t("router.pages.dashboard") },
        { name: "briefcase", icon: "bag_green", title: this.$t("router.pages.briefcase") },
        { name: "projects", icon: "projectsc", title: this.$t("router.pages.project") },
        { name: "transactions", icon: "swap_vertical_green", title: this.$t("router.pages.transactions") },
        { name: "blockscan", icon: "blockscan", title: this.$t("router.pages.blockScan") }
      ];
      if (this.$auth.user().type == "issuer") {
        general.splice(2, 0,
          { name: "my projects", icon: "folder", title: this.$t("router.pages.projects") },
          { name: "companies", icon: "company", title: this.$t("router.pages.companies") });
      }
      return general;
    },

    hovered() {
      return this.isLightTheme ? "#1F3A43" : "#28282d";
    },

    active() {
      return this.isLightTheme ? "#1F3A43A3" : "#28282dA3";
    }
  }
};
</script>

<style scoped lang="scss">
.menu__buttons {
  display: flex;
  flex-direction: column;
}
.menu-items {
  color: white;
}
.distance {
  margin: 2px 0px;
}
</style>