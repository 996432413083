import Accounts from "../appPages/Accounts.vue";
// import AssetsInvest from "@/appPages/AssetsInvest";
// import AutenticCapital from "../pages/AutenticCapital.vue";
import AutenticMarket from "../pages/AutenticMarket.vue";
import AUtSales from "@/appPages/AUtSales";

import BlockDex from "@/appPages/BlockDex";
import BuyForm from "@/appPages/BuyForm";
import TradeHistory from "@/appPages/TradeHistory";

import AuthSocial from "../pages/AuthSocial.vue";
import Briefcase from "../appPages/Briefcase.vue";
import Business from "../appPages/Business.vue";
import CapitalHistory from "@/appPages/CapitalHistory";
import Companies from "../appPages/Companies.vue";
import CompanyManager from "@/components/CompanyManager";
import Counterparties from "../appPages/Counterparties.vue";
import CounterpartyForm from "@/components/counterpartyForm/CounterpartyForm.vue";
import DAOEvents from "@/appPages/DAOEvents";
import DAORequests from "@/appPages/DAORequests";
import DaoTokens from "../appPages/DaoTokens.vue";
import Dashboard from "../appPages/Dashboard.vue";
import DashboardEmitent from "@/appPages/DashboardEmitent";
import Dividends from "../appPages/Dividends.vue";
import DividendsHistory from "@/appPages/DividendsHistory";
import DividendsIssuer from "@/appPages/DividendsIssuer";
import DividendsPay from "@/appPages/DividendsPay";
import Documentation from "@/appPages/Documentation";
import EmailVerify from "@/pages/EmailVerify";
import GoodForm from "@/appPages/GoodForm";
import Investers from "../appPages/Investers.vue";
import InvestGoods from "@/appPages/InvestGoods";
import KYCPage from "@/appPages/KYC-page";
import Login from "../pages/Login.vue";
import Logout from "../pages/Logout.vue";
import Marketplace from "@/appPages/Marketplace";
import MarketplaceCard from "@/appPages/MarketplaceCard";
import MyGoods from "@/appPages/MyGoods";
import MyProjects from "@/appPages/MyProjects";
import News from "../appPages/News.vue";
import NewsForm from "../appPages/NewsForm.vue";
import NewsList from "../appPages/NewsList.vue";
import OuterProjects from "@/appPages/OuterProjects";
import PageNotFound from "@/pages/PageNotFound";
import PaymentMethod from "../pages/PaymentMethod.vue";
import Pays from "@/appPages/Pays";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import ProjectCard from "@/appPages/ProjectCard";
import ProjectForm from "@/appPages/ProjectForm";
import ProjectInvestors from "@/appPages/ProjectInvestors";
import Projects from "@/appPages/Projects";
import ReferralProgram from "../pages/ReferralProgram.vue";
import Reports from "../appPages/Reports.vue";
import Requisites from "@/appPages/Requisites";
import Security from "../pages/Security.vue";
import SendToken from "../appPages/SendToken.vue";
import Settings from "../pages/Settings.vue";
import SingleNews from "@/appPages/SingleNews";
import Support from "../pages/Support.vue";
import TestEnv from "../pages/TestEnv.vue";
import TestPage from "../appPages/TestPage.vue";
import TokenInfo from "@/appPages/TokenInfo";
import Transactions from "../appPages/Transactions.vue";
import User from "../pages/User.vue";
import UserAgreement from "@/pages/UserAgreement";
import VoitingProtocol from "@/appPages/VoitingProtocol";
import Wallet from "../pages/Wallet.vue";
import WalletVerification from "../appPages/WalletVerification";

import Debug from "../appPages/Debug.vue";
import Unsubscribe from "../appPages/Unsubscribe.vue";

import InvestProjects from "@/appPages/InvestProjects";
import InvestProjectsApplication from "@/appPages/InvestProjectsApplication.vue";

import MultitokenCard from "@/appPages/MultitokenCard";
import NewsCapital from "@/appPages/NewsCapital";

import BlockScan from "@/appPages/BlockScan";
import PasswordRestore from "@/appPages/PasswordRestore";
import Welcome from "@/pages/Welcome";
import Withdraw from "@/appPages/Withdraw";

import Base from "@/pages/Base";

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "",
    name: "base",
    component: Base
  },
  {
    path: "/outer-projects",
    name: "outer projects",
    component: OuterProjects,
    meta: {
      title: "router.pages.projects",
      outer: true
    }
  },
  {
    path: "/outer-project/:id",
    name: "outer project",
    component: ProjectCard,
    meta: {
      title: "router.pages.projectsEdit",
      outer: true
    }
  },
  {
    path: "/debug",
    name: "debug",
    component: process.env.VUE_APP_DEBUG == 1 ? Debug : null,
    meta: {
      title: "router.wallet",
      logo: "logo",
      isMain: true,
      auth: true
    }
  },
  {
    path: "/unsubscribe/:secret",
    name: "unsubscribe",
    component: Unsubscribe,
    meta: {
      title: "router.wallet",
      logo: "logo",
      isMain: true,
      auth: true
    }
  },
  {
    path: "/",
    name: "wallet",
    component: Wallet,
    meta: {
      title: "router.wallet",
      logo: "logo",
      isMain: true,
      auth: true
    },
    children: [
      {
        path: "",
        name: "wallet empty",
        redirect: "/dashboard"
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          title: "router.pages.dashboard",
          icon: "grid_round_green",
          access: ["user", "manager"],
          adaptiveRightPanel: true
        }
      },
      {
        path: "issuer",
        name: "issuer",
        component: DashboardEmitent,
        meta: {
          title: "router.pages.dashboard",
          icon: "grid_round_green",
          access: ["issuer"],
          adaptiveRightPanel: true
        }
      },
      {
        path: "dividends-issuer/:id?",
        name: "dividends issuer",
        props: true,
        component: DividendsIssuer,
        meta: {
          title: "router.pages.dividends"
        }
      },
      {
        path: "dividends-history/:id?",
        name: "dividends history",
        props: true,
        component: DividendsHistory,
        meta: {
          title: "router.pages.dividendsHistory"
        }
      },
      {
        path: "requisites",
        component: Requisites,
        name: "requisites",
        meta: {
          title: "router.pages.requisites",
          icon: "activity_green",
          access: ["issuer"]
        }
      },
      {
        path: "my-goods",
        name: "my goods",
        component: MyGoods,
        meta: {
          title: "router.pages.goods",
          icon: "goods",
          access: ["issuer"]
        }
      },
      {
        path: "my-projects",
        name: "my projects",
        component: MyProjects,
        meta: {
          title: "router.pages.projects",
          icon: "folder",
          access: ["issuer"]
        },
        children: [
          {
            path: "edit-project/:id",
            component: ProjectForm,
            name: "edit project",
            props: true,
            meta: {
              title: "router.pages.projectsEdit",
              noColumnReverse: true
            }
          }
        ]
      },
      {
        path: "companies",
        name: "companies",
        component: Companies,
        meta: {
          title: "router.pages.companies",
          isHelp: true,
          icon: "company",
          access: ["issuer"]
        },
        children: [
          {
            path: "company-manager/:id?",
            component: CompanyManager,
            name: "company manager",
            props: true,
            meta: {
              title: "router.pages.companyManager"
            }
          }
        ]
      },
      {
        //TODO: Это скорее всего роут 3 уровня
        path: "dao-tokens/:id?",
        name: "dao tokens",
        component: DaoTokens,
        props: true,
        meta: {
          title: "router.pages.daoTokens",
          isHelp: true
          //access: ["user", "manager"]
        }
      },
      {
        path: "briefcase",
        component: Briefcase,
        name: "briefcase",
        meta: {
          title: "router.pages.briefcase",
          icon: "bag_green",
          access: ["user", "manager"]
        },
        children: [
          {
            path: "buy-form",
            name: "buy form",
            component: BuyForm,
            meta: {
              title: "router.pages.topUp"
            }
          },
          {
            path: "withdraw",
            name: "withdraw",
            component: Withdraw,
            meta: {
              title: "router.pages.withdrawal"
            }
          }
        ]
      },
      {
        path: "send-token/:id",
        component: SendToken,
        name: "send token",
        props: true,
        meta: {
          title: "router.pages.sendToken",
          noColumnReverse: true
        }
      },
      {
        path: "testpage",
        component: TestPage,
        name: "TestPage",
        props: true,
        meta: {
          title: "TestPage",
          icon: "swap_vertical_green",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "token-info/:id?",
        component: TokenInfo,
        name: "token info",
        props: true,
        meta: {
          title: "router.pages.transactions"
        }
      },
      {
        path: "my-accounts",
        name: "my accounts",
        meta: {
          title: "router.pages.accounts",
          icon: "activity_green",
          access: ["manager"]
        }
      },
      {
        path: "invest-goods",
        name: "invest goods",
        component: InvestGoods,
        meta: {
          title: "router.pages.project",
          icon: "goods",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "transactions",
        component: Transactions,
        name: "transactions",
        meta: {
          title: "router.pages.transactions",
          icon: "swap_vertical_green",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "blockscan",
        component: BlockScan,
        name: "blockscan",
        meta: {
          title: "router.pages.blockScan",
          icon: "blockscan",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "dividends",
        component: Dividends,
        name: "dividends",
        meta: {
          title: "router.pages.dividends",
          icon: "graph_green"
          //access: ["user"]
        }
      },
      {
        path: "counterparties",
        component: Counterparties,
        name: "counterparties",
        meta: {
          title: "router.pages.counterparties",
          icon: "users_green",
          access: ["manager", "issuer"]
        },
        children: [
          {
            path: "edit-counterparty/:id",
            component: CounterpartyForm,
            name: "edit counterparty",
            props: true,
            meta: {
              title: "router.pages.editCounterparty",
              noColumnReverse: true
            }
          }
        ]
      },
      {
        path: "reports",
        component: Reports,
        name: "reports",
        meta: {
          title: "router.pages.reports",
          icon: "chat_green"
          //access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "invest-projects",
        component: InvestProjects,
        name: "invest projects",
        meta: {
          title: "router.pages.investProjects"
          //access: ["user"]
        }
      },
      {
        path: "invest-projects-application",
        component: InvestProjectsApplication,
        name: "invest projects application",
        meta: {
          title: "router.pages.investProjectsApplication"
        }
      },
      {
        path: "accounts",
        component: Accounts,
        name: "accounts",
        meta: {
          title: "router.pages.account"
        }
      },
      // {
      //   path: "accounts",
      //   component: Accounts,
      //   name: "accounts",
      //   meta: {
      //     title: "router.pages.account"
      //   }
      // },
      {
        path: "news",
        component: News,
        name: "news",
        meta: {
          title: "router.pages.news",
          icon: "news_glasses"
        },
        children: [
          {
            path: "edit-news/:id",
            component: NewsForm,
            name: "edit news",
            props: true,
            meta: {
              title: "router.pages.editNews",
              noColumnReverse: true
            }
          }
        ]
      },
      {
        path: "news/:id",
        component: SingleNews,
        name: "single news",
        props: true,
        meta: {
          title: "router.pages.newsOne",
          noColumnReverse: true,
          hideNews: true
        }
      },
      {
        path: "all-news",
        component: NewsList,
        name: "all news",
        props: true,
        meta: {
          title: "router.pages.news",
          noColumnReverse: true,
          hideNews: true
        }
      },
      {
        path: "user",
        name: "user",
        component: User,
        meta: {
          title: "router.pages.user"
        }
      },
      {
        path: "testenv",
        name: "TestEnv",
        component: TestEnv,
        meta: {
          title: "TestEnv"
        }
      },
      {
        path: "referral-program",
        name: "referral program",
        component: ReferralProgram,
        meta: {
          title: "router.pages.referralProgram"
        }
      },
      {
        path: "payment-method",
        name: "payment method",
        component: PaymentMethod,
        meta: {
          title: "router.pages.paymentMethod"
        }
      },
      {
        path: "security",
        name: "security",
        component: Security,
        meta: {
          title: "router.pages.security"
        }
      },
      {
        path: "wallet-verification",
        name: "walletVerification",
        component: WalletVerification,
        meta: {
          title: "router.pages.walletVerification"
        }
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        meta: {
          title: "router.pages.settings"
        }
      },
      {
        path: "support",
        name: "support",
        component: Support,
        meta: {
          title: "router.pages.support"
        }
      },
      {
        path: "my-goods/edit-good/:id",
        name: "edit good",
        component: GoodForm,
        meta: {
          title: "router.pages.editGood"
        }
      },
      // {
      //   path: "assets-invest",
      //   name: "assets invest",
      //   component: AssetsInvest,
      //   meta: {
      //     title: "router.pages.assetsInvest"
      //   }
      // },
      {
        path: "autsales",
        name: "AUtSales",
        component: AUtSales,
        meta: {
          title: "router.pages.autSales",
          access: ["debug"]
        }
      },
      {
        path: "pays/:hash?",
        name: "pays",
        props: true,
        component: Pays,
        meta: {
          title: "router.pages.pays"
        }
      },
      {
        path: "dao-requests/:id?",
        name: "dao requests",
        props: true,
        component: DAORequests,
        meta: {
          title: "router.pages.daoRequests"
        }
      },
      {
        path: "dao-events/:id?",
        name: "dao events",
        props: true,
        component: DAOEvents,
        meta: {
          title: "router.pages.daoEvents"
        }
      },
      {
        path: "documentation",
        name: "documentation",
        component: Documentation,
        meta: {
          title: "router.pages.documentation"
        }
      },
      {
        path: "project-investors/:id?",
        name: "project investors",
        props: true,
        component: ProjectInvestors,
        meta: {
          title: "router.pages.projectInvestors"
        }
      },
      {
        path: "blockdex",
        name: "blockdex",
        component: BlockDex,
        meta: {
          title: "router.pages.buyCryptoAssets",
          showMenu: false
        }
      },
      {
        path: "trade-history",
        name: "trade history",
        component: TradeHistory,
        meta: {
          title: "router.pages.conversionStory",
          showMenu: false
        }
      },
      {
        path: "voiting-protocol/:id?",
        name: "voiting protocol",
        props: true,
        component: VoitingProtocol,
        meta: {
          title: "router.pages.protocolVoting",
          showMenu: false
        }
      },
      {
        path: "dividends-pay/:id?",
        name: "dividends pay",
        props: true,
        component: DividendsPay,
        meta: {
          title: "router.pages.dividendPay",
          showMenu: false
        }
      },
      {
        path: "/kyc",
        name: "kyc",
        component: KYCPage,
        meta: {
          title: "router.pages.kyc"
        }
      },
      {
        path: "",
        name: "autentic capital empty",
        redirect: "/projects"
      },
      {
        path: "/projects",
        name: "projects",
        component: Projects,
        meta: {
          title: "router.pages.project",
          icon: "projectsc",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "project-card/:id?",
        name: "project card",
        props: true,
        component: ProjectCard,
        meta: {
          title: "router.pages.cardProject"
        },
        children: [
          {
            path: "capital-history/:id?",
            name: "capital history",
            props: true,
            component: CapitalHistory,
            meta: {
              title: "router.pages.investStory"
            }
          }
        ]
      },
      {
        path: "news-capital",
        name: "news capital",
        component: NewsCapital,
        meta: {
          title: "router.pages.news",
          icon: "newsc",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "investers",
        name: "investers",
        component: Investers,
        meta: {
          title: "router.pages.investors",
          icon: "trend_up",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "business",
        name: "business",
        component: Business,
        meta: {
          title: "router.pages.business",
          icon: "personal_card",
          access: ["user", "manager", "issuer"]
        }
      }
    ]
  },
  {
    path: "/",
    name: "autentic market",
    component: AutenticMarket,
    meta: {
      title: "router.market",
      logo: "logo_market",
      isMain: true,
      auth: true
    },
    children: [
      {
        path: "",
        name: "autentic market empty",
        redirect: "/marketplace"
      },
      {
        path: "marketplace",
        name: "marketplace",
        component: Marketplace,
        meta: {
          title: "router.pages.marketplace",
          icon: "marketplace_green",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "marketplace-card/:id",
        name: "marketplace card",
        component: MarketplaceCard,
        meta: {
          title: "router.pages.product"
        }
      },
      {
        path: "multitoken-card/:id?",
        name: "multitoken card",
        component: MultitokenCard,
        props: true,
        meta: {
          title: "router.pages.crushedAsset"
        }
      },
      {
        path: "delivery",
        name: "delivery",
        meta: {
          title: "router.pages.delivery",
          icon: "delivery_green",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "payment",
        name: "payment",
        meta: {
          title: "router.pages.payment",
          icon: "payment_green",
          access: ["user", "manager", "issuer"]
        }
      },
      {
        path: "guarantees",
        name: "guarantees",
        meta: {
          title: "router.pages.guarantees",
          icon: "guarantees_green",
          access: ["user", "manager", "issuer"]
        }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "login"
    }
  },
  {
    path: "/oath/google",
    children: [
      { path: "/oath/fb", component: AuthSocial }
    ],
    name: "AuthSocial",
    component: AuthSocial,
    meta: {
      title: "AuthSocial"
    }
  },
  {
    path: "/email_verify/:hash",
    name: "EmailVerify",
    component: EmailVerify,
    meta: {
      title: "Verify"
    }
  },
  {
    path: "/sign",
    name: "sign",
    component: Login,
    meta: {
      title: "register"
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome
  },
  {
    path: "/password_restore/:hash?",
    name: "password restore",
    props: true,
    component: PasswordRestore
  },
  {
    path: "/privacy-policy",
    name: "privacy policy",
    component: PrivacyPolicy
  },
  {
    path: "/user-agreement",
    name: "user agreement",
    component: UserAgreement
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page not found",
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

import store from "@/store/index.js";

router.beforeEach((to, from, next) => {
  if (to.meta.outer) {
    document.documentElement.style.setProperty("overflow", "hidden");
  }

  if (to.query.outer) {
    store.dispatch("setOuterPath", to.path);
  }

  next();
});

export default router;
