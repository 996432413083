<template>
  <div>
    <Wizard class="wizard" />

    <AuBreadcrumb v-if="showProjectsTable" />
    <div
      v-if="showProjectsTable"
      class="card projects"
    >
      <div class="title">
        {{ $t('projects.label') }}
      </div>
      <div
        v-if="!loading"
        class="d-flex justify-content-between p-indent subtitle-line"
      >
        <span class="explain">
          {{ $t('projects.projectsCount') }}: <span class="count">{{ itemsCount }} </span>
        </span>
        <div>
          <AuButton
            bordered
            center
            width="237"
            @click="createNewProject"
          >
            <span class="bnt-text">
              {{ $t('projects.addProjectButton') }}
            </span>
          </AuButton>
        </div>
      </div>
      <div>
        <AuTable
          class="projects-table"
          :columns="columns"
          :items="formattedProjects"
          :icons="null"
          :loading="loading"
        >
          <template #action="{value}">
            <div
              v-click-outside="closeAllMenus"
              class="container-menu"
            >
              <AuIcon
                icon="more"
                as-button
                @click="openMenu(value.index)"
              />
              <div
                v-if="showMenu[value.index]"
                class="context-menu"
              >
                <AuButton
                  width="100%"
                  center
                  @click="editProject(value.index)"
                >
                  {{ $t("counterparties.editAction") }}
                </AuButton>
                <AuButton
                  width="100%"
                  center
                  @click="showVerifyDialog(value.index)"
                >
                  {{ $t("projects.verificationAction") }}
                </AuButton>
                <AuButton
                  width="100%"
                  center
                  @click="showDeleteDialog(value.index)"
                >
                  {{ $t("counterparties.deleteAction") }}
                </AuButton>
                <AuButton
                  v-if="value.isCompleted"
                  width="100%"
                  center
                  @click="goToPage(value.index)"
                >
                  {{ $t("projects.investHistory") }}
                </AuButton>
                <AuButton
                  v-if="value.isCompleted"
                  width="100%"
                  center
                  @click="goToProjectInvestors(value.index)"
                >
                  {{ $t("counterparties.investorsAction") }}
                </AuButton>
                <AuButton
                  v-if="value.isCompleted && value.type=='investment'"
                  width="100%"
                  center
                  @click="goToDividends(value.index)"
                >
                  {{ $t("counterparties.dividendsAction") }}
                </AuButton>
                <AuButton
                  v-if="value.isCompleted && value.isVoiting"
                  width="100%"
                  center
                  @click="goToDAORequests(value.index)"
                >
                  {{ $t("counterparties.daoRequests") }}
                </AuButton>
              </div>
            </div>
          </template>
        </AuTable>
        <div class="projects-accordion">
          <AuAccordion
            v-for="(project, index) in formattedProjects"
            :key="project"
            :last-child="(index == formattedProjects.length - 1)"
          >
            <template #title>
              <span class="company-header">{{ ( index + 1 ) }}</span>
            </template>
            <template #content>
              <div class="company-info container-fluid">
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('projects.firstColumn') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ project.projectName }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('projects.secondColumn') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ project.type }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('projects.thirdColumn') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ project.needed }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('projects.fourthColumn') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ project.status }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('projects.fifthColumn') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ project.confirmed }}</span>
                  </div>
                </div>
              </div>
            </template>
          </AuAccordion>
        </div>
      </div>
    </div>
    <RouterView />
    <AuModal
      v-model="modifyDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ modalHeader }}
        </span>
      </template>
      <template #body>
        <span>
          {{ modalBody }}
        </span>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="modalClick"
            >
              {{ $t('projects.dialog.agree') }}
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="modifyDialog=false"
            >
              {{ $t('projects.dialog.cancel') }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

import Wizard from "@/components/wizard/ProjectsWizard.vue";

export default {
  name: "MyProjects",

  components: {
    Wizard
  },

  data() {
    return {
      isStep1: true,
      loading: true,
      columns: [{ name: this.$t("projects.firstColumn"), align: "start" },
        { name: this.$t("projects.secondColumn"), align: "start" },
        { name: this.$t("projects.thirdColumn"), align: "start" },
        { name: this.$t("projects.fourthColumn"), align: "start" },
        { name: this.$t("projects.fifthColumn"), align: "end" },
        { name: this.$t("projects.sixthColumn"), align: "center", slot: "action" }],
      typeNames: {
        investment: this.$t("projects.cryptoActiveTypes.investment"),
        access: this.$t("projects.cryptoActiveTypes.access"),
        ticket: this.$t("projects.cryptoActiveTypes.ticket"),
        voucher: this.$t("projects.cryptoActiveTypes.voucher"),
        management: this.$t("projects.cryptoActiveTypes.management")
      },
      investTypeNames: {
        redeemable: ` ${this.$t("projects.investType.redeemable")}`,
        "non-redeemable": ` ${this.$t("projects.investType.non-redeemable")}`
      },
      isModify: false,
      modifyDialog: false,
      modifyId: null,
      modifyIndex: null,
      modifyName: null,
      showMenu: []
    };
  },

  computed: {
    ...mapGetters({
      requestsToIssue: "requestsToIssue"
    }),

    formattedProjects() {
      return _.map(this.requestsToIssue, (requestsToIssue, index) => ({
        projectName: requestsToIssue.tokenNameEng || this.$t("projects.noName"),
        type: this.getType(requestsToIssue),
        needed: requestsToIssue.tokenCount,
        status: requestsToIssue.isDraft ? this.$t("projects.status.draft") :
          (requestsToIssue.isCompleted ? this.$t("projects.status.approved") : this.$t("projects.status.inProcess")),
        confirmed: requestsToIssue.isCompleted ? this.$t("projects.isCompleted.released") :
          this.$t("projects.isCompleted.waiting"),
        action: {
          type: requestsToIssue.cryptoActiveType,
          index: index,
          isVoiting: requestsToIssue?.isVoiting,
          isCompleted: requestsToIssue.isCompleted
        }
      }));
    },

    itemsCount() {
      return this.requestsToIssue.length;
    },

    showProjectsTable() {
      return !_.includes(this.$route.path, "edit-project");
    },

    modalHeader() {
      return this.isModify ?
        this.$t("projects.dialog.verificationTitle") :
        this.$t("projects.dialog.deleteTitle");
    },

    modalBody() {
      return this.isModify ?
        `${this.$t("projects.dialog.verificationSubtitle")} - ${this.modifyName}?` :
        `${this.$t("projects.dialog.deleteSubtitle")} - ${this.modifyName}?`;
    }
  },

  async mounted() {
    this.closeAllMenus();
    await this.getRequestsToIssue();

    this.loading = false;
  },

  methods: {
    ...mapActions({
      delRequestToIssue: "delRequestToIssue",
      editRequestToIssue: "editRequestToIssue",
      getRequestsToIssue: "getRequestsToIssue"
    }),

    getType(value) {
      let ret = _.get(this.typeNames, value.cryptoActiveType, "-");
      ret += _.get(this.investTypeNames, value.investType, "");
      if ("isVoiting" in value) {
        ret += value.isVoiting ? ` ${this.$t("projects.isVoiting.voiting")}` :
          ` ${this.$t("projects.isVoiting.non-voiting")}`;
      }
      return ret;
    },

    closeAllMenus() {
      this.showMenu = _.map(this.requestsToIssue, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    createNewProject() {
      this.$router.push({ name: "edit project", params: { id: "new" } });
    },

    editProject(index) {
      if (!this.requestsToIssue[index].isDraft) {
        this.showError(this.$t("toasts.error.projectEdit"));
        return;
      }
      this.$router.push({ name: "edit project", params: { id: this.requestsToIssue[index].id } });
    },

    showVerifyDialog(index) {
      if (!this.requestsToIssue[index].isDraft) {
        this.showError(this.$t("toasts.error.projectNoVerify"));
        return;
      }
      this.isModify = true;
      this.showModifyDialog(index);
    },

    showDeleteDialog(index) {
      this.isModify = false;
      this.showModifyDialog(index);
    },

    showModifyDialog(index) {
      this.modifyId = this.requestsToIssue[index].id;
      this.modifyName = this.requestsToIssue[index]?.tokenNameEng;
      this.modifyIndex = index;
      this.modifyDialog = true;
    },

    modalClick() {
      if (this.isModify) {
        this.verify();
      }
      else {
        this.del();
      }
    },

    async verify() {
      this.modifyDialog = false;
      this.loading = true;
      try {
        const params = { isDraft: false };
        await this.editRequestToIssue([this.modifyId, params]);
        this.requestsToIssue[this.modifyIndex].isDraft = false;
        this.showSuccess(this.$t("toasts.success.projectVerify"));
      }
      catch (e) {
        this.showError(this.$t("toasts.error.projectVerify"));
      }
      this.loading = false;
    },

    goToPage(index) {
      // возможно после изменений в api это не пригодится
      if (!this.requestsToIssue[index].auCapital) {
        this.showError(this.$t("toasts.error.projectHistory"));
      }
      else {
        this.$router.push({ name: "capital history", params: { id: this.requestsToIssue[index].auCapital.slice(17) } });
      }
    },

    goToDividends(index) {
      let id = this.requestsToIssue[index]?.token;
      if (id) {
        id = id.slice(id.lastIndexOf("/") + 1);
        this.$router.push({ name: "dividends issuer", params: { id: id } });
      }
    },

    goToDAORequests(index) {
      let id = this.requestsToIssue[index]?.token;
      if (id) {
        id = id.slice(id.lastIndexOf("/") + 1);
        this.$router.push({ name: "dao requests", params: { id: id } });
      }
    },

    goToProjectInvestors(index) {
      let id = this.requestsToIssue[index]?.auCapital;
      if (id) {
        id = id.slice(id.lastIndexOf("/") + 1);
        this.$router.push({ name: "project investors", params: { id: id } });
      }
      else {
        this.showError(this.$t("toasts.error.projectGoToInvestors"));
      }
    },

    async del() {
      this.modifyDialog = false;
      this.loading = true;
      try {
        await this.delRequestToIssue(this.modifyId);
        this.requestsToIssue.splice(this.modifyIndex, 1);
        this.showSuccess(this.$t("toasts.success.projectDelete"));
      }
      catch (e) {
        this.showError(this.$t("toasts.error.projectDelete"));
      }
      this.loading = false;
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    }
  }
};
</script>

<style scoped lang="scss">
.projects{
  padding: 20px;
  font-family: 'Suisse Intl',serif;
  font-style: normal;

  &-table {
    @media screen and (max-width:560px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:560px) {
      display: block;
    }
  }
}

.col {
  padding: 0;
  font-size: 12px;
  &:not(.info-title) {
    text-align: end;
  }
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }
  &-info {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: 20px
  }
}

.subtitle-line {
  flex-direction: row;
  @media screen and (max-width:460px) {
    gap: 20px;
    flex-direction: column;
  }
}
.title{
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 35px;
}
.count{
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--color-60);;
}
.bnt-text{
  font-weight: 450;
  font-size: 12px;
}
.explain {
  font-weight: 450;
  font-size: 12px;
  color: var(--color-60);
}
.p-indent{
  margin-bottom: 30px;
}
.modal_head_text {
  font-family: 'Suisse Intl',serif;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.btn_indent{
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.btn-text{
  font-weight: 450;
  font-size: 12px;
}
.container-menu{
  position: relative;
}
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--main-color);
  z-index: 10;
  top: 30px;
}
.wizard {
  margin-bottom: 25px;
}
</style>
