<template>
  <span
    v-if="showValue"
    class="decimals"
  >
    {{ displayValue }}

    <template v-if="showAddition">
      <span
        v-if="checkCurrency"
        class="mnemonic"
      >{{ checkCurrency }}</span>
      <span
        v-else
        class="no-currency"
      >EMPTY TOKEN NAME</span>
    </template>
  </span>
  <span v-else>-</span>
</template>

<script>
import BigNumber from "bignumber.js";
export default {
  name: "AuDecimalStatic",

  props: {
    value: {
      type: [String, Number, BigNumber],
      default: ""
    },

    decimal: {
      type: Number,
      default: 0
    },

    indent: {
      type: Number,
      default: 2
    },

    //Валюта
    addition: {
      type: String,
      default: ""
    },

    //Возможность разделять цифры пробелом
    formatValue: {
      type: Boolean,
      default: true
    },

    showAddition: {
      type: Boolean,
      default: true
    },

    smartIndent: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    showValue() {
      const value = this.value ? this.value.toString().replace(",", ".") : 0;
      return !BigNumber(value).isNaN();
    },

    displayValue() {
      if (this.formatValue) {
        if (this.smartIndent && Math.trunc(Number(this.formatedValue)) == Number(this.formatedValue)) {
          return Number(this.formatedValue)
            .toLocaleString("ru")
            .replace(",", ".");
        }
        else {
          return Number(this.formatedValue)
            .toLocaleString("ru", { minimumFractionDigits: this.indent })
            .replace(",", ".");
        }
      }
      else {
        return this.formatedValue.replace(",", ".");
      }
    },

    checkCurrency() {
      if (this.addition == "-") {
        return null;
      }
      else {
        return this.addition;
      }
    },

    formatedValue() {
      if (!this.indent) {
        return this.value;
      }
      else {
        let value = this.value ? this.value.toString().replace(",", ".") : 0;
        return BigNumber(value).dividedBy(BigNumber(10).pow(this.decimal)).toFixed(this.indent);
      }
    }
  }
};

</script>

<style scoped lang="scss">
.no-currency {
  color: grey;
}

</style>