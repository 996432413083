import _ from "lodash";
import BigNumber from "bignumber.js";
import store from "@/store";

export default class currencySupport {

  tokens() {
    return [...store.getters.tokensFiat, ...store.getters.tokensUtility,
    // eslint-disable-next-line indent
    ...store.getters.tokensGold, ...store.getters.tokensDao];
  }

  currency() {
    return store.getters.currencyRate;
  }

  balances() {
    return store.getters.balances;
  }

  tokenQuote(mnemonic) {
    return this.tokens().find(item => item.mnemonicEng == mnemonic)?.id;
  }

  getDecimal(id) {
    return this.tokens().find(item => item.id == id)?.decimals;
  }

  getIdByIRI(iri) {
    if (_.isNil(iri)) {
      return -1;
    }
    const slashPos = iri.lastIndexOf("/") + 1;
    if (slashPos === 0) {
      return -1;
    }
    return iri.slice(slashPos);
  }

  getDecimalByIri(iri) {
    return this.tokens().find(item => item.id == this.getIdByIRI(iri)).decimals;
  }

  getFullName(tokenId) {
    return "/api/au_tokens/" + this.tokens().find(token => token.id == tokenId)?.nameEng;
  }

  getCurrency(tokenId, mnemonic) {
    const tokenQuoteId = this.tokenQuote(mnemonic);
    const decimal = BigNumber(10).pow(this.getDecimal(tokenQuoteId));
    if (tokenId == tokenQuoteId) {
      return "1";
    }
    let currency = this.currency().find(item => this.getIdByIRI(item?.token) == tokenId &&
      this.getIdByIRI(item?.tokenQuote) == tokenQuoteId
    );
    if (!_.isUndefined(currency)) {
      // не учитываем decimal валюты, но нужно еще проверить на новых токенах когда они будут. Пока не удаляем.
      // currency = BigNumber(currency.currency).dividedBy(decimal);
      currency = BigNumber(currency.currency);
      return currency.toString(10);
    }

    let cross = this.currency().find(item => this.getIdByIRI(item?.tokenQuote) == tokenId);
    if (_.isUndefined(cross)) {
      return "0";
    }
    currency = BigNumber(cross.currency).dividedBy(BigNumber(10).pow(this.getDecimal(tokenId)));

    cross = this.currency().find(item => item?.token == cross?.token &&
      this.getIdByIRI(item.tokenQuote) == tokenQuoteId);
    if (_.isUndefined(cross)) {
      return "0";
    }
    cross = BigNumber(cross.currency).dividedBy(decimal);
    currency = cross.dividedBy(currency).toString(10);
    return currency;
  }

  getBalance(tokenId) {
    let balance = _.find(this.balances(), token => token.auToken == this.getFullName(tokenId));
    let decimal = _.find(this.tokens(), token => token.id == tokenId)?.decimals;
    decimal = !decimal ? 0 : decimal;
    balance = BigNumber(balance?.amount ?? 0);
    const fmt = {
      prefix: "",
      decimalSeparator: "",
      groupSeparator: "",
      groupSize: 3,
      secondaryGroupSize: 0,
      fractionGroupSeparator: " ",
      fractionGroupSize: 0,
      suffix: ""
    };
    return balance.toFormat(decimal, 0, fmt);
  }

  getAmount(tokenId) {
    let amount = this.balances().find(token => token.auToken == this.getFullName(tokenId));
    amount = _.isUndefined(amount) ? 0 : amount.amountWithRate;
    amount = BigNumber(amount);
    let decimal = this.tokens().find(token => token.id == tokenId)?.decimals;
    decimal = _.isUndefined(decimal) ? 0 : decimal;
    const fmt = {
      prefix: "",
      decimalSeparator: "",
      groupSeparator: "",
      groupSize: 3,
      secondaryGroupSize: 0,
      fractionGroupSeparator: " ",
      fractionGroupSize: 0,
      suffix: ""
    };
    return amount.toFormat(decimal, 0, fmt);
  }
}