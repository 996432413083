<template>
  <div class="is2 card">
    <div class="form-header">
      <div class="title">
        {{ `${mode} ${$t('projects.form.secondStep.labelLastPart')}` }}
      </div>
      <AuButton
        width="auto"
        bordered
        @click="goBack"
      >
        {{ $t("projects.form.cancelButton") }}
      </AuButton>
    </div>

    <div class="is-label">
      {{ $t('projects.form.secondStep.projectMinNumLabel') }}
    </div>
    <div class="is-indent">
      <AuInput
        v-model="minCol"
        required
        :placeholder="$t('projects.form.secondStep.projectMinNumPlaceholder')"
        type="Mask"
        :mask="Number"
        :max="tokensCount"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.secondStep.projectTokenQuoteLabel') }}
    </div>
    <div class="is-indent">
      <AuSelect
        :value="tokenQuote"
        :options="tokenQuotesOptions"
        :allow-empty="false"
        required
        :placeholder="$t('projects.form.secondStep.projectTokenQuotePlaceholder')"
        @input="setTokenQuote"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.secondStep.projectPriceBaseLabel') }}
    </div>
    <div class="is-small-indent">
      <AuInput
        v-model="priceBase"
        required
        clearable
        :placeholder="$t('projects.form.secondStep.projectPriceBasePlaceholder')"
        type="Number"
        :decimal="currentTokenDecimals"
      />
    </div>
    <div class="d-flex is-indent">
      <div class="is-line is-minmax">
        <span class="is-text-inside">{{ $t('projects.form.secondStep.projectMinPriceLabel') }}</span>
        <AuDecimalStatic
          :value="minCap"
          :addition="tokenMnemonic"
          :show-addition="!!tokenMnemonic"
          :decimal="currentTokenDecimals"
          :indent="currentTokenDecimals"
        />
      </div>
      <div class="is-line  is-minmax">
        <span class="is-text-inside">{{ $t('projects.form.secondStep.projectMaxPriceLabel') }}</span>
        <AuDecimalStatic
          :value="maxCap"
          :addition="tokenMnemonic"
          :show-addition="!!tokenMnemonic"
          :decimal="currentTokenDecimals"
          :indent="currentTokenDecimals"
        />
      </div>
    </div>
    <div class="d-flex is-indent">
      <div class="d-flex flex-column is-line">
        <span class="is-label">{{ $t('projects.form.secondStep.projectDateStartLabel') }}</span>

        <AuInput
          :model-value="dateStart"
          :min-date="minDate"
          :format="formatView"
          :enable-minutes="false"
          type="Date"
          enable-time-picker
          required
          clearable
          :placeholder="$t('projects.form.secondStep.projectDateStartPlaceholder')"
          @update:modelValue="setDateStart"
        />
      </div>
      <div class="d-flex flex-column  is-line">
        <span class="is-label">{{ $t('projects.form.secondStep.projectDateEndLabel') }}</span>

        <AuInput
          :model-value="dateEnd"
          :min-date="minDateAfterMinDate"
          :format="formatView"
          :enable-minutes="false"
          type="Date"
          enable-time-picker
          required
          clearable
          :placeholder="$t('projects.form.secondStep.projectDateEndPlaceholder')"
          @update:modelValue="setDateEnd"
        />
      </div>
    </div>
    <div class="is-label">
      {{ $t('projects.form.secondStep.projectLimitMinCount') }}
    </div>
    <div class="is-indent">
      <AuInput
        v-model="minCount"
        clearable
        :placeholder="$t('projects.form.secondStep.projectLimitMinCountPlaceholder')"
        @input="checkMinCount"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.secondStep.projectLimitOneHandLabel') }}
    </div>
    <div class="is-indent">
      <AuInput
        v-model="quote"
        clearable
        :placeholder="$t('projects.form.secondStep.projectLimitPlaceholder')"
        @input="checkQuote"
      />
    </div>
    <div
      v-if="!showPriceRedeem"
      class="is-label"
    >
      {{ $t('projects.form.secondStep.projectExpectedProfitLabel') }}
    </div>
    <div
      v-if="!showPriceRedeem"
      class="is-indent"
    >
      <AuInput
        v-model="expectedProfit"
        type="Mask"
        required
        clearable
        :placeholder="$t('projects.form.secondStep.projectExpectedProfitPlaceholder')"
        :mask="Number"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.secondStep.projectSectionLabel') }}
    </div>
    <div class="is-indent">
      <AuSelect
        :value="section"
        :options="capitalsSectionsOptions"
        required
        clearable
        :placeholder="$t('projects.form.secondStep.projectSectionPlaceholder')"
        @input="value => section = value"
      />
    </div>
    <div
      v-if="showPriceRedeem"
      class="is-label"
    >
      {{ $t('projects.form.secondStep.projectPriceRedeemLabel') }}
    </div>
    <div
      v-if="showPriceRedeem"
      class="is-indent"
    >
      <AuInput
        v-model="priceRedeem"
        required
        :placeholder="$t('projects.form.secondStep.projectPriceRedeemPlaceholder')"
        type="Mask"
        :mask="Number"
      />
    </div>
    <div
      v-if="showPriceRedeem"
      class="is-label"
    >
      {{ $t('projects.form.secondStep.projectDateCloseLabel') }}
    </div>
    <div
      v-if="showPriceRedeem"
      class="is-indent"
    >
      <AuInput
        :model-value="dateClose"
        :min-date="minDateAfterMinDate"
        :format="formatView"
        :enable-minutes="false"
        enable-time-picker
        type="Date"
        required
        clearable
        :placeholder="$t('projects.form.secondStep.projectDateClosePlaceholder')"
        @update:modelValue="setDateClose"
      />
    </div>
    <div class="d-flex">
      <div
        class="is-back d-flex justify-content-center align-items-center"
        @click="prevStep"
      >
        <span>{{ $t("projects.form.backButton") }}</span>
      </div>
      <div
        class="is-send d-flex justify-content-center align-items-center"
        @click="nextStep"
      >
        <span>{{ $t("projects.form.nextButton") }}</span>
      </div>
    </div>
    <div
      v-if="showError"
      class="error"
    >
      {{ $t("projects.form.error") }}
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  props: {
    id: {
      type: String,
      default: "new"
    },
    tokensCount: {
      type: String,
      default: null
    },
    showPriceRedeem: {
      type: Boolean,
      default: false
    },
    secondPartOfProjectInfo: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      minCount: null,
      minCol: null,
      dateStart: null,
      dateEnd: null,
      section: null,
      priceBase: null,
      tokenQuote: null,
      tokenMnemonic: "",
      priceRedeem: null,
      quote: null,
      expectedProfit: null,
      expectedProfitDecimal: 4,
      dateClose: null,
      showError: false
    };
  },

  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      sectionCapital: "sectionCapital",
      requestsToIssue: "requestsToIssue"
    }),

    minDate() {
      return new Date(new Date().getTime()
      - new Date().getSeconds() * 1000
      - new Date().getMinutes() * 60000
      - new Date().getHours() * 3600000);
    },

    minDateAfterMinDate() {
      return new Date(this.dateStart
        ? this.dateStart.getTime() + (24 * 60 * 60 * 1000) * 2
        : this.minDate.getTime() + (24 * 60 * 60 * 1000) * 2);
    },

    tokenQuotesOptions() {
      let allTokens = [...this.tokensFiat, ...this.tokensGold];
      return _.map(allTokens, token => ({ display: token.nameEng, value: `/api/au_tokens/${token.id}`, decimals: token.decimals }));
    },

    currentTokenDecimals() {
      const token = _.find(this.tokenQuotesOptions, { value: this.tokenQuote });
      if (token) {
        return token.decimals;
      }

      return 0;
    },

    capitalsSectionsOptions() {
      return _.map(this.sectionCapital, section => ({ display: section.name, value: `/api/section_capitals/${section.id}` }));
    },

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    minCap() {
      return this.tokenQuote ? BigNumber(this.minCol).multipliedBy(this.priceBase).toString(10) : 0;
    },

    maxCap() {
      return this.tokenQuote ? BigNumber(this.tokensCount).multipliedBy(this.priceBase).toString(10) : 0;
    },

    mode() {
      return this.id === "new" ?
        this.$t("projects.form.secondStep.labelNew") :
        this.$t("projects.form.secondStep.labelEdit");
    },

    profit() {
      if (!this.showPriceRedeem) {
        return this.expectedProfit.replace(",", ".") * Math.pow(10, this.expectedProfitDecimal);
      }
      const pricePercent = ((this.priceRedeem - this.priceBase) * 100) / this.priceBase;
      const yearCoefficient = Math.floor(365 / moment(this.dateClose).diff(this.dateEnd, "days"));
      return pricePercent * yearCoefficient;
    }
  },

  async mounted() {
    if (this.secondPartOfProjectInfo) {
      this.minCount = this.secondPartOfProjectInfo.minCount,
      this.minCol = this.secondPartOfProjectInfo.minCol;
      this.dateStart = this.secondPartOfProjectInfo.dateStart;
      this.dateEnd = this.secondPartOfProjectInfo.dateEnd;
      this.section = this.secondPartOfProjectInfo.section;
      this.priceBase = this.secondPartOfProjectInfo.priceBase;
      this.tokenQuote = this.secondPartOfProjectInfo.tokenQuote;
      this.quote = this.secondPartOfProjectInfo.quote;
      this.tokenMnemonic = this.secondPartOfProjectInfo.tokenMnemonic;
      this.expectedProfit = (this.secondPartOfProjectInfo.expectedProfit / Math.pow(10, this.expectedProfitDecimal)).toString(10).replace(".", ",");
      if (this.showPriceRedeem) {
        this.priceRedeem = this.secondPartOfProjectInfo.priceRedeem;
        this.dateClose = this.secondPartOfProjectInfo.dateClose;
      }
    }

    if (_.isEmpty(this.requestsToIssue)) {
      await this.getRequestsToIssue();
    }
    if (_.isEmpty(this.sectionCapital)) {
      await this.getSectionCapital();
    }
    if (this.id !== "new" && !this.secondPartOfProjectInfo) {
      this.setFields(this.requestsToIssue.find(item => item.id == this.id));
    }
  },

  methods: {
    ...mapActions({
      getRequestsToIssue: "getRequestsToIssue",
      getSectionCapital: "getSectionCapital"
    }),

    setDateStart(value) {
      this.dateStart = this.getDate(value);

      if (this.dateEnd && this.dateEnd.getTime() < this.minDateAfterMinDate.getTime()) {
        this.dateEnd = null;
      }

      if (this.dateClose && this.dateClose.getTime() < this.minDateAfterMinDate.getTime()) {
        this.dateClose = null;
      }
    },

    setDateEnd(value) {
      this.dateEnd = this.getDate(value);
    },

    setDateClose(value) {
      this.dateClose = this.getDate(value);
    },

    getDate(value) {
      if (!value) {
        return value;
      }

      value = new Date(value.getTime() - 20 * 60000);

      if (value.getMinutes() < 30) {
        return new Date(value.getTime()
        - value.getSeconds() * 1000
        - value.getMinutes() * 60000);
      }
      else {
        return new Date(value.getTime()
        + (60 - value.getSeconds()) * 1000
        + (59 - value.getMinutes()) * 60000);
      }
    },

    checkInput(value) {
      const buf = value.match(/^[0-9]{1,}[.,]?[0-9]{0,2}/g);
      return buf ? buf[0].replace(",", ".") : null;
    },

    checkQuote() {
      this.quote = this.checkInput(this.quote);
      if (this.quote && BigNumber(this.quote).isGreaterThan(this.tokensCount)) {
        this.quote = this.quote.slice(0, -1);
      }
    },

    checkMinCount() {
      this.minCount = this.checkInput(this.minCount);
      if (this.minCount && BigNumber(this.minCount).isGreaterThan(this.tokensCount)) {
        this.minCount = this.minCount.slice(0, -1);
      }
    },

    prevStep() {
      let secondPartOfProjectInfo = {
        minCount: this.minCount,
        minCol: this.minCol,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        section: this.section,
        priceBase: this.priceBase,
        tokenQuote: this.tokenQuote,
        quote: this.quote,
        expectedProfit: this.profit.toString(),
        tokenMnemonic: this.tokenMnemonic
      };
      if (this.showPriceRedeem) {
        secondPartOfProjectInfo.priceRedeem = this.priceRedeem;
        secondPartOfProjectInfo.dateClose = this.dateClose;
      }


      this.$emit("secondPartOfProjectInfo", secondPartOfProjectInfo);
      this.$emit("prevStep");
    },

    formatView(date) {
      return moment(date).locale("ru").format("L HH:mm");
    },

    setTokenQuote(value) {
      this.tokenQuote = value;
      let selectedToken = _.find([...this.tokensFiat, ...this.tokensGold], { id: Number(value.split("/")[value.split("/").length - 1]) });
      this.tokenMnemonic = selectedToken.mnemonicEng;
    },

    nextStep() {
      let secondPartOfProjectInfo = {
        minCol: this.minCol,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        section: this.section,
        priceBase: this.priceBase,
        tokenQuote: this.tokenQuote,
        tokenMnemonic: this.tokenMnemonic,
        minCap: this.minCap,
        maxCap: this.maxCap,
        expectedProfit: this.profit.toString()
      };
      if (this.showPriceRedeem) {
        secondPartOfProjectInfo.priceRedeem = this.priceRedeem;
        secondPartOfProjectInfo.dateClose = this.dateClose;
      }

      if (Object.values(secondPartOfProjectInfo).length != _.filter(Object.values(secondPartOfProjectInfo)).length) {
        this.showError = true;
      }
      else {
        secondPartOfProjectInfo.quote = this.quote;
        secondPartOfProjectInfo.minCount = this.minCount;
        this.$emit("secondPartOfProjectInfo", secondPartOfProjectInfo);
        this.$emit("nextStep");
      }
    },

    goBack() {
      this.$router.push({ name: "my projects" });
    },

    setFields(data) {
      this.minCount = data.minCount,
      this.minCol = (data.minCap / data.priceBase).toString(10);
      this.dateStart = new Date(Date.parse(data.dateStart));
      this.dateEnd = new Date(Date.parse(data.dateEnd));
      this.section = data.section;
      this.priceBase = data.priceBase;
      this.tokenQuote = data.tokenQuote;
      this.quote = data.quote;
      this.expectedProfit = (data.expectedProfit / Math.pow(10, this.expectedProfitDecimal)).toString(10).replace(".", ",");
      this.setTokenQuote(data.tokenQuote);
      if (this.showPriceRedeem) {
        this.priceRedeem = data.priceRedeem;
        this.dateClose = new Date(Date.parse(data.dateClose));
      }
    }
  }
};
</script>

<style lang="scss">
.form-header {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 500;
    font-size: 20px;
  }
}
.is2{
  font-weight: 400;
  font-size: 14px;
  padding: 20px;
}
.is-label{
  margin-bottom: 15px;
}
.is-line{
  width: 50%;
}
.is-indent{
  margin-bottom: 35px;
  gap: 10px;
}
.is-small-indent{
  margin-bottom: 15px;
}
.error {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: red;
}
.is-text-area{
  width: 100%;
  height: 150px;
  border-radius: 12px;
  padding-left: 15px;
  padding-top: 12px;
  resize: none;
}
.is-minmax{
  border: 1px solid #289566;
  border-radius: 12px;
  padding: 12px 15px 12px;
}
.is-text-inside{
  color: var(--color-60);
}
.is-calendar{
  border: 1px solid var(--color-80);
  border-radius: 12px;
  padding: 13px 15px 13px;
}
.is-back{
  border: 1px dashed var(--color-80);
  border-radius: 15px;
  width: 180px;
  height: 44px;
  margin-right: 15px;
  &:hover {
    cursor: pointer;
  }
}
.is-send{
  border: 1px dashed #289566;
  border-radius: 12px;
  height: 44px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}
</style>