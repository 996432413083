<template>
  <WizardWrap
    v-if="!loading"
    :texts="texts"
    :stage="stage"
  />
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import WizardWrap from "./WizardWrap";

export default {
  components: {
    WizardWrap
  },

  data() {
    return {
      texts:
        [
          {
            label: {
              text: "To create new project you should add company information on "
            },
            link: {
              text: "Compnany page.",
              link: "/companies"
            }
          }
        ],
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      companies: "companies"
    }),

    stage() {
      if (_.some(this.companies, c => c.isVerified)) {
        return -1;
      }

      return 0;
    }
  },

  async mounted() {
    await this.getCompanies();
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getCompanies: "getCompanies"
    })
  }
};
</script>