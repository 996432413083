<template>
  <div v-if="projects.length > 0 || loading">
    <div
      v-if="projects.length > 0 || loading"
      class="title"
    >
      <span v-if="isInvestment">My orders</span>
      <span v-else>Projects</span>
      <RouterLink
        v-if="isInvestment"
        :to="{ path: '/briefcase', query: { selectedTitle: 'My orders' }}"
      >
        All orders
        <AuIcon icon="chevron_right" />
      </RouterLink>

      <RouterLink
        v-if="!isInvestment"
        to="/projects"
      >
        All projects
        <AuIcon icon="chevron_right" />
      </RouterLink>
    </div>

    <div class="projects">
      <template v-if="loading && filteredProjects.length == 0">
        <CapitalCardLoader
          v-for="item in skeletonItems"
          :key="item"
          :is-investment="isInvestment"
          :height="isInvestment == true ? '334px': '560px'"
        />
      </template>

      <template v-else>
        <CapitalCard
          v-for="proj in filteredProjects"
          :key="proj.id"
          class="project"
          :is-investment="isInvestment"
          :project="proj"
        />
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import CapitalCard from "@/components/capital/CapitalCard";
import CapitalCardLoader from "@/components/capital/CapitalCardLoader";
import { convertCapital } from "@/components/capital/capitalHelper";

export default {
  name: "DashboardProjects",

  components: {
    CapitalCard,
    CapitalCardLoader
  },

  props: {
    isInvestment: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      projects: [],
      filtersFlag: {},
      selectedCategoryId: null,
      auCapitals: [],
      page: 1,
      totalItems: 1,
      loading: true,
      type: "All",
      screenWidth: window.innerWidth
    };
  },

  computed: {
    ...mapGetters({
      sectionCapital: "sectionCapital"
    }),

    skeletonItems() {
      let array = [];

      for (let index = 0; index < this.take; index++) {
        array.push(index);
      }

      return array;
    },

    take() {
      if (this.screenWidth <= 600) {
        return 1;
      }

      if (this.screenWidth <= 900) {
        return 2;
      }

      if (this.screenWidth <= 1400) {
        return 3;
      }

      return 4;
    },

    filteredProjects() {
      return _.take(this.projects, this.take);
    }
  },

  created() {
    window.addEventListener("resize", this.windowResize);
  },

  destroyed() {
    window.removeEventListener("resize", this.windowResize);
  },

  async mounted() {
    await this.getSectionCapital();
    await this.getCapital();
    for (let i = 0; i < this.sectionCapital; ++i) {
      let item = {};
      item[this.sectionCapital[i].id] = false;
      this.filtersFlag.push(item);
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination",
      getInvestmentsAuCapitals: "capitals/getInvestmentsAuCapitals",
      sendCapital: "sendCapital"
    }),

    windowResize(screenWidth) {
      this.screenWidth = screenWidth.target.innerWidth;
    },

    async getCapital() {
      try {
        let resp = null;
        if (this.isInvestment) {
          resp = await this.getInvestmentsAuCapitals({
            page: this.page,
            status: "open",
            itemsPerPage: 4
          });
        }
        else {
          resp = await this.getAllAuCapitalsPagination({
            page: this.page,
            itemsPerPage: 4,
            status: "open,wating",
            order: "desc"
          });
        }

        this.auCapitals = resp.data;
        this.totalItems = resp.totalItems;

        const capitalData = this.auCapitals;
        this.projects = await this.convertAuCapitals(capitalData);
      }
      catch (e) {
        console.log("error", e);
      }
    },

    async convertAuCapitals(auCapitals) {
      let projects = [];
      for (const item of auCapitals) {
        const project = convertCapital(item, this.sectionCapital);
        projects.push(project);
      }

      return projects;
    }
  }
};
</script>

<style lang="scss" scoped>
.switches {
  gap: 10px;
}

.filter-line {
  margin-bottom: 35px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 4px;
  font-size: 22px;

  a {
    padding-top: 4px;
    font-size: 11px;
    color: var(--color-50);
    text-decoration: none;
  }
}

.projects {
  display: flex;
  gap: 25px;
}

.selector {
  min-width: 220px;
}
</style>
