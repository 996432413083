<template>
  <button
    type="button"
    class="btn"
    :class="type"
    :style="cssVars"
    :bordered="bordered"
    :customBordered="borderColor != ''"
    :center="center"
    :popup="popup"
    :disabled="disabled || isProgress"
    @click="$emit('click')"
  >
    <SpinnerLoader
      v-if="isProgress"
      color="#5AC396"
      :size="loaderHeight"
    />
    <AuIcon
      v-if="icon && !isProgress"
      :icon="icon"
      @click="$emit('click')"
    />
    <div
      v-if="$slots['default'] && !isProgress"
      class="btn__text"
    >
      <slot />
    </div>
    <AuIcon
      v-if="iconAfter && !isProgress"
      :icon="iconAfter"
    />
  </button>
</template>

<script>
import SpinnerLoader from "@/loaders/progress-spinner";
import { mapGetters } from "vuex";
export default {
  components: {
    SpinnerLoader
  },
  props: {
    icon: {
      type: String,
      default: ""
    },
    iconAfter: {
      type: String,
      default: ""
    },
    bordered: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: ""
    },
    borderColor: {
      type: String,
      default: ""
    },
    borderRadius: {
      type: [Number, String],
      default: 8
    },
    center: {
      type: Boolean,
      default: false
    },
    activeBgColor: {
      type: String,
      default: ""
    },
    activeColor: {
      type: String,
      default: ""
    },
    hoveredBgColor: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: "14px"
    },
    fontColor: {
      type: String,
      default: ""
    },
    isProgress: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    popup: {
      type: Boolean,
      default: false
    }
  },

  emits: ["click"],

  data() {
    return {
      basicWidth: "auto",
      basicHeight: "44px"
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    cssVars() {
      const backgroundColor = this.type == "primary" ? "#2aba7c" : this.activeBgColor;

      return {
        "--width": this.currentWidth,
        "--height": this.currentHeight,
        "--border-radius": this.borderRadius + "px",
        "--border-color": this.borderColor || "#CDCDCD",
        "--background-color": backgroundColor,
        "--background-hovered-color": this.hoveredBgColor || this.bgDefColor,
        "--background-active-color": this.activeColor || backgroundColor,
        "--font-color": this.fontDefColor,
        "--font-size": this.fontSize
      };
    },

    onlyIcon() {
      return this.$slots["default"] == null;
    },

    currentWidth() {
      if (this.width) {
        return this.width;
      }
      else if (this.onlyIcon) {
        return "auto";
      }

      return this.basicWidth;
    },

    currentHeight() {
      if (this.height) {
        return this.height;
      }
      else if (this.onlyIcon) {
        return "auto";
      }

      return this.basicHeight;
    },

    loaderHeight() {
      return this.height ? this.height : "34px";
    },

    fontDefColor() {
      if (this.type == "primary" || this.disabled) {
        return "#FFFFFF";
      }
      if (this.fontColor) {
        return this.fontColor;
      }
      return this.isLightTheme ? "#080808" : "#FFFFFF";
    },

    bgDefColor() {
      return this.isLightTheme ? "#8adcba" : "#3b8d6b";
    }
  }
};
</script>

<style scoped lang="scss">
button[width-limit] {
  max-width: 10px;
  margin-right: -6px;
}
button.btn {
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;

  width: var(--width);
  height: var(--height);

  border: 1px solid var(--color-70);

  .btn__text {
    color: var(--font-color);
    font-size: var(--font-size);
  }

  &[popup=true] {
    .btn__text {
      color: var(--color-60);
      &:hover {
        color: var(--font-color);
      }
    }
  }

  &:active {
    border-color: var(--btn-border-color-def);
  }

  &[active=true] {
    border-color: var(--btn-border-color-def);
    background-color: var(--background-hovered-color);
    transition: background-color 0.2s ease;
  }

  &:active{
    border-color: var(--btn-border-color-def);
    background-color: var(--background-active-color);
  }

  &:hover {
    background-color: var(--background-hovered-color);
    transition: background-color 0.2s ease;

    &:active{
      border-color: var(--btn-border-color-def);
      background-color: var(--background-active-color);
    }
  }

  &[bordered=true] {
    border: 1px solid var(--color-70);
  }

  &[customBordered=true] {
    border: 1px solid var(--border-color);
  }

  &[center=true] {
    justify-content: center;
  }

  .primary {
    background-color: var(--btn-dg-color-primary);
    .btn__text {
      color: #FFFFFF;
    }
  }

  &[disabled]{
    background-color: var(--btn-dg-color-dis);
    opacity: 1;
    .btn__text {
      color: var(--btn-ft-color-dis);
    }
  }
}
</style>
