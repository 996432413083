<template>
  <span>
    <p>User Agreement Autentic LTD 20.03.2024</p>

    This user agreement sets out the terms and conditions between you and us on which you can access our website https://autentic.capital (site, website, platform). This user agreement applies to all users and visitors to our website.

    Your use of our site means that you accept and agree to abide by all of the terms of this user agreement.

    The website https://autentic.capital is a website operated by Autentic Ltd, a company incorporated in Seychelles for the purpose of operating the Autentic platform, Registered at Suite 1, Second Floor, Sound & Vision House, Francis Rachel Str., Victoria, Mahe, Seychelles.

    Please read this agreement carefully before using the Website. By continuing to use the Website, you agree to be bound by these terms and conditions and the following policies:

    Autentic's Privacy Policy - Accessed at https://cabinet.autentic.capital/privacy-policy/

    If you do not agree to this User Agreement, you should stop using this Website immediately. We reserve the right to modify or amend these agreements, the Website or any content on the Website from time to time, including for security, legal or regulatory reasons, or to reflect updates or changes to the services or features of the platform.

    You are encouraged to periodically review this agreement to ensure that you are familiar with and abide by the current version. Changes are binding on users of the Website and will be effective immediately upon posting of the revised documentation on the Website. You agree to abide by such change and your continued use of the Website will constitute your acceptance of such change.

    We will indicate on the Site that the Terms have changed. You agree that by doing so, we have provided you with sufficient notice of such change.

    If you decide to become an issuer or investor with Autentic, you must comply with our Issuer Terms or Investor Terms, as applicable. This agreement should be read together and applied in addition to our Issuer Terms or our Investor Terms.


    1. Prohibited use

    You may only use our site for lawful purposes.
    You may not use our website:

    - in any manner that violates any applicable local, national or international laws or regulations;

    - in any manner that is unlawful or fraudulent or has an unlawful or fraudulent purpose or effect;

    - with the intent to harm or attempt to harm minors in any way;

    - send, knowingly receive, upload, download, use or reuse any material that does not comply with our content standards set out below;

    - transmit or cause to be sent any unsolicited or unauthorized advertising or promotional material or any other form of similar offer (spam); or

    - knowingly transmit any data, send or download any material containing viruses, Trojan horses, worms, time bombs, keystroke loggers, spyware, adware or any other malware or similar computer code designed to adversely affect the operation of any computer software or hardware;

    - Use the Website in any manner that could or reasonably be expected to damage or overload any of our servers or any network connected to any of our servers;

    - Use the Website in any manner that may, or could reasonably be expected to, interfere with the use of the Website by any other party or that is or may be contrary to any applicable law or the rights or statutory rights of any party under any applicable law;

    - Use any robot, parser, screen cleaner, data aggregation tool or other automated device, procedure or process (automated process) to process, monitor, copy or retrieve any web pages on the Website or any information, content or data contained therein. or available through the Website without our prior written permission;

    - Use any automated process to aggregate or combine information, content or data contained on or available through the Website with information, content or data available through or obtained from any third party;

    - Use any automated process to interfere or attempt to interfere with the proper operation of the Website or any transaction or process conducted on or through the Website;

    - Publish upload, transmit, distribute, store, create or otherwise publish or make available through the Website any content that is false, misleading, erroneous, fraudulent or otherwise likely to cause confusion to users of the Website, or content that is unlawful or defamatory. , defamatory, offensive to any religious or moral group, obscene, pornographic, indecent, indecent, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory or otherwise objectionable;

    - Perform any act that places an unreasonable or disproportionately large load on the infrastructure or bandwidth connecting to the Site;

    - Attempt to gain unauthorized access to the Website, the server on which the Website is stored, or any server, computer or database connected to the Website;

    - Attempt to attack the Website through a DDoS denial of service attack or a distributed denial of service attack;

    - Perform reverse engineering, reverse assembling, decompiling or otherwise attempting to discover source code or other arithmetic formulae or processes with respect to the software underlying the infrastructure and processes associated with the Website;

    - Copy, reproduce, modify, alter, change, modify, create derivative works or publicly display any part of any content on the Website without our prior written permission.

    - Your use of the Website in violation of these agreements may result in, among other things, termination or suspension of your rights to use the Website, and we may, in our sole discretion, report a breach of any such obligation that also constitutes a violation of applicable law to the appropriate law enforcement authorities and will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the Website will terminate immediately.


    2. Content Standards

    These content standards apply to all material you post on our site (contributions), as well as any interactive services associated with it.

    You must comply with the spirit and letter of the following standards. The standards apply to each part of any attachment as well as to the whole.

    Attachments should:

    - be accurate (where the facts are stated);

    - be sincere (where they express their opinions);

    - comply with the applicable laws of any country from which they are posted.

    Attachments should not:

    - contain any material that discredits any person;

    - contain any material of an obscene, offensive, hateful or inflammatory nature;

    - to promote sexually explicit material;

    - to promote violence;

    - advocate discrimination on the basis of race, gender, religion, nationality, disability, sexual orientation or age;

    - infringe any copyright, database or trademark rights of any other person;

    - the likelihood of anyone being deceived;

    - be committed in breach of any legal obligations to a third party, such as contractual or confidentiality obligations;

    - promote any illegal activity;

    - threaten, insult or invade the privacy of another person or cause annoyance, inconvenience or unnecessary disturbance;

    - may disturb, upset, embarrass, alarm or annoy any other person;

    - be used to impersonate another person or to misrepresent your identity or affiliation;

    - give the impression that they are coming from us when they are not; or

    - defend, promote or assist any illegal activities such as (by way of example only) copyright infringement or computer misuse.


    3. Disclaimers and risk warnings

    The Website is a platform that allows you to invest directly in tokens, including those issued by third parties. If tokens are placed by third parties, in such case, we do not own or offer the investment opportunities presented on the Website.

    You acknowledge and agree that no content published on the Website constitutes a recommendation that any particular idea, investment, portfolio, transaction or investment strategy is suitable or appropriate for you or any other particular person. You further understand that neither we nor any of the information providers, their affiliates or any third parties will advise you personally as to the nature, potential, feasibility, value or suitability of any particular investment, portfolio, transaction, investment strategy or other.

    The content and materials available on the Website are for informational purposes only and are not intended to fulfill your specific requirements. In particular, the content and materials available on the Website do not constitute any form of advice or recommendation on our part, should not be construed as an offer, solicitation, invitation or recommendation to buy or sell tokens, make investments, purchase securities or any other financial services and are not intended to be relied upon by you in making (or refraining from making) any particular investment or other decision. We recommend that you seek independent advice from a financial advisor before making such a decision.

    The value of investments and the income derived from them may fluctuate and you may not necessarily be able to recover the amount invested. Past performance of investments is not necessarily indicative of their future performance. You may find it difficult to sell or value certain investments or to obtain reliable information about their value or the degree of risk to which they are exposed.

    You acknowledge and agree that prices, descriptions and other financial data available on the Website may be collected from third party sources believed to be reliable and, where possible, such data is verified by us to the extent commercially possible. Notwithstanding this, you acknowledge and agree that the calculations, profiles and data available on the Website are made using such third party data and that neither such data nor such calculations are guaranteed by those sources, us, the information providers or any other person or entity and may not be accurate or complete.

    You acknowledge and agree that project returns posted by us on the Website are indicative only and are subject to economic, business and other factors beyond our control, and we do not guarantee that such returns will be available to you at any time.

    Nothing included in the Website constitutes an offer or solicitation to sell or distribute investments and related services to anyone in any jurisdiction.

    From time to time, our website may make reference to previous investments or data that we have published. These references may be selective or partial. As markets are constantly changing, previously published information and data may be out of date and should not be relied upon.


    4. Disclaimer of warranties

    The Website is provided "as is" without any express or implied warranties of any kind. We and our affiliates, including agents and licensors, cannot and do not warrant the accuracy, reliability, quality or completeness of the information provided on the Website or that the services provided through the Website will be timely, non-infringing, of commercial quality or fit. for a particular purpose, and we hereby disclaim any such express or implied warranties, except those that cannot be lawfully excluded.

    While we make every effort to ensure that the services offered on the Website are always available, the nature of the Internet means that we cannot guarantee the availability of the Website. We do not warrant that the provision of the Website or any part of the Website will be available, uninterrupted or error free, that defects in the Website will be corrected or that the Website will be free of viruses or other harmful components. We will not be liable for any loss, damage, cost, expense, delay or other liability (including but not limited to any financial loss) that you may suffer as a result of your use of the Website or as a result of the Website or any part of it. that it is unavailable at any time for any reason.

    You are solely responsible for obtaining access to the Website and for any fees (such as internet service charges) associated with such access. Similarly, you are solely responsible for all equipment necessary for you to access this Website and for installing and maintaining anti-virus software on your computer.

    Information transmitted through the Website will be transmitted over publicly available telecommunications networks. We will not be liable if communications sent via the Website are intercepted by third parties, delayed, misdelivered or not delivered.


    5. Means of access

    The Website is generally intended to be viewed in a regular web browser on a personal computer. Although you may use other ways to access the Website, please be aware that the Website may not display accurately if you use other access methods.

    Certain parts of the Website are password protected or require you to log in. You must not gain or attempt to gain unauthorized access to such parts of the Website or any other protected material or information by any means not intentionally made available by us for your specific use.


    6. Eligibility and registration

    To access the Website, you must be at least eighteen 18 years of age (or twenty-one 21 years of age) Depending on where you live) and have full legal capacity. You warrant, represent and covenant that you have the legal right and experience, knowledge and ability to access and use the Website in accordance with this User Agreement.

    To access our services and some parts of the Restricted Website, you must register as an investor or issuer. Please note that an application for both investor and issuer registration may be rejected.

    To become an investor or issuer, you will need to complete a registration process. First, you will need to provide some basic information about yourself (including your name and email address), you will be asked to choose a unique username and password, and you may be asked to answer certain screening questions. These are the methods we use to identify you, so you should always keep them secure.

    You will also need to provide details of either yourself or the project, the information you wish to provide as appropriate. We may also require you to provide proof of identity and proof of your current address (or the address of the directors, partners or representatives of the relevant project, as applicable) in order to fulfill our AML obligations. Applications for issuer and investor status on the website are subject to approval and may be accepted or rejected by Autentic in its sole discretion.

    You warrant, represent and covenant that the information you provide about yourself during the registration process is accurate, current and complete.


    7. Your login details

    Each time you log in to the Website, or parts of it, you will be required to enter your user ID and password, and you may be required to answer one or more test questions. Your user name and password are unique and non-transferable.

    You are responsible for all information and activities on the Website of anyone using your username and password. Accordingly, you must take steps to protect the confidentiality of your username and password, and you must notify us immediately if you become aware of any disclosure, loss, theft or unauthorized use of them.

    Notwithstanding the above, you:

    - You may not share or resell your username or password with third parties;

    - You must notify us at info@autentic.capital if you become aware of any possible unauthorized use of your username or password or any possible breach of security, including loss, theft or unauthorized disclosure of your username or password.

    If you authorize an employee, representative to use your login information, you are responsible for their actions on the Website. We reserve the right not to act on your instructions if we suspect that the person logged into your account on https://autentic.capital is not you, or we suspect illegal or fraudulent activity or unauthorized use.


    8. Links to and from other sites

    You may use hypertext or other computer links to access from the https://autentic.capital Website to websites operated by persons other than us. Such hyperlinks are provided solely for reference and convenience and are the sole responsibility of the owners or operators of such websites. You acknowledge and agree that we are not responsible for the content or operation of such websites and that we make no representations or warranties with respect to such websites, including, but not limited to, that the content of such websites does not infringe the rights of any third party or any applicable law.

    A link from this Web Site to another Web Site does not imply that we endorse or approve of the content of that Web Site, the operator or the activities of that Web Site. You are solely responsible for determining the extent to which you may use or rely on any content on any other websites you access from this Website. We have no control over the content on those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them.

    Our Website must not be hosted on any other website, and you may not create a link to any part of our Website, without our permission. We reserve the right to withdraw permission to link without notice.

    We are not responsible for the use or inability to use any third party software or other materials on the Website and shall have no liability to any person or entity for the use or inability to use any such third party software. or materials.


    9. Limitation of liability

    Your use of the Website is entirely at your own risk. Neither we nor any of our customers, representatives, affiliates, agents, officers, employees, suppliers or licensors shall be liable to you or anyone else for:

    - inaccuracy, delay, interruption of service, error or omission, regardless of the cause, or for any damage resulting therefrom;

    - a decision made or action taken or not taken by you based on the Website or its content;

    - damages, including, but not limited to, any direct, indirect, incidental, consequential, special, punitive, exemplary or similar damages, regardless of the theory of liability and even if the possibility of such damages has been advised in advance;

    - loss or damage resulting from the use of the content or the Website, in whole or in part, whether caused by negligence or by unforeseeable circumstances beyond the control of the company in acquiring, compiling, interpreting, reporting or delivering the Website and its content or otherwise;

    - loss, damage, liability, claim or expense (including, without limitation, legal fees and costs of defense or settlement) of any kind, whether direct, indirect or consequential, arising out of or relating to your use of the Website or its content, use of https://autentic.capital however caused, whether in contract, tort (including negligence), law or otherwise;

    - damage arising from a cause over which we have no control.

    Our total liability to you in connection with these Agreements and any other terms to which you may subscribe in connection with your use of the Website cannot be paid in any form.

    All exclusions and limitations of liability set forth in these Agreements shall apply to the maximum extent permitted by applicable law.

    You agree to fully indemnify us for any losses, costs or expenses however caused or incurred by us as a result of your breach of these agreements. If you use the Site in an improper or malicious manner, you agree to indemnify us for any losses incurred by us or any third party as a result of or in connection with such use.


    10. Site accessibility

    Because electronic services may be interrupted or out of service, access to the Website is provided on an "as is" and "as available" basis only.

    We reserve the right to restrict the availability of the Website to any person, geographic area or jurisdiction we wish and/or terminate your access to and use of the Website at any time and in our sole discretion.

    We may, in our sole discretion, impose restrictions on your use of the Website. In addition, for commercial, security, technical, technical, technical, technical, technical, legal or regulatory reasons or for any breach of these Terms, we may close the Website or your access to the Website or suspend such access at any time and without prior notice.


    11. Intellectual property rights

    You acknowledge that you are responsible for any material provided through your username or password, and you, not we, are solely responsible for such material, including its accuracy, legality, reliability, appropriateness, originality and copyright. Notwithstanding the foregoing, we have the right to refuse to post, remove, edit or abridge any materials for any reason and to disclose any materials as we deem necessary, including, without limitation, to achieve compliance with any applicable law, regulation, legal process or governmental request, all in our sole discretion.

    Any comments, suggestions, ideas, materials or other information you provide through https://autentic.capital will be retained by Autentic and will remain with us forever.

    We own all present and future copyrights, registered and unregistered trademarks, design rights, unregistered designs, database rights and all other present and future intellectual property rights and rights in the form of intellectual property rights existing in or relating to the Website.

    If and to the extent that any such Intellectual Property Rights are assigned to you by operation of law or otherwise, you agree to do any and all such acts and execute any and all such documents now or in the future as we may reasonably request in order to return such Intellectual Property Rights to us.

    You retain ownership of all copyrights in all data that you upload or submit to the Website. You grant us a worldwide, perpetual, exclusive, royalty-free, perpetual license to use, copy, distribute, publish and transmit such data in any manner.

    We do not warrant or represent that the content of the Website does not infringe the rights of third parties.


    12. Copyrights and trademarks

    The Website and its contents are our property or the property of our partners and are protected by copyright, trademark, patent and other applicable laws.

    Except as necessary and related to personal viewing of the Website through a web browser or as permitted by these agreements, no part of the Website may be reproduced, stored (for any period of time) in an electronic or any other search engine, modified, adapted, uploaded to a third party location, framed, presented publicly or transmitted in any form by any means without our specific prior written consent.


    13. Privacy

    You undertake that at no time after we have provided you with a unique username, you will not disclose or communicate to any person (other than professional representatives or advisers or as may be required by law or any legal or regulatory authority) any Confidential Information, and will use your best efforts to prevent the unauthorized publication or disclosure of any Confidential Information, and will use such Confidential Information only for the purposes of the Offer. Confidential Information for the purposes of this paragraph includes all information in any form (including visual, oral or electronic) relating to us or any investor or issuer (including all investor or business profiles) or individual or company that was previously an investor or vendor that is provided or disclosed through the Website (or to any employees or agents) in connection with the use of the Website.


    14. Suspension and termination

    We will determine, in our sole discretion, whether there has been a breach of this agreement, acceptable use when you use our site. In the event of a breach of the agreement, we may take any action we deem appropriate.

    We may block or delete a User's account or deny access using any account to the Site and its functionality, including if the User violates this User/Autentic LTD relationship.

    We exclude liability for actions taken in response to violations of this Acceptable Use Agreement. The responses described in this agreement are not limited, and we may take any other action we deem appropriate.

    All limitations and all disclaimers, exclusions and limitations of our liability will continue in effect during the suspension and will survive any termination. After suspension or termination, you may not directly or indirectly access or use the Website.


    15. General conditions

    If we do not insist that you strictly comply with these terms or if we fail to exercise any of our rights or remedies to which we are entitled under these terms and conditions or any other document referred to herein, this will not amount to a waiver of such rights or remedies, nor will it relieve you of your obligations under these terms and conditions.

    No waiver, in whole or in part, of any such right or remedy shall limit our ability to exercise that right or remedy or any other right or remedy in the future.

    If any of these terms and conditions or any provision of any other document submitted as part of your relationship with us is found by a competent authority to be invalid, illegal or unenforceable to any extent, then such term or condition will be removed from the user agreement, which will remain in full force and effect to the extent permitted by the laws of the various countries.

    Nothing in this Agreement is intended or will be used to create any partnership or joint venture between the parties, nor does it authorize any party to incur any obligation for the benefit of or on behalf of any other party.

    You should take your own tax advice. No warranty or representation is given as to your tax position that will apply to you after making any investment.

    Any notice or other communication to either party in connection with this agreement shall be made in electronic mail and sent to the e-mail address provided to the other party pursuant to this agreement. Our e-mail address for sending notices is: info@autentic.capital. Any notice or other communication will be deemed to have been received if sent by e-mail.

    All correspondence and communication between you and us will be in English.

    Any dispute or claim arising out of or in connection with the Website, its subject matter or formation will be governed by the laws of Seychelles.

    This agreement and any document expressly referred to herein constitute the entire agreement between us and supersede all prior discussions, correspondence, negotiations or agreements relating to this subject matter.


    16. Amendments to the agreement

    We may revise these Acceptable Use Agreements at any time by making changes to this document. You are expected to check the website and this user agreement from time to time to pay attention to any changes we make, as they are legally binding on you. Some of the provisions contained in this agreement may also be superseded by provisions or notices published elsewhere on our website.


    17. Contact us

    If you have any questions about this User Agreement, or would like to contact us for any reason, please contact us at info@autentic.capital
  </span>
</template>