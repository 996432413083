<template>
  <div class="emitent">
    <EmitentRates />
    <ExchangeRates />
    <LastTransactions />
  </div>
</template>

<script>
import EmitentRates from "@/components/exchangeRates/top/EmitentRates";
import ExchangeRates from "../components/exchangeRates/top/ExchangeRates.vue";
import LastTransactions from "@/components/lastTransactions/LastTransactions";

import { mapActions } from "vuex";
export default {
  name: "DashboardEmitent",
  components: {
    EmitentRates,
    ExchangeRates,
    LastTransactions
  },
  async mounted() {
    await this.getNfts({ page: 1 });
  },
  methods: {
    ...mapActions({
      getNfts: "goods/getCurrentUserNfts"
    })
  }
};
</script>

<style scoped>
.emitent{
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between
}
</style>