<template>
  <div class="emitent-rates">
    <ExchangeRate
      v-for="(rate, index) in rates"
      :key="index"
      :name="rate.name"
      :info="rate.info"
      :chart="rate.chart"
      :values="rate.values"
      :icon="rate.icon"
      :color="rate.color"
      :link="rate.link"
      :value-default="rate.valueDefault"
    />
  </div>
</template>

<script>
import CurrencySupport from "@/help/currencySupport";
import ExchangeRate from "./ExchangeRate";

import BigNumber from "bignumber.js";
import { mapGetters } from "vuex";

export default {
  name: "EmitentRates",

  components: {
    ExchangeRate
  },

  data() {
    return {
      goodsNamesDictionary: [this.$t("dashboard.rates.goodsNamesDictionary.one"), this.$t("dashboard.rates.goodsNamesDictionary.no"), this.$t("dashboard.rates.goodsNamesDictionary.many")],
      projectsNamesDictionary: [this.$t("dashboard.rates.projectsNamesDictionary.one"), this.$t("dashboard.rates.projectsNamesDictionary.no"), this.$t("dashboard.rates.projectsNamesDictionary.many")],
      companiesNamesDictionary: [this.$t("dashboard.rates.companiesNamesDictionary.one"), this.$t("dashboard.rates.companiesNamesDictionary.no"), this.$t("dashboard.rates.companiesNamesDictionary.many")],
      assetsNamesDictionary: [this.$t("dashboard.rates.assetsNamesDictionary.one"), this.$t("dashboard.rates.assetsNamesDictionary.no"), this.$t("dashboard.rates.assetsNamesDictionary.many")],
      currencySup: new CurrencySupport()
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      projectCount: "projectCount",
      companyCount: "companyCount",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      totalNtfs: "goods/totalNtfs"
    }),

    rates() {
      return [
        {
          name: this.$t("dashboard.rates.assetsNamesDictionary.name"),
          info: this.getCountVisualisation(this.auOwners, this.assetsNamesDictionary),
          chart: true,
          values: [0, 3, 2, 4],
          icon: "crypto_assets", color: "#9C2AF6",
          link: "briefcase",
          valueDefault: ""
        },
        {
          name: this.$t("dashboard.rates.projectsNamesDictionary.name"),
          info: this.getCountVisualisation([], this.projectsNamesDictionary, this.projectCount),
          chart: false, values: [0, 3, 2, 4],
          icon: "my_projects",
          link: "my projects",
          valueDefault: ""
        },
        {
          name: this.$t("dashboard.rates.companiesNamesDictionary.name"),
          info: this.getCountVisualisation([], this.companiesNamesDictionary, this.companyCount),
          chart: false,
          values: [0, 3, 2, 4],
          icon: "my_companies",
          link: "companies",
          valueDefault: ""
        }
      ];
    }
  },

  methods: {
    getCountVisualisation(values, dictionary, totalItems) {
      let result = "";

      if (totalItems) {
        values = totalItems;
      }
      else {
        values = values ? values.length : 0;
      }

      let count = BigNumber(values).mod(100).toNumber();
      const isSecondTen = count > 10 && Math.floor(count / 10) % 10 === 1;
      count = count % 10;

      if (count === 0 || (count > 4 && count < 9) || isSecondTen) {
        result = dictionary[2];
      }
      else {
        result = count === 1 ? dictionary[0] : dictionary[1];
      }

      return values + " " + result;
    }
  }
};
</script>

<style lang="scss" scoped>
.emitent-rates {
  display: flex;
  justify-content: space-between;
  ;
  gap: 20px;

  @media screen and (max-width:640px) {
    flex-wrap: wrap;
  }
}
</style>
