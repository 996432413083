<template>
  <div>
    <AuBreadcrumb
      :current-page-title="$t('router.pages.withdraw') + defaultTokenMnemonic"
    />
    <div class="card withdraw">
      <span class="title">
        {{ $t("withdraw.title") + defaultTokenMnemonic }}
      </span>
      <div v-if="!withdrawSuccess">
        <div class="body">
          <div v-if="stage === 1">
            <AuInput
              v-model="eth"
              :label="$t('withdraw.ethLabel')"
            />
            <span class="notice">
              {{ $t("withdraw.ethNotice") }}
            </span>
          </div>
          <div v-if="stage === 1">
            <AuInput
              v-model="amount"
              :label="$t('withdraw.sumLabel')"
              type="Number"
              :decimal="defaultTokenDecimals"
            />
            <span class="notice">
              {{ $t("withdraw.sumNotice") + availableFormat + " " + defaultTokenMnemonic }}
            </span>
          </div>
          <span v-if="stage===2">
            {{ message }}
          </span>
          <AuInput
            v-if="stage===2"
            v-model="code"
            :label="$t('withdraw.confirmCode')"
          />
          <span class="error">
            {{ error }}
          </span>
          <div class="btn-place">
            <AuButton
              v-if="stage===2 && !use2fa"
              class="btn"
              center
              :disabled="timer !== 0"
              @click="sendMail"
            >
              {{ sendBtnText }}
            </AuButton>
            <AuButton
              type="primary"
              class="btn"
              center
              :is-progress="progress"
              @click="doAction"
            >
              {{ withdrawBtnText }}
            </AuButton>
          </div>
        </div>
      </div>
      <div
        v-if="withdrawSuccess"
        class="success"
      >
        <span class="msg">{{ $t("withdraw.success") }}</span>
        <AuButton
          type="primary"
          width="40%"
          center
          @click="goToBriefcase"
        >
          {{ $t("withdraw.backBtnText") }}
        </AuButton>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Withdraw",
  data() {
    return {
      stage: 1,
      eth: null,
      amount: "",
      code: "",
      error: "",
      currency: "1",
      available: 0,
      availableFormat: 0,
      decimals: 2,
      withdrawSuccess: false,
      timer: 0,
      sendBtnText: this.$t("withdraw.sendCode"),
      progress: false,
      interval: null,
      eventId: null
    };
  },
  computed: {
    ...mapGetters({
      defaultTokenId: "defaultToken/defaultTokenId",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      ethAddresses: "ethAddresses",
      tokens: "tokens"
    }),

    message() {
      return this.use2fa ? this.$t("withdraw.twoFAMessage") : this.$t("withdraw.mailMessage");
    },

    withdrawBtnText() {
      return this.stage === 1 ? this.$t("withdraw.btnNext") : this.$t("withdraw.btnWithdraw");
    },

    use2fa() {
      return this.$auth.user().is2faEnabled;
    },

    defaultTokenDecimals() {
      return this.tokens.find(token => token.mnemonicEng == this.defaultTokenMnemonic)?.decimals;
    }
  },
  async mounted() {
    await this.getAvailableSum();
  },
  methods: {
    ...mapActions({
      getAuOwnerByTokenId: "getAuOwnerByTokenId",
      sendTokenEvents: "sendTokenEvents",
      sendMailConfirmation: "withdrawal/sendMailConfirmation",
      confirmMailCode: "withdrawal/confirmMailCode"
    }),

    async doAction() {
      switch (this.stage) {
      case 1:
        if (!this.$filters.correctETH(this.eth)) {
          this.error = this.$t("withdraw.errorCorrectEth");
          return;
        }
        if (this.amount === "" || this.amount == 0 || !this.amount || BigNumber(this.amount).isGreaterThan(this.available)) {
          this.error = this.$t("withdraw.errorCorrectSum");
          return;
        }
        this.error = "";
        this.progress = true;
        this.stage = this.stage + await this.withdraw();
        if (!this.use2fa && this.stage === 2) {
          this.startTimer();
        }
        this.progress = false;
        break;
      case 2:
        this.progress = true;
        await this.check();
        this.progress = false;
        break;
      }
    },

    async check() {
      const params = {
        code: this.code,
        tokenEvent: `/api/token_events/${this.eventId}`
      };
      try {
        await this.confirmMailCode(params);
        this.withdrawSuccess = true;

        try {
          if (window.carrotquest && process.env.VUE_APP_CARROT_ENABLED === "true") {
            window.carrotquest.track("withdraw", {
              "address": this.eth,
              "amount": this.amount
            });
          }
        }
        catch (error) {
          console.error(error);
        }

        if (this.interval) {
          clearInterval(this.interval);
        }
      }
      catch (e) {
        this.error = this.$t("withdraw.incorrectCode");
      }
    },

    goToBriefcase() {
      this.$router.push({ name: "briefcase" });
    },

    startTimer() {
      this.timer = 60;
      this.interval = setInterval(() => {
        this.timer--;
        this.sendBtnText = this.$t("withdraw.sendAgain") + this.timer + this.$t("withdraw.sec");
        if (this.timer === 0) {
          this.sendBtnText = this.$t("withdraw.sendCode");
          clearInterval(this.interval);
        }
      }, 1000);
    },

    async sendMail() {
      try {
        await this.sendMailConfirmation(this.eventId);
        this.startTimer();
        this.error = "";
      }
      catch (e) {
        this.error = e.response?.data?.message;
      }
    },

    async getAvailableSum() {
      try {
        const resp = await this.getAuOwnerByTokenId({
          tokenId: this.defaultTokenId,
          ownerAddress: this.ethAddresses[0].value
        });
        this.available = resp[0].amount;
        this.decimals = resp[0].auToken.decimals;
        let buf = BigNumber(this.available).dividedBy(BigNumber(10).pow(this.decimals));
        this.availableFormat = buf.isNaN() ? 0 : buf.toString(10);
      }
      catch (e) {
        this.available = 0;
        this.availableFormat = 0;
      }
    },

    async withdraw() {
      let params = {
        user: `api/users/${this.$auth.user().id}`,
        createdAt: new Date,
        type: "token_withdrawal",
        description: "withdraw",
        value: this.amount,
        token: `/api/au_tokens/${this.defaultTokenId}`,
        isTask: true,
        address: this.eth
      };
      try {
        const resp = await this.sendTokenEvents(params);
        this.eventId = resp.data.id;
      }
      catch (e) {
        this.error = this.$t("withdraw.error") + e.response?.data?.message;
        return 0;
      }
      return 1;
    }
  }
};
</script>

<style scoped lang="scss">
.withdraw {
  padding: 20px 20px 40px 20px;

  .title {
    font-size: 24px;
  }

  .body {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .notice {
      font-size: 12px;
    }

    .error {
      width: 100%;
      height: 20px;
      text-align: center;
      font-size: 14px;
      color: red;
    }

    .btn-place {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      @media screen and (max-width: 680px) {
        flex-direction: column;
        align-items: center;
        gap: 30px
      }
    }

    .btn {
      width: 40%;
      @media screen and (max-width: 680px) {
        width: 100%;
      }
    }
  }

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-top: 50px;

    .msg {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
  }
}
</style>