<template>
  <ProgressLoader
    v-if="loading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />

  <table
    v-else
    class="table"
    :class="`column-width-${columnWidth}`"
  >
    <thead>
      <tr>
        <th
          v-for="column in columns"
          :key="column"
          scope="col"
          :style="getTableHeaderStyle(column)"
        >
          {{ column.name }}
        </th>
        <th
          v-if="actionColumn && actionType == 'Simple'"
          scope="col"
          action-column
          :style="actionStyle"
        >
          {{ actionColumn.label }}
        </th>
        <th
          v-if="actionColumn && actionType == 'contextMenu'"
          scope="col"
          action-column
          :style="actionStyle"
        >
          {{ $t("briefcase.actions") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- eslint-disable -->
    <template v-for="(item, itemIndex) in items" :key="itemIndex">
      <!-- eslint-enable -->
        <tr>
          <td
            v-for="(prop, name, propIndex) in item"
            :key="name"
            :class="{checkbox : propIndex == 0 && checkboxes}"
            :style="getTableStyle(propIndex)"
          >
            <slot
              :name="getSlot(propIndex)"
              :value="prop"
            >
              <AuCheckbox
                v-if="(propIndex == 0 && checkboxes)"
                @change="value => clickCheckbox(value, itemIndex)"
              />
              <img
                v-if="(icons && icons.length != 0 && propIndex == 0)"
                :src="icons[itemIndex]"
                class="icon-with-bg"
                :class="{'icon-link' : isIconLinks}"
                @click="toLink(itemIndex)"
              >
              <span
                v-if="colorOrder && (prop == 'sale' || prop == 'buy')"
                class="order-sale"
                :class="{'order-buy': prop == 'buy'}"
              >
                {{ prop }}
              </span>
              <AuDecimalStatic
                v-else-if="isDecimalStaticCol(propIndex)"
                :value="parsedValue(prop)"
                :decimal="colIndent(propIndex, itemIndex)"
                :addition="parsedAddition(prop)"
              />
              <span
                v-else-if="propIndex == 0 && isIconLinks"
                :class="{'icon-link' : isIconLinks}"
                @click="toLink(itemIndex)"
              >{{ prop }}</span>
              <span
                v-else-if="goodsLink && isIconLinks"
                :class="{'icon-link' : isIconLinks}"
                @click="toLinkGoods(itemIndex)"
              >{{ prop }}</span>
              <span v-else>{{ prop }}</span>
            </slot>
          </td>
          <td
            v-if="actionColumn"
            action-column
            :style="actionStyle"
          >
            <AuIcon
              v-if="actionType == 'Simple'"
              clickable
              :icon="actionColumn.icon"
              @click="$emit('click', itemIndex)"
            />
            <AuIcon
              v-if="actionType == 'Dropdown'"
              :icon="actionColumn.icon[dropdownView[itemIndex] === true ? 1 : 0]"
              @click="showSlot(itemIndex)"
            />
            <div
              v-if="actionType == 'contextMenu'"
              class="action-wrapper"
            >
              <AuIcon
                :icon="actionColumn.icon"
                as-button
                @click="openContextMenu(itemIndex)"
              />
              <div
                :ref="`contextMenu_${itemIndex}`"
                v-click-outside="() => closeMenu()"
                class="closedContextMenu"
              >
                <AuButton
                  v-if="item.company?.isDefault"
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="topUp(itemIndex)"
                >
                  {{ $t("briefcase.changeAction") }}
                </AuButton>
                <AuButton
                  v-if="item.company?.isDefault"
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="$emit('toWithdraw', itemIndex)"
                >
                  {{ $t("briefcase.bringOutAction") }}
                </AuButton>
                <AuButton
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="send(itemIndex)"
                >
                  {{ $t("briefcase.sendAction") }}
                </AuButton>
              </div>
            </div>
            <div
              v-if="actionType == 'company'"
              class="action-wrapper"
            >
              <AuIcon
                :icon="actionColumn.icon"
                as-button
                @click="openContextMenu(itemIndex)"
              />
              <div
                :ref="`contextMenu_${itemIndex}`"
                v-click-outside="() => closeMenu()"
                class="closedContextMenu"
              >
                <AuButton
                  width="100%"
                  center
                  @click="edit(itemIndex)"
                >
                  {{ $t("counterparties.editAction") }}
                </AuButton>
                <AuButton
                  width="100%"
                  center
                  @click="del(itemIndex)"
                >
                  {{ $t("counterparties.deleteAction") }}
                </AuButton>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="dropdownView[itemIndex]">
          <td :colspan="columnsCount">
            <slot
              :item="item"
              :index="itemIndex"
            />
          </td>
        </tr>
      </template>
      <template v-if="!items.length">
        <tr>
          <td :colspan="columnsCount">
            <div
              class="no_data"
            >
              {{ $t('briefcase.ordersNoData') }}
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import _ from "lodash";
import ProgressLoader from "@/loaders/progress-bar";

export default {
  components: { ProgressLoader },

  props: {
    columns: {
      type: Array,
      default: () => []
    },
    actionColumn: {
      type: Object,
      default: () => {}
    },
    actionType: {
      type: String,
      default: "Simple"
    },
    items: {
      type: Array,
      default: () => []
    },
    checkboxes: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Array,
      default: () => []
    },
    decimalStaticCols: {
      type: Array,
      default: () => []
    },
    iconLinks: {
      type: Array,
      default: () => []
    },
    isIconLinks: {
      type: Boolean,
      default: false
    },
    colorOrder: {
      type: Boolean,
      default: false
    },
    goodsLink: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      contextIsOpen: false,
      openedContextMenuIndex: -1,
      dropdownView: []
    };
  },

  computed: {
    columnWidth() {
      return Number.isInteger(this.columnsCount) ? 100 / this.columnsCount : (100 / this.columnsCount).toFixed(1).replace(/\./g, "_");
    },

    columnsCount() {
      let actionColCount = _.isEmpty(this.actionColumn) ? 0 : 1;
      return this.columns.length + actionColCount;
    },

    actionStyle() {
      if (this.actionColumn) {
        return {
          "--table-action-align": this.actionColumn.align || "end"
        };
      }

      return {
        "--table-action-align": "end"
      };
    }
  },

  mounted() {
    while (this.dropdownView.length < this.columns) {
      this.dropdownView.push(false);
    }
  },

  methods: {
    getSlot(propIndex) {
      return _.get(this.columns, `[${propIndex}].slot`);
    },

    showSlot(itemIndex) {
      this.dropdownView[itemIndex] = !this.dropdownView[itemIndex];
      this.$emit("drop", { index: itemIndex, visible: this.dropdownView[itemIndex] });
    },

    getTableStyle(index) {
      let column = this.columns[index];

      if (column) {
        return {
          "--table-align": column.align || "center"
        };
      }

      return {
        "--table-align": "end"
      };
    },

    getTableHeaderStyle(column) {
      return {
        "--table-header-align": column.align || "center"
      };
    },

    parsedValue(prop) {
      return prop.match(/\d+/);
    },

    parsedAddition(prop) {
      return prop.replace(this.parsedValue(prop), "");
    },

    isDecimalStaticCol(index) {
      return _.some(this.decimalStaticCols, ["colIndex", index]);
    },

    colIndent(index, itemIndex = 0) {
      let ret = _.find(this.decimalStaticCols, ["colIndex", index]).indent;
      if (typeof ret == "number") {
        return ret;
      }
      return ret[itemIndex];
    },

    goToPage(itemIndex) {
      this.$emit("goToPage", itemIndex);
    },
    send(itemIndex) {
      this.$emit("send", itemIndex);
    },
    topUp(itemIndex) {
      this.$emit("topUp", itemIndex);
    },
    edit(itemIndex) {
      this.$emit("edit", itemIndex);
    },
    del(itemIndex) {
      this.$emit("del", itemIndex);
    },
    verify(itemIndex) {
      this.$emit("verify", itemIndex);
    },
    closeMenu() {
      for (let i = 0; i < this.items.length; i++) {
        this.$refs[`contextMenu_${i}`][0].classList = "closedContextMenu";
      }
    },
    toLink(itemIndex) {
      this.$router.push({ name: "token info", params: { id: this.iconLinks[itemIndex] } });
    },
    toLinkGoods(itemIndex) {
      this.$router.push({ name: "marketplace card", params: { id: this.items[itemIndex].id } });
    },
    openContextMenu(index) {
      for (let i = 0; i < this.items.length; i++) {
        this.$refs[`contextMenu_${i}`][0].classList = "closedContextMenu";
      }

      if (this.contextIsOpen) {
        for (let i = 0; i < this.items.length; i++) {
          this.$refs[`contextMenu_${i}`][0].classList = "closedContextMenu";
        }
        if (this.openedContextMenuIndex != index) {
          this.openedContextMenuIndex = index;
          this.$refs[`contextMenu_${index}`][0].classList = "openedContextMenu";
        }
        else {
          this.openedContextMenuIndex = -1;
          for (let i = 0; i < this.items.length; i++) {
            this.$refs[`contextMenu_${i}`][0].classList = "closedContextMenu";
          }
        }
      }
      else {
        this.contextIsOpen = true;
        this.openedContextMenuIndex = index;
        this.$refs[`contextMenu_${index}`][0].classList = "openedContextMenu";
      }
    }
  }
};
</script>

<style scoped lang="scss">
  table.table {
      & > :not(caption) > * > * {
      padding: 15px 0px 18px 0px;
      background-color: transparent;
      border-width: 0;
      border-color: var(--color-70);
    }
    &.column-width {
      &-20 {
        td {
          // width: 20%;
          align-items: end;
        }
      }
      &-25 {
        td {
          width: 25%;
          align-items: end;
        }
      }
      &-33_3 {
        td {
          width: 33.3%;
          align-items: end;
        }
      }
    }
    th {
      border-top-width: 1px;
      border-bottom-width: 1px;
      border-style: solid;
      font-weight: 450;
      font-size: 12px;
      color: var(--color-20);
      padding-left: 5px;
      padding-right: 5px;

      text-align: var(--table-header-align);

      &[action-column] {
        text-align: var(--table-action-align);
      }
    }
    tr {
      &:last-child {
        td {
          border-bottom-width: 0px;
        }
      }
    }
    td {
      border-bottom-width: 0.8px;
      border-style: dashed;
      font-weight: 450;
      font-size: 14px;
      color: var(--text-color);
      padding: 24px 5px;

      text-align: var(--table-align);
      vertical-align: middle;

      &[action-column] {
        text-align: var(--table-action-align);
      }

      &.checkbox {
        div {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }
    .icon-with-bg {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      border-radius: 8px;
      padding: 4px;
    }
  }

  .action-wrapper {
    position: relative;
    text-align: end;
  }

  .openedContextMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 128px;
    right: calc(50% - 128px / 2);
    background-color: var(--menu-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    z-index: 10;
    top: 30px;
  }

  .closedContextMenu {
    display: none;
  }
  .icon-link {
    &:hover {
      cursor: pointer;
    }
  }
  .order-sale{
    color: red;
  }
  .order-buy{
    color: green;
  }
  .no_data {
    display: flex;
    justify-content: center;
    color: var(--color-50);
}

</style>