<template>
  <div class="breadcrumb-wrap">
    <nav class="breadcrumb">
      <ol class="breadcrumb">
        <div
          v-for="item of items"
          :key="item.key"
          class="breadcrumb"
        >
          <li class="breadcrumb-item">
            <RouterLink :to="item.path">
              {{ $t(item.name) }}
            </RouterLink>
          </li>
          <div class="breadcrumb_icon">
            <AuIcon icon="breadcrumb_arrow" />
          </div>
        </div>

        <li
          class="breadcrumb-item active"
        >
          <a v-if="currentPageTitle">
            {{ currentPageTitle }}
          </a>
          <a v-else>
            {{ $t($route.meta.title) }}
          </a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    pages: {
      type: Array,
      default: () => []
    },
    parentName: {
      type: String,
      default: "router.wallet"
    },
    parentPath: {
      type: String,
      default: "/"
    },
    currentPageTitle: {
      type: String,
      default: ""
    }
  },
  computed: {
    items() {
      let ret = [{ key: 0, name: this.parentName, path: this.parentPath }];
      if (this.parentName === "router.wallet" && this.parentPath === "/") {
        const buf = _.map(this.$route.matched, (el, i) => ({ key: i, name: el.meta.title, path: el.path }));
        ret = buf?.length > 0 ? buf.slice(0, -1) : ret;
      }
      return ret;
    }
  }
};
</script>

<style lang="scss">
.breadcrumb-wrap {
  .breadcrumb {
    column-gap: 15px;
    margin-bottom: 8px;

    .breadcrumb-item {
      cursor: pointer;

      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--text-color);

      a {
        text-decoration: none;
        font-size: 12px;
        font-weight: 450;
        color: var(--color-50);
        &:hover {
          color: var(--color-50);
        }
      }

      &.active{
        a:not([href]) {
          text-decoration: none;
          color: var(--text-color);
          font-size: 12px;
          font-weight: 450;
          &:hover {
            color: var(--text-color);
          }
        }
      }
    }

    .breadcrumb_icon {
      width: 12px;
      height: 12px;
    }
  }
}


</style>