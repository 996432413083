<template>
  <AuModal
    class="capital-modal"
    :model-value="showDialog"
    width="400px"
    height="400px"
    :border-radius="15"
    padding="15px 20px 26px 20px"
    @update:modelValue="$emit('update:modelValue', false)"
  >
    <template #header>
      <span
        v-if="step < 5 && owner"
        class="gray-color"
      >{{ step }} from 4</span>
    </template>

    <template #body>
      <div
        v-if="!owner"
        class="main-error"
      >
        {{ $t("auCapitals.capitalModal.error", {token: defaultTokenEnv}) }}
      </div>

      <template v-else-if="step == 1">
        <div class="title clamp-text">
          {{ $t("auCapitals.capitalModal.1step.title") }} {{ project.name }}
        </div>

        <div class="subtitle">
          {{ $t("auCapitals.capitalModal.1step.subtitle") }}
        </div>

        <div class="inputs">
          <div class="line">
            <AuSelect
              :value="token"
              :options="tokensOptions"
              :placeholder="$t('auCapitals.capitalModal.1step.payWith')"
              :select-label="$t('auCapitals.capitalModal.1step.payWith')"
              @input="value => token = value"
            />

            <div class="info-block">
              <span>{{ $t("auCapitals.capitalModal.1step.yourBalance") }}</span>
              <span><AuDecimalStatic
                :value="owner.amountFree"
                :decimal="owner.auToken.decimals"
                :indent="owner.auToken.decimals"
                :show-addition="false"
              /><RouterLink
                class="link"
                to="/briefcase/buy-form"
              >{{ $t("auCapitals.capitalModal.1step.deposit") }}</RouterLink></span>
            </div>
          </div>

          <div class="line">
            <div class="input">
              <AuInput
                id="money-input"
                :model-value="moneyAmount"
                :placeholder="$t('auCapitals.capitalModal.1step.amount')"
                :label="$t('auCapitals.capitalModal.1step.amount') + ' ' + tokensOptions[0]?.display"
                type="Mask"
                :mask="Number"
                @update:modelValue="setMoneyAmount"
                @blur="checkMoneyAmount"
              />

              <AuButton
                bordered
                center
                :border-radius="12"
                @click="getMax"
              >
                {{ $t("auCapitals.capitalModal.1step.maxButton") }}
              </AuButton>
            </div>

            <div class="info-block">
              <span>{{ $t("auCapitals.capitalModal.1step.amountOfTokens") }}</span>
              <span><AuDecimalStatic
                :value="tokenCount"
                :decimal="0"
                :show-addition="false"
                smart-indent
              /></span>
            </div>
          </div>
        </div>

        <div class="mt-2 d-flex justify-content-between gray-color">
          <span class="d-flex flex-column">
            <span>
              {{ $t("auCapitals.capitalModal.1step.min") }} <AuDecimalStatic
                :value="min"
                :decimal="0"
                :addition="project.mnemonicEng"
                smart-indent
              />
            </span>
            <span>
              {{ $t("auCapitals.capitalModal.1step.max") }} <AuDecimalStatic
                :value="max"
                :decimal="0"
                :addition="project.mnemonicEng"
                smart-indent
              />
            </span>
          </span>
          <span class="text-end">{{ $t("auCapitals.capitalModal.1step.instantly") }}</span>
        </div>
      </template>

      <template v-else-if="step == 2">
        <div class="title">
          {{ $t("auCapitals.capitalModal.2step.title") }}
        </div>

        <div class="subtitle">
          {{ $t("auCapitals.capitalModal.2step.subtitle") }}
        </div>

        <div class="terms">
          <Terms />
        </div>

        <div class="mt-2 d-flex gap-2">
          <div>
            <input
              id="termsCheckbox"
              v-model="termsAgree"
              type="checkbox"
              class="checkbox"
            >
          </div>

          <label
            class="checkbox-label"
            for="termsCheckbox"
          >
            {{ $t("auCapitals.capitalModal.2step.termsAgree") }}
          </label>
        </div>

        <div class="mt-2 d-flex gap-2">
          <div>
            <input
              id="memorandumCheckbox"
              v-model="memorandumAgree"
              type="checkbox"
              class="checkbox"
            >
          </div>

          <label
            class="checkbox-label"
            for="memorandumCheckbox"
          >
            {{ $t("auCapitals.capitalModal.2step.memorandumAgree") }} <label
              class="link"
              :disabled="memorandumLoading"
              @click.prevent="tryDownloadMemorandum"
            >
              {{ $t("auCapitals.capitalModal.2step.memorandumAgreeLink") }}
            </label>.
          </label>
        </div>
      </template>

      <template v-else-if="step == 3">
        <div class="title">
          {{ $t("auCapitals.capitalModal.3step.title") }}
        </div>

        <div class="subtitle">
          {{ $t("auCapitals.capitalModal.3step.subtitle") }}
        </div>

        <div class="verify">
          <div class="verify-line">
            <span class="label me-2">{{ $t("auCapitals.capitalModal.3step.project") }}</span>
            <span class="value clamp-text">{{ project.name }}</span>
          </div>

          <div class="verify-line">
            <span class="label">{{ $t("auCapitals.capitalModal.3step.payWith") }}</span>
            <span class="value">{{ selectedToken }}</span>
          </div>

          <div class="verify-line">
            <span class="label">{{ $t("auCapitals.capitalModal.3step.amount") }}</span>
            <span class="value">{{ moneyAmount.toString().replace(",", ".") }}</span>
          </div>

          <div class="verify-line">
            <span class="label">{{ $t("auCapitals.capitalModal.3step.fees") }}</span>
            <span class="value">-</span>
          </div>
        </div>
      </template>

      <template v-else-if="step == 4">
        <div class="title">
          {{ $t("auCapitals.capitalModal.4step.title") }}
        </div>

        <div class="subtitle">
          {{ $t("auCapitals.capitalModal.4step.subtitle") }}
        </div>

        <div class="d-flex align-items-center gap-3">
          <ProgressBar
            :procent="procent"
            green
          />

          <span> {{ procent }}%</span>
        </div>

        <div class="progresses">
          <div
            :checked="transactionsChecks.terms"
            class="progress-line"
          >
            <img src="/img/project-card/check.svg">
            <p>{{ $t("auCapitals.capitalModal.4step.terms") }}<span>...</span><span>OK</span></p>
          </div>

          <div
            :checked="transactionsChecks.balance"
            class="progress-line"
          >
            <img src="/img/project-card/check.svg">
            <p>{{ $t("auCapitals.capitalModal.4step.balance") }}<span>...</span><span>OK</span></p>
          </div>

          <div
            :checked="transactionsChecks.smartcontract"
            class="progress-line"
          >
            <img src="/img/project-card/check.svg">
            <p>{{ $t("auCapitals.capitalModal.4step.smartcontract") }}<span>...</span><span>OK</span></p>
          </div>

          <div
            :checked="transactionsChecks.received"
            class="progress-line"
          >
            <img src="/img/project-card/check.svg">
            <p>{{ selectedToken }} {{ $t("auCapitals.capitalModal.4step.received") }}<span>...</span><span>OK</span></p>
          </div>

          <div
            :checked="transactionsChecks.sent"
            class="progress-line"
          >
            <img src="/img/project-card/check.svg">
            <p>{{ $t("auCapitals.capitalModal.4step.sent") }}<span>...</span><span>OK</span></p>
          </div>

          <span
            v-if="error"
            class="text-error"
          >{{ error }}</span>
        </div>
      </template>

      <template v-else-if="step == 5">
        <div class="final-titles">
          <div class="title">
            {{ $t("auCapitals.capitalModal.5step.title") }}
          </div>

          <div class="subtitle clamp-text">
            {{ $t("auCapitals.capitalModal.5step.subtitle") }} {{ clampedName }}
          </div>
        </div>

        <div class="image d-flex justify-content-center">
          <img src="/img/project-card/trophy.svg">
        </div>

        <div class="result">
          <span>
            {{ tokenCount }} {{ clampedName }} {{ $t("auCapitals.capitalModal.5step.credited") }}
          </span>

          <a
            class="link"
            target="_blank"
            :href="link"
          >
            {{ $t("auCapitals.capitalModal.5step.check") }}
          </a>
        </div>
      </template>
    </template>

    <template #footer>
      <div
        v-if="!owner"
        class="final-buttons"
      >
        <AuButton
          bordered
          center
          active-bg-color="#2ABA7C"
          @click="$router.push({name: 'buy form'})"
        >
          {{ $t("auCapitals.capitalModal.topUp") + " " + project.mnemonicEng }}
        </AuButton>
      </div>

      <div
        v-else-if="error"
        class="final-buttons"
      >
        <AuButton
          class="button"
          bordered
          center
          active-bg-color="#ba2a2a"
          @click="$emit('update:modelValue', false)"
        >
          {{ $t("auCapitals.capitalModal.close") }}
        </AuButton>
      </div>

      <div
        v-else-if="step < 5"
        :step="step"
        class="main-buttons"
      >
        <AuButton
          v-if="step > 1 && step < 4"
          class="back-button"
          bordered
          center
          @click="step--"
        >
          {{ $t("auCapitals.capitalModal.back") }}
        </AuButton>

        <span
          v-if="isAmountIncorrect && step <= 3"
          class="warning"
        >
          {{ $t("auCapitals.capitalModal.warning") }}
          <AuDecimalStatic
            :value="amountToFix"
            :decimal="0"
            :indent="project.mnemonicDecimals"
            :show-addition="false"
            @click="fixAmount"
          />
          {{ tokensOptions[0]?.display }}
        </span>

        <AuButton
          class="button"
          bordered
          center
          active-bg-color="#2ABA7C"
          :disabled="isNextBolcked"
          @click="step++"
        >
          {{ $t("auCapitals.capitalModal.next") }}
        </AuButton>
      </div>

      <div
        v-else
        class="final-buttons"
      >
        <AuButton
          class="button"
          bordered
          center
          active-bg-color="#2ABA7C"
          @click="$emit('update:modelValue', false)"
        >
          {{ $t("auCapitals.capitalModal.anotherInvestment") }}
        </AuButton>
      </div>
    </template>
  </AuModal>
</template>

<script>
import BigNumber from "bignumber.js";
import moment from "moment";

import ProgressBar from "@/components/progressBar/ProgressBar";
import Terms from "./Terms";

import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export const STATES = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  FAIL: 3
};

export default {
  components: {
    ProgressBar,
    Terms
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      required: true
    }
  },

  emits: ["update:modelValue", "finish"],

  data() {
    return {
      moneyAmount: "",
      step: 1,
      token: "",
      termsAgree: false,
      memorandumAgree: false,
      error: "",
      STATES: STATES,
      address: "",
      hash: "",
      memorandumLoading: false,
      transactionsChecks: {
        terms: STATES.NOT_STARTED,
        balance: STATES.NOT_STARTED,
        smartcontract: STATES.NOT_STARTED,
        received: STATES.NOT_STARTED,
        sent: STATES.NOT_STARTED
      }
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      tokens: "tokens",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      defaultTokenId: "defaultToken/defaultTokenId",
      defaultTokenEnv: "defaultToken/defaultTokenEnv"
    }),

    currentToken() {
      return this.tokens.find(item => item.id == this.project.tokenQuoteId);
    },

    link() {
      let link = `${process.env.VUE_APP_SCAN_BASE_URL}`;
      if (this.hash) {
        link += "tx/" + this.hash;
      }
      return link;
    },

    priceBase() {
      let value = BigNumber(this.project.priceBase);
      value = value.dividedBy(BigNumber(10).pow(this.project.mnemonicDecimals));
      return value.toNumber();
    },

    min() {
      return this.minQuote ? _.max([this.priceBase, this.minQuote]) : this.priceBase;
    },

    max() {
      let amountFree = BigNumber(this.owner.amountFree);
      amountFree = amountFree.dividedBy(BigNumber(10).pow(this.project.mnemonicDecimals));
      amountFree = amountFree.dividedBy(this.priceBase).integerValue(BigNumber.ROUND_FLOOR);
      amountFree = amountFree.multipliedBy(this.priceBase).toNumber();
      let minPrice = BigNumber(this.project.maxCap - this.project.currentCap);
      minPrice = minPrice.dividedBy(BigNumber(10).pow(this.project.mnemonicDecimals)).toNumber();
      return this.maxQuote ? _.min([minPrice, this.maxQuote, amountFree]) : _.min([minPrice, amountFree]);
    },

    procent() {
      let procent = 0;

      for (const property in this.transactionsChecks) {
        if (this.transactionsChecks[property] == STATES.DONE) {
          procent += 20;
        }
      }
      return procent;
    },

    maxQuote() {
      let value = BigNumber(this.project.priceBase);
      value = value.dividedBy(BigNumber(10).pow(this.project.mnemonicDecimals));
      value = value.multipliedBy(this.project.quote);
      return value.toNumber();
    },

    minQuote() {
      let value = BigNumber(this.project.priceBase);
      value = value.dividedBy(BigNumber(10).pow(this.project.mnemonicDecimals));
      value = value.multipliedBy(this.project.minCount);
      return value.toNumber();
    },

    isEnough() {
      let count = this.moneyAmount.toString().replace(",", ".");
      count = BigNumber(count).multipliedBy(this.priceBase);

      if (this.owner === undefined) {
        return false;
      }
      let value = BigNumber(this.owner.amountFree);
      value = value.dividedBy(BigNumber(10).pow(this.project.mnemonicDecimals));
      return count.isLessThanOrEqualTo(value);
    },

    owner() {
      return this.auOwners.find(item => item.auToken.id == this.project.tokenQuoteId);
    },

    tokenCount() {
      let tokenCount = BigNumber(this.moneyAmount.toString().replace(",", "."));
      tokenCount = tokenCount.dividedBy(this.priceBase);
      const isAmountIncorrect = !tokenCount.integerValue(BigNumber.ROUND_DOWN).isEqualTo(tokenCount);
      const maxDecimals = _.max([this.project.decimals, 2]);
      return tokenCount.toNumber().toFixed(isAmountIncorrect ? maxDecimals : this.project.decimals);
    },

    tokensOptions() {
      return _.map([...this.tokensFiat, ...this.tokensGold], (token) => ({
        display: token.mnemonicEng,
        value: token.id
      }));
    },

    isNextBolcked() {
      if (this.isAmountIncorrect) {
        return true;
      }

      if (this.moneyAmount < this.min) {
        return true;
      }

      if (this.step == 1 && this.moneyAmount == 0) {
        return true;
      }

      if (this.step == 2 && !(this.termsAgree && this.memorandumAgree)) {
        return true;
      }

      if (this.step == 4 && this.transactionsChecks.received != STATES.DONE) {
        return true;
      }

      return false;
    },

    clampedName() {
      if (this.project.name.length >= 15) {
        return this.project.name.slice(0, 15) + "...";
      }

      return this.project.name;
    },

    selectedToken() {
      return _.chain(this.tokensOptions).find({ value: this.token }).get("display");
    },

    isAmountIncorrect() {
      return Math.trunc(this.tokenCount) !== Number(this.tokenCount);
    },

    amountToFix() {
      let amount = BigNumber(this.tokenCount).integerValue(BigNumber.ROUND_DOWN);
      amount = amount.multipliedBy(this.priceBase);
      return amount.isGreaterThan(this.min) ? amount.toString(10) : this.min;
    }
  },

  watch: {
    async step(value) {
      if (value == 4) {
        this.transactionsChecks.terms = STATES.IN_PROGRESS;
        await new Promise(r => setTimeout(r, 500));
        this.transactionsChecks.terms = STATES.DONE;

        this.transactionsChecks.balance = STATES.IN_PROGRESS;
        try {
          await this.getAuOwners();

          if (!this.isEnough) {
            this.error = this.$t("auCapitals.capitalModal.errors.balance");
            this.transactionsChecks.balance = STATES.FAIL;
            return;
          }
        }
        catch (error) {
          this.error = this.$t("auCapitals.capitalModal.errors.balanceError");
          this.transactionsChecks.balance = STATES.FAIL;
          return;
        }
        this.transactionsChecks.balance = STATES.DONE;

        this.transactionsChecks.smartcontract = STATES.IN_PROGRESS;
        await new Promise(r => setTimeout(r, 500));
        this.transactionsChecks.smartcontract = STATES.DONE;

        this.transactionsChecks.received = STATES.IN_PROGRESS;
        let invest = null;
        try {
          invest = await this.investCapital();
          if (!invest.data) {
            this.error = this.$t("auCapitals.capitalModal.errors.transaction");
            this.transactionsChecks.received = STATES.FAIL;
            return;
          }
          else if (invest.status == 267) {
            console.log(invest);
            if (invest.data.message) {
              this.error = invest.data.message;
            }
            else {
              this.error = this.$t("auCapitals.capitalModal.errors.purchase");
            }
            this.transactionsChecks.received = STATES.FAIL;
            return;
          }
        }
        catch (error) {
          this.error = this.$t("auCapitals.capitalModal.errors.transaction");
          this.transactionsChecks.received = STATES.FAIL;
          return;
        }
        this.transactionsChecks.received = STATES.DONE;

        this.transactionsChecks.sent = STATES.IN_PROGRESS;
        for (let i = 1;; i += 1) {
          try {
            const data = await this.getTokenEventById(invest.data?.id);
            if (data.isTaskDone) {
              this.address = data.address;
              const transactions = await this.getTransactionsByAddress(this.owner.auToken.createdAddress, data.address);
              this.hash = _.first(transactions)?.hash;

              if (data.value == "0") {
                this.error = this.$t("auCapitals.capitalModal.errors.zeroValue");
                this.transactionsChecks.sent = STATES.FAIL;
                return;
              }

              this.moneyAmount = BigNumber(data.value).multipliedBy(this.priceBase).toNumber();
              break;
            }
          }
          catch (error) {
            this.error = this.$t("auCapitals.capitalModal.errors.checking");
            this.transactionsChecks.sent = STATES.FAIL;
            return;
          }

          await new Promise(r => setTimeout(r, 5000));
        }
        this.$emit("finish");

        await this.getBalances(this.defaultTokenId);
        await this.getAuOwners();

        try {
          if (window.carrotquest && process.env.VUE_APP_CARROT_ENABLED === "true") {
            window.carrotquest.track("invest", {
              "address": this.address,
              "amount": Math.trunc(this.tokenCount).toString(10)
            });
          }
        }
        catch (error) {
          console.error(error);
        }

        this.transactionsChecks.sent = STATES.DONE;
      }
    }
  },

  async created() {
    await this.getBalances(this.defaultTokenId);
    await this.getAuOwners();
  },

  async mounted() {
    this.token = this.defaultTokenId;
    this.checkMoneyAmount();
  },

  methods: {
    ...mapActions({
      sendTokenEvents: "sendTokenEvents",
      getTokenEventById: "getTokenEventById",
      getAuOwners: "getAuOwners",
      getBalances: "getBalances",
      getTransactionsByAddress: "getTransactionsByAddress",
      downloadMemorandum: "capitals/downloadMemorandum"
    }),

    setMoneyAmount(value) {
      this.moneyAmount = value;
      this.checkMoneyAmount();
    },

    async tryDownloadMemorandum() {
      if (this.memorandumLoading == false) {
        try {
          this.memorandumLoading = true;
          await this.downloadMemorandum({ name: this.project.name, id: this.project.id });
        }
        catch (error) {
          console.error(error);
        }
        finally {
          this.memorandumLoading = false;
        }
      }
    },

    checkMoneyAmount() {
      const inputWrap = document.querySelector("#money-input");
      const input = inputWrap.querySelector(".input");

      if (Number(this.moneyAmount.replace(",", ".")) > Number(this.max)) {
        this.moneyAmount = this.max.toString().replace(".", ",");
      }

      if (Number(this.moneyAmount.replace(",", ".")) < Number(this.min)) {
        this.moneyAmount = this.min.toString().replace(".", ",");
      }

      input.value = this.moneyAmount;
    },

    getMax() {
      let amountFree = BigNumber(this.owner.amountFree);
      amountFree = amountFree.dividedBy(BigNumber(10).pow(this.project.mnemonicDecimals));

      if (amountFree.isGreaterThanOrEqualTo(this.max)) {
        this.moneyAmount = this.max.toString().replace(".", ",");
      }
      else {
        this.moneyAmount = amountFree.toString(10).replace(".", ",");
      }

      this.fixAmount();
    },

    fixAmount() {
      this.moneyAmount = BigNumber(Math.trunc(this.tokenCount)).multipliedBy(this.priceBase).toString().replace(".", ",");
    },

    async investCapital() {
      const params = {
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: moment(),
        type: "token_capital_buy",
        description: "buy",
        value: Math.trunc(this.tokenCount).toString(10),
        address: "",
        isTask: true,
        auCapital: `api/au_capitals/${this.project.id}`
      };

      const invest = await this.sendTokenEvents(params);
      return invest;
    }
  }
};
</script>

<style lang="scss">
.capital-modal {
  button.btn.button {
    div.btn__text {
      color: white;
    }
  }

  button.btn.back-button {
    border-color: var(--btn-dg-color-primary) !important;

    div.btn__text {
      color: var(--btn-dg-color-primary);
    }
  }

  .text-error{
    color: red;
  }

  .main-error {
    height: 300px;
    display: flex;
    align-items: center;
    font-size: 18px;
    text-align: center;
  }

  .image {
    height: 116px;
  }

  .final-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .main-buttons {
    display: flex;
    justify-content: space-between;

    &[step="1"],&[step="4"]{
      justify-content: end;
    }
  }

  .progresses {
    display: flex;
    flex-direction: column;

    gap: 8px;

    margin-top: 10px;

    .progress-line {
      display: flex;
      position: relative;

      &[checked="1"] {
        span {
          &:first-child {
            display: block;

            clip-path: inset(0 10px 0 0);
            animation: l 1s steps(4) infinite;

            @keyframes l {
              to {
                clip-path: inset(0 -3px 0 0)
              }
            }
          }
        }
      }

      &[checked="3"] {
        color: red
      }



      &[checked="2"] {
        img {
          display: block;
        }

        span {
          display: block;
        }
      }

      span {
        display: none;
      }

      img {
        display: none;
        position: absolute;
        top: 6px;
      }

      p {
        margin-left: 21px;
        margin-bottom: 0;

        display: flex;
        gap: 5px;
      }
    }
  }

  .final-titles {
    margin-top: 25px;

    text-align: center;
  }

  .warning {
    color: orange;
    margin-right: 25px;

    &:not(:first-child) {
      margin-left: 10px;
      margin-right: 10px;
    }

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .indent_progress {
    width: 100%;
  }

  .gray-color {
    color: var(--color-50);
  }

  .title {
    font-size: 24px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 13px;

    font-family: Suisse Intl Semi Bold;
  }

  .clamp-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .subtitle {
    font-size: 15px;
    line-height: 15px;
    margin-bottom: 24px;

    white-space: nowrap;
  }

  .result {
    display: flex;
    flex-direction: column;
    text-align: center;

    gap: 10px;

    >span {
      margin: 0 60px;
      font-family: Suisse Intl Semi Bold;
    }
  }

  .terms {
    margin-top: -13px;
    margin-bottom: 15px;
    padding: 5px 12px;

    height: 95px;

    overflow-y: scroll;
    overflow-x: hidden;

    font-size: 12px;
    line-height: 24px;

    background: var(--sep-line-color);
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 14px;
    height: 14px;
    vertical-align: middle;
    border: 1px solid var(--text-color);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;

    margin-top: 4px;

    &:checked {
      &::before {
        content: "✓";
        color: var(--text-color);
      }
    }

    &-label {
      cursor: pointer;
    }
  }

  .verify {
    display: flex;
    flex-direction: column;

    gap: 12px;

    .verify-line {
      display: flex;
      justify-content: space-between;

      padding-bottom: 10px;
      border-bottom: 1px var(--color-50) dashed;

      .label  {
        color: var(--color-50);
      }

      .value {
        font-family: Suisse Intl Semi Bold;
        text-align: end;
      }

      &:last-child {
        border: none;
      }
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .line {
      display: flex;
      justify-content: space-between;
      align-items: end;

      .input-label {
        margin-bottom: 4px;
      }

      .au-select-label {
        margin-bottom: 4px;
      }

      .input {
        display: flex;
        justify-content: space-between;
        align-items: end;
        gap: 10px;
      }

      >div {
        &:first-child {
          width: 60%;
        }

        &:last-child {
          text-align: end;
        }
      }

      .info-block {
        display: flex;
        flex-direction: column;
        gap: 7.5px;
        line-height: 14px;
      }
    }
  }

  .link {
    color: #279063;
    text-decoration: underline;
    cursor: pointer;

    &:not([disabled=true]) {
      &:active {
        color: #279063A3;
        &:hover {
          color: #279063A3;
        }
      }

      &:hover {
        color: #279063d1;
      }
    }

    &[disabled=true] {
      color: #124630;
      cursor: auto;
    }
  }
}
</style>