import axios from "axios";

export default class axiosClient {
  async getAuOwners() {
    const params = {
      pagination: false
    };
    const resp = await axios.get("api/au_owners", { params: params });
    return resp.data;
  }

  async getOwnerPage(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };

    const resp = await axios.get("api/au_owners", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getCompanies() {
    const resp = await axios.get("api/companies");
    return resp.data;
  }

  async getHydraCompanies(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const resp = await axios.get("api/companies", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getBankRequisites() {
    const resp = await axios.get("api/bank_requisites");
    return resp.data;
  }

  async postBankRequisites(body) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    await axios.post("api/bank_requisites", body, { headers });
  }

  async deleteBankRequisites(id) {
    await axios.delete(`/api/bank_requisites/${id}`);
  }

  async updateBankRequisites(id, newValue) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    await axios.put(`/api/bank_requisites/${id}`, newValue, headers);
  }

  async getCurrencyRate() {
    const resp = await axios.get("api/currency_rates");
    return resp.data;
  }

  async getTransactions() {
    const resp = await axios.get("api/transactions?methodName=transfer");
    return resp.data;
  }

  async getNftOwners() {
    const resp = await axios.get("api/nft_owners");
    return resp.data;
  }

  async getTokenHistory({ defaultTokenId, tokenId }) {
    const params = {
      page: 1,
      itemsPerPage: 7,
      isD: true,
      isW: false,
      isM: false,
      tokenId: tokenId,
      tokenQuoteId: defaultTokenId
    };

    const resp = await axios.get("api/history_courses", { params });
    return resp.data;
  }

  async getNftOwnersPage(page = 1, userId) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: page,
      userId: userId
    };
    const resp = await axios.get("api/nft_owners", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getTransactionsPage({ pageId = 1, sort = "desc", auTokenId, adr, dateFrom, dateTo, itemsPerPage = 30, addressFrom, addressTo, methodName }) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: pageId,
      itemsPerPage,
      pagination: true,
      sortById: sort,
      auTokenId: auTokenId,
      address: adr,
      methodName: methodName
    };

    if (addressFrom) {
      params.addressFrom = addressFrom;
    }
    if (addressTo) {
      params.addressTo = addressTo;
    }

    if (dateFrom) {
      params.dateFrom = dateFrom;
    }
    if (dateTo) {
      params.dateTo = dateTo;
    }

    try {
      const resp = await axios.get("api/transactions", { headers, params });
      const data = resp.data["hydra:member"];
      const totalItems = resp.data["hydra:totalItems"];
      return { data, totalItems };
    }
    catch (error) {
      console.log(error);
    }
  }

  async getTransactionsHistory(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    try {
      const resp = await axios.get("api/transactions", { headers, params });
      const data = resp.data["hydra:member"];
      const totalItems = resp.data["hydra:totalItems"];
      return { data, totalItems };
    }
    catch (error) {
      console.log(error);
    }
  }

  async getTransactionsPageOwnerFrom(pageId, ownerId) {
    const params = {
      page: pageId,
      itemsPerPage: 30,
      auOwnerFromId: ownerId
    };
    const resp = await axios.get("api/transactions", { params });
    return resp.data;
  }

  async getTransactionsPageOwnerTo(pageId, ownerId) {
    const params = {
      page: pageId,
      itemsPerPage: 30,
      auOwnerToId: ownerId
    };
    const resp = await axios.get("api/transactions", { params });
    return resp.data;
  }

  async getTransactionsByAddress(addressFrom, addressTo) {
    const params = {
      from: addressFrom,
      to: addressTo,
      itemsPerPage: 1
    };
    const resp = await axios.get("api/transactions", { params });
    return resp.data;
  }

  async getSectionMarket(locale = "en") {
    const params = {
      locale: locale
    };
    const resp = await axios.get("api/section_markets", { params });
    return resp.data;
  }

  async getSectionCapital(locale = "en") {
    const params = {
      locale: locale
    };
    const resp = await axios.get("api/section_capitals", { params });
    return resp.data;
  }

  async getCountry(locale = "en") {
    const resp = await import(`@/localization/countries/${locale}`);
    return resp;
  }

  async getCounterparties() {
    const resp = await axios.get("api/user_contacts");
    return resp.data;
  }

  async getCounterparty() {
    await axios.get("api/user_contacts");
  }

  async postCounterparty(body) {
    await axios.post("api/user_contacts", body);
  }

  async postCapitalMp(body) {
    const resp = await axios.post("api/au_capitals/post_mp", body);
    return resp.data;
  }

  async patchCurrentUser({ userId, body }) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };
    await axios.patch(`/api/users/${userId}`, body, { headers });
  }

  async getAuTokens() {
    const params = {
      pagination: false
    };
    const resp = await axios.get("api/au_tokens", { params });
    return resp.data;
  }

  async getUserFromNftTransactions(userId) {
    const params = {
      type: "transfer",
      userFrom: userId
    };

    const resp = await axios.get("api/nft_transactions", { params });
    return resp.data;
  }

  async getUserToNftTransactions(userId) {
    const params = {
      type: "transfer",
      userTo: userId
    };

    const resp = await axios.get("api/nft_transactions", { params });
    return resp.data;
  }

  async getFollowings() {
    const resp = await axios.get("api/followings");
    return resp.data;
  }

  async setFollowing(id) {
    const resp = await axios.post("api/followings/start", {
      auToken: `/api/au_tokens/${id}`
    });
    return resp.data;
  }

  async unsetFollowing(id) {
    const resp = await axios.post("api/followings/stop", {
      auToken: `/api/au_tokens/${id}`
    });
    return resp.data;
  }

  async setRequestToIssue(requestToIssue) {
    const resp = await axios.post("/api/request_to_issues", requestToIssue);
    return resp.data;
  }

  async putRequestToIssue({ requestToIssue, id }) {
    const resp = await axios.put("/api/request_to_issues/" + id, requestToIssue);
    return resp.data;
  }

  async getBalances(tokenQuote) {
    const resp = await axios.get("/api/au_owners/with_price/" + tokenQuote);
    return resp.data;
  }

  async sendTokenEvents(params) {
    return await axios.post("api/token_events", params);
  }

  async sendTokenEventsById(id, params) {
    return await axios.post(`api/token_events${id}`, params);
  }

  async getTokenEvents(pageId, capitalId, type) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };

    const param = {
      page: pageId,
      itemsPerPage: 30,
      auCapitalId: capitalId,
      type: type
    };
    const resp = await axios.get("api/token_events", { headers, params: param });
    return resp.data;
  }

  async getTokenEventById(id) {
    const resp = await axios.get("api/token_events/" + id);
    return resp.data;
  }

  register(body) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    return axios.post("api/users/register", body, { headers });
  }

  async defaultRequest(request) {
    const resp = await axios.get(request);
    return resp.data;
  }

  async requestForms(body) {
    await axios.post("/api/request_forms", body);
  }

  async getEthAddresses() {
    const resp = await axios.get("/api/eth_addresses");
    return resp.data;
  }

  async postEthAddresses(body) {
    return await axios.post("/api/eth_addresses", body);
  }

  async patchEthAddresses(id, body) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };

    await axios.patch(`/api/eth_addresses/${id}`, body, { headers });
  }

  async deleteEthAddresses(id) {
    await axios.delete(`/api/eth_addresses/${id}`);
  }

  async deleteCounterparty(id) {
    await axios.delete(`/api/user_contacts/${id}`);
  }

  async updateCounterparty(id, newValue) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    await axios.put(`/api/user_contacts/${id}`, newValue, headers);
  }

  async getRequestsToIssue() {
    const resp = await axios.get("api/request_to_issues");
    return resp.data;
  }

  async getHydraRequestToIssue(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const resp = await axios.get("api/request_to_issues", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async delRequestToIssue(id) {
    const resp = await axios.delete(`api/request_to_issues/${id}`);
    return resp.data;
  }

  async editRequestToIssue(id, params) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.put(
      `api/request_to_issues/${id}`,
      params,
      headers
    );
    return resp.data;
  }

  async getValidateContent(currentEth) {
    const resp = await axios.get(
      `/api/eth_addresses/get_validate_content/${currentEth}`
    );
    return resp.data;
  }

  async signAndVerify(accountAddress, sign) {
    const resp = await axios.get(
      `/api/eth_addresses/sign_and_verify/${accountAddress}`,
      {
        params: { signature: sign }
      }
    );
    return resp.data;
  }

  async emailVerify(body) {
    const headers = {
      accept: "*/*",
      "Content-Type": "application/json"
    };

    return await axios.post("api/users/email_verify", body, headers);
  }

  async addCompany(params) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.post("api/companies", params, headers);
    return resp.data;
  }

  async addCompanyFromWelcome(body) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.post("api/companies", body, { headers });
    return resp.data;
  }

  async editCompany(id, params) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.put(`api/companies/${id}`, params, headers);
    return resp.data;
  }

  async deleteCompany(id) {
    const resp = await axios.delete(`api/companies/${id}`);
    return resp.data;
  }

  async getExchangeBalances(userId, tokenId) {
    const params = {
      userId: userId,
      tokenId: tokenId,
      pagination: false
    };
    const resp = await axios.get("api/exchange_balances", { params: params });
    return resp.data;
  }

  async getHistory(dateFrom, dateTo, userId, tokenId) {
    const params = {
      dateFrom,
      dateTo,
      userId,
      tokenOfValueId: tokenId,
      pagination: false
    };

    const resp = await axios.get("api/history_briefcases", { params });
    return resp.data;
  }

  async getTrades(page, userId, pairId) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: page,
      userId: userId,
      pairId: pairId,
      itemsPerPage: 30
    };
    const resp = await axios.get("api/trades", { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getCourse(tokenId, tokenQuoteId) {
    const resp = await axios.get(
      `/api/au_tokens/${tokenId}/currency_rates/${tokenQuoteId}`
    );
    return resp.data.currency;
  }

  async getPairs() {
    const resp = await axios.get("/api/pairs");
    return resp.data;
  }

  async getUserContact(page, itemsPerPage) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const params = {
      page: page,
      itemsPerPage: itemsPerPage
    };
    const resp = await axios.get("/api/user_contacts", {
      headers: headers,
      params: params
    });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async unsubscribe(secret) {
    const resp = await axios.get(`/api/users/unsubscribe/${secret}`);
    return resp.data;
  }

  async changePassword(params) {
    try {
      const resp = await axios.post("/api/users/change_password", params);
      return resp;
    }
    catch (error) {
      return error;
    }
  }

  async getQr2FA(userId) {
    try {
      const resp = await axios.get(`api/users/${userId}/create_token`);
      return resp;
    }
    catch (error) {
      return error;
    }
  }

  async on2Fa(params) {
    try {
      const resp = await axios.post("api/users/set2fa", params);
      return resp;
    }
    catch (error) {
      return error;
    }
  }

  async off2Fa(params) {
    try {
      const resp = await axios.post("/api/users/set2fa", params);
      return resp;
    }
    catch (error) {
      return error;
    }
  }

  async getNftOwnersById(id, isInMarket = null) {
    const params = {
      nftId: id,
      pagination: false
    };
    if (isInMarket !== null) {
      params.isInMarket = isInMarket;
    }
    const resp = await axios.get("/api/nft_owners", { params: params });
    return resp.data;
  }

  async getAuOwnerByTokenId(tokenId, ownerAddress) {
    const params = {
      auTokenId: tokenId,
      ownerAddress: ownerAddress
    };
    const resp = await axios.get("api/au_owners", { params: params });
    return resp.data;
  }

  async payCreate(params) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const resp = await axios.post("/api/pays/create", params, { headers });
    return resp.data;
  }

  async getPay(id) {
    const resp = await axios.get(`/api/pays/${id}`);
    return resp.data;
  }

  async payNotify(payId) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json"
    };
    const params = {
      payId: payId
    };
    const resp = await axios.post("/api/pays/notify", params, { headers });
    return resp.data;
  }

  async restorePassword(email) {
    return await axios.get(`/api/users/restore_request/${email}`);
  }

  async restoreRequestCheck(hash) {
    return await axios.get(`/api/users/restore_request_check/${hash}`);
  }

  async getCaptcha() {
    const resp = await axios.get("/api/captcha");
    return resp.data;
  }
}
