<template>
  <div>
    <Verification />
  </div>
</template>

<script>
import Verification from "@/components/Verification";

export default {
  name: "WalletVerification",
  components: {
    Verification
  }
};
</script>

<style scoped lang="scss">

</style>