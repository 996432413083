<template>
  <ImaskInput
    :model-value="displayValue"
    class="input"
    :class="{'faded': faded}"
    :mask="Number"
    :placeholder="placeholder"
    :scale="indent"
    :normalize-zeros="true"
    :readonly="readonly"
    @update:modelValue="input"
  />
</template>

<script>
import BigNumber from "bignumber.js";
import { IMaskComponent } from "vue-imask";

// import _ from "lodash";

export default {
  name: "AuDecimalInput",

  components: {
    "ImaskInput": IMaskComponent
  },

  props: {
    value: {
      type: String,
      default: ""
    },

    decimal: {
      type: Number,
      default: 0
    },

    indent: {
      type: Number,
      default: 2
    },

    placeholder: {
      type: String,
      default: ""
    },

    readonly: {
      type: Boolean,
      default: false
    },

    faded: {
      type: Boolean,
      default: false
    }
  },

  emits: ["input"],

  data() {
    return {
      dotIndex: -1
    };
  },

  computed: {
    displayValue() {
      let value = this.value ? this.value : "";
      value = value.replace(",", ".");

      if (this.decimal) {
        if (this.dotIndex > 0) {
          value.slice(0, this.dotIndex) + "," + value.slice(this.dotIndex);
        }
        value = BigNumber(value).dividedBy(BigNumber(10).pow(this.decimal));
        value = value.isNaN() ? "" : value;
        value = value.toString(10).replace(".", ",");
      }

      return value.toString();
    }
  },

  methods: {
    input(value) {
      if (value[value.length - 1] == ",") {
        value += "0";
      }

      if (this.decimal) {
        this.dotIndex = value.indexOf(",");
        value = value.replace(",", ".");
        value = BigNumber(value).multipliedBy(BigNumber(10).pow(this.decimal)).toString(10);
      }

      this.$emit("input", value.toString());
    }
  }
};

</script>

<style scoped lang="scss">
.faded{
  color: var(--color-80)
}
</style>