<template>
  <div>
    <div class="ts-header">
      <p>{{ $t("buyForm.third.title.1") }}</p>
      <p>{{ $t("buyForm.third.title.2") }}</p>
      <p>
        {{ $t("buyForm.third.title.3") }} <a
          :href="scanUrl + 'address/' + address + '#tokentxns'"
          target="_blank"
        >{{ $t("buyForm.third.title.4") }}</a>.
      </p>
    </div>
    <div class="ts-divider" />
    <div class="ts-info">
      <div class="ts-info-element">
        <span class="ts-text">{{ $t("buyForm.third.type") }}</span>
        <span class="ts-bold">{{ defaultTokenMnemonic }}</span>
      </div>
    </div>

    <div class="ts-referral">
      <span class="ts-text">
        {{ $t("buyForm.third.received") }}
      </span>
    </div>

    <div class="ts-referral">
      <span class="ts-text">
        {{ $t("buyForm.third.support") }}
        <span
          class="ts-green"
          @click="openChat"
        >
          AutenticSupport.
        </span>
      </span>
    </div>
    <div class="ts-more-info ts-text">
      {{ $t("buyForm.third.projects") }} <RouterLink :to="{name: 'projects'}">
        {{ $t("buyForm.third.projectsRef") }}
      </RouterLink>.
    </div>
    <div class="ts-next">
      <AuButton
        center
        width="40%"
        type="primary"
        @click="$emit('next')"
      >
        {{ $t("buyForm.third.next") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ThirdStage",
  props: {
    address: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    scanUrl() {
      return process.env.VUE_APP_BSCSCAN_BASE_URL;
    }
  },
  methods: {
    openChat() {
      window.carrotquest.open();
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: underline;
  color: #279063;
}

.ts-header {
  font-size: 26px;
}
.ts-divider {
  border-bottom: 3px solid green;
  width: 200px;
  margin: 20px 0;
}
.ts-info {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}
.ts-info-element {
  display: flex;
  gap: 10px;
}
.ts-referral {
  margin-bottom: 15px;
}
.ts-more-info {
  margin-bottom: 40px;
}
.ts-next {
  display: flex;
  justify-content: center;
}
.ts-text {
  font-size: 18px;
}
.ts-bold {
  font-size: 18px;
  font-weight: 600;
}
.ts-green {
  color: #279063;
  cursor: pointer;
}
</style>