<template>
  <div class="main">
    <AuIcon
      class="logo-img"
      icon="logo.v2"
    />
    <div class="text-content">
      <p><em>Privacy policy Autentic LTD</em></p>
      <p>This privacy policy is effective as of September 01, 2023.</p>
      <p>This privacy policy (the &#8220;Policy&#8221;) applies to all information, including personal data within the meaning of applicable law (&#8220;Personal Information&#8221;), that Autentic LTD and/or its affiliates may obtain about you in the course of your use of any Autentic websites, programs, products and/or services.</p>
      <p>Autentic LTD (&#8220;Autentic LTD&#8221;, hereinafter &#8220;we&#8221; or &#8220;us&#8221;), incorporated under the laws of Seychelles and having an address of Suite 1, Second Floor, Sound &amp; Vision House, Francis Rachel Str., Victoria, Mahe, Seychelles. collects, uses and discloses information about you when you use our website, https://aut.finance and the services at https://autentic.capital (collectively, the &#8220;Services&#8221;) when you interact with us as described below.</p>
      <p>We may change this Privacy Policy from time to time in our sole discretion. If we make changes, we will notify you by changing the date at the top of this Privacy Policy and the updated Privacy Policy will be posted via a hyperlink on a page on our website. In some cases, we may provide you with additional notice (for example, by adding a Privacy Policy change statement to the home page of our website or by sending you an email notice). We encourage you to review this Privacy Policy each time you contact us so that you are aware of our information practices and the choices available to you.</p>
      <p>It is your responsibility to periodically visit our Website to review any changes that may be made to this Privacy Policy. Your continued use of our Website and Services constitutes your agreement to be bound by the Privacy Policy and any changes to the Privacy Policy. If you do not agree to this Privacy Policy, you may not use our Website, and your sole remedy is to refuse to use our Website or Services.</p>
      <p>We understand that your privacy is very important and we are committed to protecting it. This includes Personal Data or information you may provide when you access and use the content of the websites located at https://aut.finance, https://autentic.capital, or any other websites, applications, pages, features or content that we own or operate.</p>
      <p>In addition, the information you provide to Autentic LTD in response to an email, request for information or any other contact with Autentic LTD, and as part of any Autentic LTD application processes, will also be processed in accordance with this Privacy Policy.</p>
      <p>In this Privacy Policy, Autentic LTD describes Autentic LTD&#8217;s use of your Personal Data and the scope and breadth of our privacy obligations. We are committed to ensuring that your Personal Data is handled responsibly and in accordance with the requirements of state and federal laws and regulations.</p>
      <ol
        start="1"
        class="wp-block-list"
      >
        <li>Acceptance of Autentic LTD Privacy Policy</li>
      </ol>
      <p>By exploring and using Autentic LTD&#8217;s Site and/or Services, and by submitting information to Autentic LTD, you signify your agreement to the terms of our Privacy Policy.</p>
      <p>If we need your consent to process your Personal Data, we will ask you to consent to the collection, use and disclosure of your Personal Data as described below.</p>
      <p>Autentic LTD may provide additional &#8220;timely&#8221; disclosures or additional information about the methods of collection, use and transmission of data in specific systems.</p>
      <p>If you do not agree with any aspect of this Privacy Policy, you should immediately stop accessing or using our Services.</p>
      <ol
        start="2"
        class="wp-block-list"
      >
        <li>Data and personal data</li>
      </ol>
      <p>Personal data is data that can be used to directly or indirectly identify you or to contact you.</p>
      <p>Our Privacy Policy applies to all personal data that you voluntarily provide to us and that we receive from our partners. This Privacy Policy does not cover anonymized data, as it cannot be used to identify you. You may be asked to provide Personal Data any time you come into contact with us.</p>
      <p>Autentic LTD may also combine your Personal Information with other information to provide and improve our products, services and content (see section below).</p>
      <p>Except as described in this Privacy Policy, Autentic LTD will not transfer, sell, rent, lease or loan any Personal Information to third parties.</p>
      <p>&nbsp; &nbsp;2.1 Personal data we collect</p>
      <p>We collect Personal Data that you provide to us directly or that you generate when you register with us, use our Services or our website(s) and platform(s).</p>
      <p>When we require certain personal data from users, it is because we are required to collect that data by law (anti-fraud, anti-money laundering, terrorist financing, KYC &#8220;know your customer&#8221; obligations) or it is necessary for certain purposes. Any information you provide to us unnecessarily is voluntary.</p>
      <p>You are free to choose whether to provide us with the types of Personal Information requested, but we may not serve you as effectively or offer you all of our Services if you choose not to provide us with certain information.</p>
      <p>We also collect Personal Information when you use or request information about our Services, sign up for marketing emails, request support, complete surveys, or sign up for one of our events.</p>
      <p>We may also collect Personal Information from you offline, such as when you attend one of our events or contact our support team.</p>
      <p>We may use this information in conjunction with other information we collect about you as set forth in this Privacy Policy.</p>
      <p>&nbsp; &nbsp;2.2 Data collection when registering / using our services</p>
      <p>When registering on our Site(s), you may be asked to enter:</p>
      <p>&#8211; Contact Information and Personal Information such as your name, residential address, business and mailing addresses, telephone number, e-mail address;</p>
      <p>&#8211; Profile information, such as username and password;</p>
      <p>&#8211; Demographic data such as date of birth, country of citizenship and/or country of residence;</p>
      <p>&#8211; State-issued identification (to the extent permitted by applicable law), such as a national identification number (such as a Social Security number, taxpayer identification number, or passport number), state or local identification number (such as a driver&#8217;s license or other state-issued identification number), a copy of your state-issued identification card, and appropriate state-issued photographs;</p>
      <p>&#8211; Wallet and account and payment information that you may use to receive the Services, other relevant payment information and cryptocurrency wallet addresses that you may use to receive AUT Autentic LTD tokens and revenue payment tokens, if any;</p>
      <p>&#8211; Information about your connection to the company, such as your current occupation, title, and whether you are the beneficial owner or authorized representative of the entity using the Service;</p>
      <p>&#8211; Feedback and correspondence, such as information you provide when you request information from us, receive customer support or otherwise correspond with us, including by interacting with our pages on websites or social media services;</p>
      <p>&#8211; Marketing information, such as your preferences for receiving marketing messages and information about how you interact with our marketing messages;</p>
      <p>Transaction Information:</p>
      <p>&#8211; Information about the transactions you make on our Services, such as the name of the recipient, your name, amount and/or time stamp.</p>
      <p>&#8211; Social media information such as your username, your preferences;</p>
      <p>&#8211; Other information you choose to provide.</p>
      <p>We may also collect Personal Information from third-party partners and public sources where required or permitted by applicable law, such as public databases, identity verification partners, reseller and channel partners, co-marketing partners, and social media platforms, which include:</p>
      <p>&#8211; Financial information</p>
      <p>&#8211; Reputational information</p>
      <p>&#8211; Corporate/business activities of corporate clients.</p>
      <p>We use publicly available databases and identity verification partners to verify your identity. Our identity verification partners use a combination of public records and publicly available information about you to verify your identity. Such information includes (may include) your name, address, title, public employment profile, credit history, status on any sanctions lists maintained by government agencies, and other relevant data. We obtain such information to fulfill our legal obligations, such as anti-money laundering laws.</p>
      <p>In some cases, we may process additional data about you to ensure that our Services are not used fraudulently or for other illegal activities. In such cases, the processing is necessary to enable us to continue to fulfill our contracts with you and others.</p>
      <p>&nbsp; 2.3 Collecting data when you visit our website</p>
      <p>When you visit our website(s), we save each instance of access in a log file, so the following data is saved:</p>
      <ul class="wp-block-list">
        <li>Information about your computer or mobile device</li>
        <li>The website from which our domain is accessed and information about using the site</li>
        <li>Your computer&#8217;s operating system</li>
        <li>Country from which our website is accessed</li>
        <li>The name of your Internet service provider</li>
        <li>Name and URL of the data being accessed</li>
        <li>Date and time of access</li>
        <li>The IP address of the computer from which the access was made.</li>
      </ul>
      <p>These data are generally anonymized and not personally identifiable and are used only to establish a link to the website, to ensure the security and stability of the system and for statistical purposes. This data is not associated or stored together with personal data.</p>
      <p>The IP address of the computer from which access is made is only analyzed in the event of an attack on the network infrastructure or suspected unauthorized or improper use of the website and exclusively for protection purposes or for use in criminal proceedings for the identification and criminal and/or civil proceedings against the user concerned. The IP address is also used to filter geographical areas from accessing our services.</p>
      <p>We also partner with Yandex Metrika and Google Analytics to track how you use and interact with our website using behavioral metrics, heat maps and session replay to improve and promote our products/services. Site usage data is collected through first and third party cookies and other tracking technologies to determine product/service popularity and online activity. We also use this information for site optimization, anti-fraud/security and advertising purposes. For information on how Yandex Metrika and Google Analytics collects and uses your data, please see the privacy statements of the said companies.</p>
      <ol
        start="3"
        class="wp-block-list"
      >
        <li>How your Personal Information is used</li>
      </ol>
      <p>Our primary goal in collecting Personal Information is to provide you with a secure, seamless, efficient and personalized experience. In general, we use Personal Information to create, develop, operate, deliver and improve our Service, Services, content and advertising, as well as for loss prevention and anti-fraud purposes. We may use this information in the following ways:</p>
      <p>&nbsp; &nbsp;3.1 To comply with legal and regulatory requirements</p>
      <p>Some of our Services are subject to laws and regulations that require us to collect and use your Personal Identifying Information, Formal Identifying Information, financial information, transactional information, employment information, online identifiers and/or usage data in certain ways.</p>
      <p>We must identify and verify customers who use our Services and Services in order to comply with anti-money laundering and anti-terrorist financing laws in various jurisdictions. In addition, we use (plan to use) third parties to verify your identity by comparing the personal information you provide with third-party databases and public records.</p>
      <p>We may require you to provide additional information that we may use in conjunction with service providers acting on our behalf to verify your identity or address, and/or for risk management purposes as required by applicable law. If you do not wish to have your personal information processed for such purposes, we will terminate your account because we will not be able to provide the Services in accordance with legal and regulatory requirements.</p>
      <p>&nbsp; 3.2 To ensure compliance with our terms in the User Agreement and other agreements</p>
      <p>We handle sensitive information such as your identification and financial data, so it is very important to us and our customers that we actively monitor, investigate, prevent and remedy any potentially prohibited or illegal activities, enforce our agreements with third parties and/or violations of our posted user agreement or other Services agreement. In addition, we may need to charge you for your use of our Services, third party Services. We collect information about your account usage and closely monitor your interactions with our Services. We may use any of your Personal Information collected on our Services for these purposes. The consequences of not using your Personal Information for these purposes is termination of your account because we cannot provide our Services in accordance with our terms.</p>
      <p>&nbsp; &nbsp;3.3. To provide Autentic LTD services</p>
      <p>We process your Personal Information in order to provide the Services to you.</p>
      <p>We cannot provide you with the Services without such information.</p>
      <p>&nbsp; &nbsp;3.4 To ensure communication with services</p>
      <p>We send you administrative, promotional, or account-related information to keep you informed about our Services, to inform you of relevant security issues or updates, and to provide other transaction-related information. Without such communications, you may not be aware of important events related to your account that may affect how you can use our Services.</p>
      <p>&nbsp; 3.5 To provide customer service</p>
      <p>We process your Personal Information when you contact us to resolve issues, disputes, or troubleshoot problems. We may process your information in response to a request from another customer, if necessary. Without processing your Personal Information for such purposes, we may not be able to respond to your requests and ensure that your use of the Services is uninterrupted.</p>
      <p>&nbsp; 3.6 To ensure quality control</p>
      <p>We process your Personal Information for quality control and staff training to ensure that we continue to provide you with accurate information. If we do not process Personal Information for quality control purposes, you may experience problems with the services and services, such as inaccurate transaction records or other interruptions. The basis for such processing is to fulfill our obligations with you.</p>
      <p>&nbsp; 3.7 To ensure network and information security</p>
      <p>We process your Personal Information to improve security, to monitor and verify identity or access to services, to combat spam or other malware or security risks, and to comply with applicable security laws and regulations. Threats on the Internet are constantly evolving, so it is more important than ever for us to have accurate and up-to-date information about how you use our Services and Services. Without processing your personal information, we cannot keep our Services and Services secure.</p>
      <p>&nbsp; 3.8 For research and development purposes</p>
      <p>We process your Personal Information to better understand how you use and interact with our Services and Services. In addition, we use such information to customize, measure and improve the Services and Services, the content and design of our website(s), and to develop new products. Without such processing, we will not be able to provide you with continued use of our Services and Services. The basis for such processing is our legitimate interest.</p>
      <p>&nbsp; 3.9 To improve the performance of your website</p>
      <p>We process your Personal Information to provide a personalized experience and to implement the preferences you have requested. For example, you may choose to give us access to certain Personal Information held by third parties. Without such processing, we may not be able to ensure your continued use of part or all of our Services and Services.</p>
      <p>&nbsp; 3.10 To participate in marketing activities</p>
      <p>Based on your communication preferences, we may send you marketing messages to inform you about our events or those of our partners; provide targeted marketing; and provide you with promotional offers based on your communication preferences. We use information about how you use our Services and your contact information to provide marketing messages. You may opt-out of our marketing communications at any time.</p>
      <p>If you are a current customer residing in the EEA, we will only contact you by electronic means (email) with information about our Services that are similar to those that were the subject of a previous sale or negotiation of a sale to you.</p>
      <p>If you are a new customer and are in the EEA, we will only contact you, if you are in the EU, by electronic means for marketing purposes if you have consented to such communication. You may opt out of further processing for direct marketing purposes (e.g. New Letters) at any time and free of charge.</p>
      <p>We will not use your Personal Information for purposes other than those we have disclosed to you without your authorization. From time to time, we may request your permission to share your Personal Information with third parties. You may refuse to share your personal information with third parties or to allow us to use your Personal Information for purposes incompatible with the purposes for which we originally collected it or subsequently obtained your authorization. If you choose to restrict the use of your Personal Information, some features or our Services may not be available to you.</p>
      <ol
        start="4"
        class="wp-block-list"
      >
        <li>Cookies &#8211; collection and use of data collected automatically</li>
      </ol>
      <p>Cookies are small files that a website or its service provider transfers to your computer&#8217;s hard drive through your web browser (if you have allowed), which allows the website or service provider&#8217;s systems to recognize your browser and collect and remember certain information. They are widely used to make websites work or operate more efficiently, and to provide information to website owners.</p>
      <p>Cookies are stored on your computer&#8217;s hard disk and do not necessarily transfer your personal data to Autentic LTD.</p>
      <p>Cookies are used to help Autentic LTD understand your preferences based on previous or current actions on the site, which enables Autentic LTD to provide you with better services and experiences.</p>
      <p>Cookies are also used for security purposes and to collect aggregate data about website traffic and interactions in order to offer better services and tools in the future.</p>
      <p>Autentic LTD only uses &#8220;first party&#8221; cookies that communicate directly between our services and your computer. Autentic LTD does NOT track your usage in other applications. Autentic LTD may also use trusted third party service providers who track this information on Autentic LTD&#8217;s behalf. Our service providers place cookies on your computer&#8217;s hard drive and receive data that we select in order to provide us with detailed information about:</p>
      <p>&#8211; How visitors navigate our site</p>
      <p>&#8211; Which sections are being viewed</p>
      <p>&#8211; General Information.</p>
      <p>Our service provider(s) analyze this data and provide(s) us with aggregate reports. The data and analysis provided by our service provider(s) is used to help us better understand the interests of our website visitors and how to better serve those interests. Data collected by our service provider(s) may be linked and combined with data we collect about you when you use the websites. Our service provider(s) are contractually prohibited from using the information they obtain from our Website other than to assist us.</p>
      <p>You can choose to have your computer warn you (through your browser settings) each time a cookie is sent, or you can choose to disable all cookies. If you choose to disable cookies, some features that make your browsing experience more efficient may not work properly.</p>
      <p>In order to continuously optimize the website Autentic LTD uses web analytics services &#8220;Google Analytics&#8221; and Yandex Metrika &#8211; these are web analytics services offered by the company that track and report on website traffic. The collected data is processed in a non-personalized form (IP-anonymization).</p>
      <p>You can opt out of having your activities on the Autentic LTD website available to Google Analytics and Yandex Metrika by installing the browser add-on you prefer to use.</p>
      <p>For more information about the privacy practices of Yandex Metrika and Google Analytics, please see the privacy statements of these companies.</p>
      <ol
        start="5"
        class="wp-block-list"
      >
        <li>Disclosure and transfer of Personal Data</li>
      </ol>
      <p>We may disclose your Personal Information to third parties, legal and regulatory authorities, and share your Personal Information as described below.</p>
      <p>There are certain circumstances in which we may share your Personal Information with employees, contractors and other parties.</p>
      <p>We may also share your information with certain contractors or service providers. They may process your Personal Data for us, for example, if we use a marketing agency. Other recipients/service providers include advertising agencies, IT specialists, database providers, backup and disaster recovery specialists, email providers or other companies. Our vendors and service providers must meet our information handling and security standards. The information we share with them, including your information, will only be shared in connection with the performance of their functions;</p>
      <p>We may also share your information with certain other third parties. We will do this either when we obtain your consent or because we need them to see your information in order to provide products or services to you. These include credit reference agencies, fraud databases, verification agencies and other partners we work with.</p>
      <p>Your personal data may be shared with other third-party organizations in certain cases:</p>
      <ul class="wp-block-list">
        <li>If we are discussing the sale or transfer of part or all of our business &#8211; information may be shared with potential buyers under appropriate confidentiality terms;</li>
        <li>If we reorganize or are sold, the information may be transferred to a buyer who can continue to provide services to you;</li>
        <li>If we are required to do so by law or by any regulatory code or practice we follow, or if we are asked to do so by any governmental or regulatory authority;</li>
        <li>If we are defending ourselves against a legal action, your information may be shared if necessary in connection with defending ourselves against such an action.</li>
      </ul>
      <p>Your Personal Data may be transferred if it is anonymized and aggregated, as in this case the information ceases to be personal data.</p>
      <p>Your information will not be sold, exchanged or transferred to third parties without your consent, except to Autentic LTD Services or as required by law.</p>
      <ol
        start="6"
        class="wp-block-list"
      >
        <li>Data transfer</li>
      </ol>
      <p>We store and process your Personal Data in data centers around the world, wherever our facilities or service providers are located.</p>
      <p>Some countries to which your Personal Data may be transferred and processed do not have appropriate protection regulation.</p>
      <ol
        start="7"
        class="wp-block-list"
      >
        <li>Data security</li>
      </ol>
      <p>We take all reasonable technical and organizational security measures to protect your data from manipulation, loss or access by unauthorized third parties.</p>
      <p>Our Websites are regularly tested for security vulnerabilities to make your visit to our Sites as safe as possible.</p>
      <p>Your Personal Information is stored behind secure networks and is only accessible to a limited number of individuals who have special access rights to such systems and are required to keep the information confidential.</p>
      <p>While no method of data transmission over the Internet or method of electronic storage is completely secure, we strive to continually update and improve our security measures to keep up with the latest technological advances.</p>
      <p>Please note that we generally never ask for financial or payment information such as your credit card number, access codes, account number or pin number in emails, text messages in chat rooms on websites or any other communications we send to you. Please always verify that any site that asks you to provide financial or payment information in connection with our services and services is operated by Autentic LTD. The risk of fraud exists and should be considered when using our sites, services and facilities.</p>
      <p>If you receive a suspicious request, do not provide your information and report it by contacting our support team as outlined in this Privacy Policy.</p>
      <p>Because we cannot 100% guarantee that loss, misuse, unauthorized acquisition or alteration of your data will not occur, please accept that you play an important role in protecting your own Personal Information. When registering with us, it is important that you choose an appropriate password of sufficient length and complexity, do not disclose it to third parties, and notify us immediately if you become aware of unauthorized access to or use of your account.</p>
      <p>In addition, we cannot ensure or warrant the security or privacy of information you transmit to us or receive from us over the Internet including email, as we have no way to protect that information after it leaves us and until it reaches us. If you have reason to believe that your information is no longer protected, please contact us at the email address listed at the end of this Privacy Policy.</p>
      <ol
        start="8"
        class="wp-block-list"
      >
        <li>Data storage</li>
      </ol>
      <p>&nbsp; &nbsp;8.1 Personal data</p>
      <p>Autentic LTD will only retain your Personal Data for as long as is necessary for the purposes set out in this Privacy Policy.</p>
      <p>Autentic LTD will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your Data to comply with applicable law), resolve disputes and enforce our legal agreements and policies.</p>
      <p>Therefore, in accordance with our recordkeeping obligations, we will retain the account and other Personal Information for at least 3 years (and in some cases up to ten years if required by applicable law) after account termination.</p>
      <p>&nbsp; &nbsp;8.2 Data collected automatically</p>
      <p>Autentic LTD also stores data collected automatically, either from the use of the website or from the infrastructure of the website itself (such as the duration of a page visit) for internal analysis purposes.</p>
      <p>Data collected by technical means such as cookies, page counters and other analytics tools are stored for 10 years from the expiration date of the cookie.</p>
      <ol
        start="9"
        class="wp-block-list"
      >
        <li>Disclosure of information in connection with legal compliance</li>
      </ol>
      <p>Autentic LTD complies with all applicable privacy laws and regulations.</p>
      <p>Autentic LTD may be required to disclose personal information about users or customers to legal authorities without the express consent of the user if a court order or similar legal or administrative order is served, or if required or permitted by the laws, rules and regulations of any country, region or other applicable jurisdiction.</p>
      <ol
        start="10"
        class="wp-block-list"
      >
        <li>Your rights</li>
      </ol>
      <p>&nbsp;10.1 General Provisions</p>
      <p>Autentic LTD is the Data Controller in respect of your Personal Data. We determine the means and purposes of processing your Data.</p>
      <p>We may process your Personal Data, where you have consented to its processing, in order to comply with our legal obligations, where this is necessary to fulfill our obligations arising from any contracts and agreements we have entered into with you, or to take action at your request prior to entering into a contract with you, or in our legitimate interests to protect the property, rights or safety of Autentic LTD, our customers or others.</p>
      <p>When possible, you can update your Personal Information directly in the settings section of your account. If you are unable to change your Personal Information, please contact us so that we can make the necessary changes.</p>
      <p>
        If you have any questions or wish to exercise your rights, please contact our Data Protection Officer by emailing <a
          href="/cdn-cgi/l/email-protection"
          class="__cf_email__"
          data-cfemail="a5d6d0d5d5cad7d1e5c4d0d18bc3cccbc4cbc4c6c0"
        >[email&#160;protected]</a> so that we can deal with your request in accordance with applicable law.
      </p>
      <p>We reserve the right to verify your identity before responding to such requests.</p>
      <p>Please note that according to the laws of various countries, specific data must be stored for a certain period of time. Therefore, such data must remain with Autentic LTD until the statutory period expires. This specific data is locked in our system and is only used to fulfill legal requirements.</p>
      <p>&nbsp; 10.2 Individual rights in relation to your data</p>
      <p>&nbsp; 10.2.1 The right to access your personal data</p>
      <p>You have the right to ask Autentic LTD whether we process your data. If so, you may request a copy of your personal data from us. We may require you to specify the information or processing activities to which your request relates. This information will be provided without undue delay.</p>
      <p>Your right of access can only be limited if the burden or cost of providing access would be disproportionate to the risk to your privacy in this case, or if the rights of persons other than you would be violated.</p>
      <p>&nbsp; 10.2.2 The right to have your Personal Data corrected</p>
      <p>You have the right to request correction or updating of any incomplete or inaccurate Personal Data we hold about you. Your right to access and rectification may be limited only if the burden or expense of providing access would be disproportionate to the risks to your privacy in this case, or if the rights of persons other than you would be violated.</p>
      <p>&nbsp; 10.2.3 Right to withdraw consent</p>
      <p>You have the right to withdraw your consent to the processing of your Personal Data collected on the basis of your consent at any time. Your withdrawal will not affect the lawfulness of previous processing based on your consent prior to the withdrawal.</p>
      <p id="10-2-4">
        &nbsp; 10.2.4 The right to have your personal data deleted
      </p>
      <p>You have the right to ask us to delete or remove Personal Data in certain circumstances. There are also certain exceptions where we may refuse a deletion request, for example, if the Personal Data is necessary to comply with the law or in connection with a claim.</p>
      <p>
        To ask us for removing Personal Data send email to&nbsp;<strong><a
          href="/cdn-cgi/l/email-protection"
          class="__cf_email__"
          data-cfemail="7a151c1c13191f3a1b0f0e1f140e131954191b0a130e1b16"
        >[email&#160;protected]</a></strong>&nbsp;with subject &#8220;Delete my account&#8221; from email bound with your account.
      </p>
      <p>&nbsp; 10.2.5 Right to data portability</p>
      <p>If we process your Personal Data on the basis of a contract with you or on the basis of your consent, or the processing is carried out by automated means, you may request to receive your Personal Data in a structured, commonly used and machine-readable format, and ask us to transfer your Personal Data directly to another &#8220;controller&#8221; (the natural or legal person who determines the purposes and means of the processing of your Personal Data) where technically feasible, unless the exercise of this right is for</p>
      <p>&nbsp; 10.2.6 Right to restrict processing</p>
      <p>You have the right to restrict or object to our processing of your Personal Data if any of the following conditions apply:</p>
      <p>&#8211; You dispute the accuracy of your Personal Data that we have processed. In such cases, we will restrict processing for the period necessary for us to verify the accuracy of your Personal Data.</p>
      <p>&#8211; The processing is unlawful and you oppose the deletion of your Personal Data and request that we restrict its use instead.</p>
      <p>&#8211; We no longer need your Personal Data for processing purposes, but you need it to establish, exercise or defend legal claims.</p>
      <p>&nbsp; 10.2.7 Right to object to processing</p>
      <p>If we process your personal data on the basis of consent or legitimate interests (or those of a third party), you may object to the processing of your personal data at any time. However, we may have the right to continue processing your personal data based on our legitimate interests or if it is necessary for the defense of legal claims.</p>
      <p>You also have the right to object if we process your personal data for direct marketing purposes.</p>
      <p>&nbsp; 10.2.8 Automated solutions</p>
      <p>You may challenge any automated decision made about you if it has legal or similarly significant consequences and request reconsideration.</p>
      <p>&nbsp; 10.3 Complaint</p>
      <p>
        If you believe that we have violated your rights, we encourage you to first contact us at <a
          href="/cdn-cgi/l/email-protection"
          class="__cf_email__"
          data-cfemail="60090e060f200115144e06090e010e010305"
        >[email&#160;protected]</a> so that we can attempt to resolve the matter informally.
      </p>
      <p>You also have the right to lodge a complaint with the supervisory authority where we are located or in the event of an alleged breach of data protection law.</p>
      <ol
        start="11"
        class="wp-block-list"
      >
        <li>Links to other websites</li>
      </ol>
      <p>Autentic LTD&#8217;s websites, namely https://aut.finance and https://autentic.capital, may contain links to other websites that are not operated by Autentic LTD. If you click on a third party link, you will be directed to that third party&#8217;s site.</p>
      <p>Autentic LTD strongly encourages you to review the privacy policy of each website you visit.</p>
      <p>Autentic LTD has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.</p>
      <ol
        start="12"
        class="wp-block-list"
      >
        <li>Changes to this Privacy Policy</li>
      </ol>
      <p>Autentic LTD may update the Privacy Policy from time to time.</p>
      <p>You are encouraged to periodically review this Privacy Policy for changes. Changes to this Privacy Policy will be effective upon posting on https://aut.finance and/or https://autentic.capital.</p>
      <ol
        start="13"
        class="wp-block-list"
      >
        <li>Contact us</li>
      </ol>
      <p>
        If you have suggestions, questions or concerns about this Privacy Policy, or if you have a complaint, you should first contact us at <a
          href="/cdn-cgi/l/email-protection"
          class="__cf_email__"
          data-cfemail="f891969e97b8998d8cd69e91969996999b9d"
        >[email&#160;protected]</a>.
      </p>
      <p>Risk Factors</p>
      <p>The purchase of tokens involves a high degree of risk, including but not limited to the risks described below. Before acquiring AUT tokens, it is recommended that each participant carefully weighs all the information and risks detailed in this White Paper, and, specifically, the following risk factors.</p>
      <p>A. Dependence on computer infrastructure</p>
      <p>AUTENTIC’s dependence on functioning software applications, computer hardware and the Internet implies that AUTENTIC can offer no assurances that a system failure would not adversely affect the use of your AUT tokens. Despite AUTENTIC&#8217;s implementation of all reasonable network security measures, its processing center servers are vulnerable to computer viruses, physical or electronic break-ins or other disruptions of a similar nature. Computer viruses, break-ins or other disruptions caused by third parties may result in interruption, delay or suspension of services, which would limit the use of the AUT tokens.</p>
      <p>B. Smart contract limitations</p>
      <p>Smart contract technology is still in its early stages of development, and its application is of experimental nature. This may carry significant operational, technological, regulatory, reputational and financial risks. Consequently, lthough the audit conducted by independent third party increases the level of security, reliability, and accuracy, this audit cannot serve as any form of warranty, including any expressed or implied warranty that the AUT Smart Contract is fit for purpose or that it contains no flaws, vulnerabilities or issues which could cause technical problems or the complete loss of AUT tokens.</p>
      <p>C. Regulatory risks</p>
      <p>Blockchain technology, including but not limited to the issue of tokens, may be a new concept in some jurisdictions, which may then apply existing laws or introduce new regulations regarding Blockchain technology-based applications, and such regulations may conflict with the current AUT smart contract setup and AUT token concept. This may result in the need to make substantial modifications to the AUT smart contract, including but not limited to its termination, the loss of AUT tokens, and the suspension or termination of all AUT token functions.</p>
      <p>D. Taxes</p>
      <p>AUT token holders may be required to pay taxes associated with the transactions contemplated herein, whether in the United States or in their home countries. It will be a sole responsibility of AUT token holders to comply with the tax laws of the United States and other jurisdictions applicable to them and pay all relevant taxes.</p>
      <p>E. Force Majeure</p>
      <p>AUTENTIC performance may be interrupted, suspended or delayed due to force majeure circumstances. For the purposes of this White Paper, force majeure shall mean extraordinary events and circumstances which could not be prevented by AUTENTIC and shall include: acts of nature, wars, armed conflicts, mass civil disorders, industrial actions, epidemics, lockouts, slowdowns, prolonged shortage or other failures of energy supplies or communication service, acts of municipal, state or federal governmental agencies, other circumstances beyond AUTENTIC’s control, which were not in existence at the time of White Paper release. If such circumstances occur prior to the issue of AUT tokens and AUTENTIC is unable to issue AUT tokens within one month from the projected date, the escrow agent may issue a refund at the request of the AUT token purchasers. The refund will be issued in the original form of payment to the same digital wallet or bank account where the funds were transferred from.</p>
      <p>F. Disclosure of information</p>
      <p>Personal information received from AUT token holders, the information about the number of tokens owned, the wallet addresses used, and any other relevant information may be disclosed to law enforcement, government officials, and other third parties when AUTENTIC is required to disclose such information by law, subpoena, or court order. AUTENTIC shall at no time be held responsible for such information disclosure.</p>
      <p>G. Value of AUT token</p>
      <p>Once purchased, the value of AUT token may significantly fluctuate due to various reasons. AUTENTIC does not guarantee any specific value of the AUT token over any specific period of time. AUTENTIC shall not be held responsible for any change in the value of AUT token.</p>
      <p>H. Number of submissions by ICO projects</p>
      <p>AUTENTIC does not guarantee any number of ICO projects’ applications submitted through its platform and/or available for voting. AUTENTIC has control over neither the number of projects applying for an ICO nor over their quality. The expected number of submissions is based on the number of inquiries received by AUTENTIC to date and AUTENTIC’s market forecasts. The number of submissions and the number of projects presented for voting may vary and may not reach the expected volume, which may ultimately affect the expected length of the project and the AUTENTIC token holders’ ability to exchange their tokens for the new ICO projects’ tokens at the anticipated rate and discount.</p>
      <p>I. Number of new ICO projects’ tokens available for exchange</p>
      <p>The number of new ICO projects’ tokens available for exchange for AUT tokens may vary due to multiple factors such as the amount of funds received by AUTENTIC through the sale of its AUT tokens, the total number of AUT tokens sold, market conditions, legal regulations, and other risks. AUTENTIC does not guarantee any specific number of new projects’ tokens available for exchange for an AUT token at any given time.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style scoped>
.main {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.logo-img {
  width: 30%;
}
.text-content {
  font-size: 20px;
  line-height: 35px;
}
</style>