<template>
  <div class="pnf-main">
    <AuIcon
      icon="logo"
      class="pnf-logo"
    />
    <span class="pnf-text">
      {{ prepareMessageNotes }}
    </span>
    <AuButton
      :type="'primary'"
      center
      height="44px"
      @click="$router.push({ name: 'base' })"
    >
      {{ $t("pageNotFound.toMain") }}
    </AuButton>
  </div>
</template>

<script>
export default {
  name: "AuthSocial",
  data() {
    return {
      message: "",
      code: ""
    };
  },

  computed: {
    prepareMessageNotes() {
      switch (this.message) {
      case "access_denied":
        return "Access denied";
      case "AxiosError":
        return "Request failed with status code 500";
      default:
        return "Authorization page...";
      }
    }
  },

  mounted() {
    this.responseSocial();
  },

  methods: {
    responseSocial() {
      if (this.$route.query?.error) {
        this.message = this.$route.query?.error;
        return;
      }
      if (this.$route.path == "/oath/google") {
        this.code = this.$route.query?.code;
        this.loginWithSocial("google");
      }
      if (this.$route.path == "/oath/fb") {
        this.code = this.$route.query?.code;
        this.loginWithSocial("fb");
      }
    },
    async loginWithSocial(path) {
      const params = {
        code: this.code
      };
      try {
        const resp = await this.axios.get(`/api/users/oath/${path}`, { params });

        this.$auth.remember(resp.data.user_identifier);
        this.$router.push({ name: "dashboard" });
      }
      catch (error) {
        console.log(error);
        this.message = error.name;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.pnf-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
  height: 100vh;
  width: 100vw;
  gap: 20px;
}
.pnf-logo {
  width: 250px;
}
.pnf-text {
  font-size: 30px;
  text-align: center;
}
</style>