<template>
  <div class="card cm-main">
    <div class="cm-header">
      <span class="title">{{ header }}</span>
      <AuButton
        bordered
        center
        @click="goBack"
      >
        {{ $t('companies.form.cancel') }}
      </AuButton>
    </div>
    <div>
      <div class="cm-section">
        {{ $t('companies.form.nameLabel') }}
      </div>
      <div class="cm-paragraph">
        <AuInput
          v-model="name"
          :placeholder="$t('companies.form.namePlaceholder')"
        />
      </div>
      <div class="cm-section">
        {{ $t('companies.form.descriptionLabel') }}
      </div>
      <div class="cm-paragraph">
        <AuInput
          v-model="description"
          :placeholder="$t('companies.form.descriptionPlaceholder')"
          is-text-area
        />
      </div>
      <div class="cm-section">
        {{ $t('companies.form.addressLabel') }}
      </div>
      <div class="cm-paragraph">
        <AuInput
          v-model="address"
          :placeholder="$t('companies.form.addressPlaceholder')"
        />
      </div>
      <div class="cm-section">
        {{ $t('companies.form.companyInfoLabel') }}
      </div>
      <div class="d-flex cm-paragraph">
        <div class="cm-input cm-indent">
          <AuSelect
            :value="countryCode"
            :options="countryOptions"
            :placeholder="$t('companies.form.countryPlaceholder') + '(*)'"
            required
            @input="value => countryCode = value"
          />
        </div>
        <div class="cm-input">
          <AuInput
            v-model="url"
            :placeholder="$t('companies.form.URLPlaceholder')"
          />
        </div>
      </div>
      <div class="cm-section">
        {{ $t('companies.form.companyReqLabel') }}
      </div>
      <div class="d-flex cm-paragraph">
        <div class="cm-input cm-indent">
          <div class="cm-newline">
            <AuInput
              v-model="rusInn"
              :placeholder="$t('companies.form.INNPlaceholder')"
            />
          </div>
          <div>
            <AuInput
              v-model="rusKpp"
              :placeholder="$t('companies.form.KPPPlaceholder')"
            />
          </div>
        </div>
        <div class="cm-input">
          <div class="cm-newline">
            <AuInput
              v-model="rusOgrn"
              :placeholder="$t('companies.form.OGRNPlaceholder')"
            />
          </div>
          <div>
            <AuInput
              v-model="rusBank"
              :placeholder="$t('companies.form.bankPlaceholder')"
            />
          </div>
        </div>
      </div>
      <div class="cm-section">
        {{ $t('companies.form.reqLabel') }}
      </div>
      <div class="cm-input cm-paragraph">
        <AuSwitch
          v-model="isVerifyRequest"
          :label="$t('companies.form.reqPlaceholder')"
        />
      </div>
    </div>
    <div>
      <AuButton
        bordered
        center
        active-bg-color="#279063"
        width="100%"
        @click="addOrEdit"
      >
        {{ button }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CompanyManager",
  props: {
    id: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      name: null,
      description: null,
      address: null,
      countryCode: null,
      url: null,
      isVerifyRequest: false,
      isVerified: false,
      rusInn: null,
      rusOgrn: null,
      rusKpp: null,
      rusBank: null,
      index: null
    };
  },

  computed: {
    ...mapGetters({
      country: "country",
      companies: "companies"
    }),

    header() {
      return _.isEmpty(this.id) ?
        this.$t("companies.form.addCompanyLabel") :
        this.$t("companies.form.editCompanyLabel");
    },

    button() {
      return !this.isVerifyRequest ? this.$t("companies.form.saveAsDraft") : this.header;
    },

    countryOptions() {
      return _.map(this.country, c => ({ display: c.name, value: c.code }));
    }
  },
  async mounted() {
    if (_.isEmpty(this.country)) {
      await this.getCountry();
    }
    if (_.isEmpty(this.companies)) {
      await this.getCompanies();
    }
    if (!_.isEmpty(this.id)) {
      this.setFields(this.companies.find((item, index) => {
        if (item.id == this.id) {
          this.index = index;
          return true;
        }
      }));
    }
  },

  methods: {
    ...mapActions({
      getCountry: "getCountry",
      getCompanies: "getCompanies",
      addCompany: "addCompany",
      editCompany: "editCompany"
    }),

    goBack() {
      this.$router.push({ name: "companies" });
    },

    addOrEdit() {
      if (_.isEmpty(this.id)) {
        this.add();
      }
      else {
        this.edit();
      }
    },

    async add() {
      const params = {
        nameRus: this.name,
        nameEng: this.name,
        descriptionRus: this.description,
        descriptionEng: this.description,
        addressRus: this.address,
        addressEng: this.address,
        countryCode: this.countryCode,
        url: this.url,
        isVerifyRequest: this.isVerifyRequest,
        rusInn: this.rusInn,
        rusOgrn: this.rusOgrn,
        rusKpp: this.rusKpp,
        rusBank: this.rusBank,
        index: null
      };
      try {
        const data = await this.addCompany(params);
        this.companies.push(data);
        this.showSuccess(this.$t("toasts.success.companyAdd"));
        setTimeout(this.goBack, 1500);
      }
      catch (e) {
        this.showError(this.$t("toasts.error.companyAdd"));
      }
    },

    setFields(data) {
      this.name = data.nameEng;
      this.description = data.descriptionEng;
      this.address = data.addressEng;
      this.countryCode = data.countryCode;
      this.url = data.url;
      this.isVerifyRequest = data.isVerifyRequest;
      this.isVerified = data.isVerified;
      this.rusInn = data.rusInn;
      this.rusOgrn = data.rusOgrn;
      this.rusKpp = data.rusKpp;
      this.rusBank = data.rusBank;
    },

    async edit() {
      const params = {
        nameRus: this.name,
        nameEng: this.name,
        descriptionRus: this.description,
        descriptionEng: this.description,
        addressRus: this.address,
        addressEng: this.address,
        countryCode: this.countryCode,
        url: this.url,
        isVerifyRequest: this.isVerifyRequest,
        rusInn: this.rusInn,
        rusOgrn: this.rusOgrn,
        rusKpp: this.rusKpp,
        rusBank: this.rusBank
      };
      try {
        const data = await this.editCompany([this.id, params]);
        this.companies[this.index] = data;
        this.showSuccess(this.$t("toasts.success.companyEdit"));
        setTimeout(this.goBack, 1500);
      }
      catch (e) {
        this.showError(this.$t("toasts.error.companyAdd"));
      }
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    }
  }
};
</script>

<style scoped lang="scss">
.cm-main {
  padding: 20px;
}
.cm-header{
  display: flex;
  justify-content: space-between;
  .title{
    font-weight: 500;
    font-size: 20px;
  }
}
.cm-section{
  font-size: 14px;
  margin-bottom: 15px;
}
.cm-input{
  width: 50%;
}
.cm-indent{
  margin-right: 15px;
}
.cm-newline{
  margin-bottom: 10px;
}
.cm-paragraph{
  margin-bottom: 30px;
}
</style>