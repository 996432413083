<template>
  <div>
    <div class="card p-4">
      <div class="fs24">
        {{ $t("TestPage") }}
      </div>
      <hr>
      <div class="mt-2 mb-2">
        default, disable false
      </div>
      <div class="d-flex">
        <AuSwitchButton
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>


      <hr>
      <div class="mt-2 mb-2">
        primary, disable false
      </div>
      <div class="d-flex">
        <AuSwitchButton
          type="primary"
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          type="primary"
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          type="primary"
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>

      <hr>
      <div class="mt-2 mb-2">
        disable true
      </div>
      <div class="d-flex">
        <AuSwitchButton
          disabled
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          disabled="true"
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          disabled
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>


      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />

      <AuPagination
        :total-items="totalItems"
      />
    </div>
    <div class="card p-4 mt-4 down-block">
      <div class="fs24">
        {{ $t("TestPage") }}
      </div>
      <hr>
      <div class="mt-2 mb-2">
        default, disable false
      </div>
      <div class="d-flex">
        <AuSwitchButton
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>

      <hr>
      <div class="mt-2 mb-2">
        primary, disable false
      </div>
      <div class="d-flex">
        <AuSwitchButton
          type="primary"
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          type="primary"
          class="dist"
          @click="getMore"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          type="primary"
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>

      <hr>
      <div class="mt-2 mb-2">
        disable true
      </div>
      <div class="d-flex">
        <AuSwitchButton
          disabled
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          disabled="true"
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          disabled
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>


      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />

      <AuPagination
        :total-items="totalItems"
      />
    </div>
    <div class="d-flex flex-row gap-3 p-4 mt-4">
      <CapitalCardLoader
        v-for="item in [1,2,3,4]"
        :key="item"
        :is-investment="true"
        height="338px"
      />
    </div>
  </div>
</template>

<script>
import CapitalCardLoader from "@/components/capital/CapitalCardLoader";
import ProgressLoader from "@/loaders/progress-bar";

export default {
  name: "TestPage",
  components: {
    ProgressLoader,
    CapitalCardLoader
  },
  data() {
    return {
      page: 3,
      totalItems: 100,
      loading: false
    };
  },

  computed: {
    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    typeTitle() {
      return this.$t("transactions.titleType.spending");
    }
  },

  methods: {
    getMore() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

};
</script>

<style scoped lang="scss">
.dist{
  margin-right: 4px;
}
.down-block {
  background: #00000000;
  border: 1px solid var(--color-70)
}
</style>
