<template>
  <div class="transaction">
    <img
      class="t-image"
      :src="icon"
      alt="cryptoactive"
      @click="toToken"
    >

    <div class="transaction-info">
      <div class="transaction-info__item transaction-info__left">
        <AuEthAddress
          class="fs14 transaction-info__item-name a_link"
          :volume="addressEth"
          :name="name"
        />

        <div class="fs11 transaction-info__type uppercase">
          {{ prepareTransactionNotes }}
          <span
            v-if="projectId && (prepareTransactionNotes == 'Investment into' || prepareTransactionNotes == 'Refund for')"
          >
            <RouterLink
              :to="`/project-card/${projectId}`"
              class="link"
              target="_blank"
            >
              {{ projectName }}
            </RouterLink>
          </span>
          <span
            v-if="!isConfirmed"
            class="pending"
          >[pending]</span>
          <span
            v-if="projectId && prepareTransactionNotes == 'Distribution'"
          >for
            <RouterLink
              :to="`/project-card/${projectId}`"
              class="link"
              target="_blank"
            >
              {{ projectName }}
            </RouterLink>
          </span>
        </div>
      </div>

      <div class="transaction-info__item transaction-info__right">
        <div
          :single="!isOwnerTransaction"
          class="transaction-info__item-inner"
        >
          <div
            v-if="isOwnerTransaction"
            class="transaction-info__dot"
          >
            <div class="dot" />
          </div>
          <div class="transaction-info__right">
            <div
              class="fs14 transaction-info__amount"
            >
              {{ sign }}
              <AuDecimalStatic
                :value="amount"
                :decimal="decimals"
                :indent="decimals"
                :addition="mnemonic"
                smart-indent
              />
            </div>

            <div class="fs11 transaction-info__card-type">
              {{ cardType }}
            </div>
          </div>
        </div>

        <AuEthHash
          class="fs14 transaction-info__item-name a_link"
          :volume="hash"
        />
      </div>

      <!-- <div class="dot">
        <div class="dot2" />
      </div> -->
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

export default {
  props: {
    icon: {
      type: String,
      default: "/svg/token/criptoactive_icon.svg"
    },
    name: {
      type: String,
      required: true
    },
    addressFrom: {
      type: String,
      default: ""
    },
    addressTo: {
      type: String,
      default: ""
    },
    addressEth: {
      type: [String, null],
      default: "",
      required: true
    },
    hash: {
      type: String,
      required: true
    },
    transactionType: {
      type: String,
      required: true
    },
    amount: {
      type: [Number, String],
      required: true
    },
    cardType: {
      type: String,
      required: true
    },
    isRefill: {
      type: Boolean,
      default: true
    },
    isConfirmed: {
      type: Boolean,
      default: true
    },
    decimals: {
      type: Number,
      default: 0
    },
    mnemonic: {
      type: String,
      default: ""
    },
    isOwnerTransaction: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: Number,
      default: null
    },
    projectName: {
      type: String,
      default: ""
    }
  },

  computed: {
    ...mapGetters({
      tokens: "tokens"
    }),

    prepareTransactionNotes() {
      switch (this.transactionType) {
      case "investing":
        return "Investment into";
      case "capital_payment":
        return "Distribution";
      case "capital_refund":
        return "Refund for";
      default:
        return this.transactionType;
      }
    },

    sign() {
      return this.isRefill ? "+ " : "- ";
    },

    tokenId() {
      return _.find(this.tokens, { mnemonicEng: this.mnemonic })?.id;
    }
  },

  methods: {
    toToken() {
      this.$router.push({ name: "token info", params: { id: this.tokenId } });
    }
  }
};
</script>

<style scoped lang="scss">
.a_link {
  color: var(--text-color);
}
.link {
  color: var(--text-color);
  text-decoration: underline;
  margin-right: 4px;
}

.t-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
}
.transaction {
  display: flex;
  align-items: center;

  &-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    margin-left: 8px;

    &__type {
      color: var(--color-60);
    }

    &__card-type {
      color: var(--color-60);
      text-align: right;
    }

    .pending {
      color: red;
      // margin-left: 5px;
    }

    &__amount[isRefill="true"] {
      color: #5ac396;
    }

    &__amount[isRefill="false"] {
      color: #e94545;
    }

    &__item {
      display: flex;
      flex-direction: column;

      &-name {
        text-decoration: underline;
      }

      &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 130px;
        gap: 10px;

        @media screen and (max-width:400px) {
          align-items: baseline;
          min-width: 112px;
          gap: 5px;
        }

        &[single=true] {
          justify-content: end;
        }
      }
    }

    &__left {
      align-items: flex-start;
    }

    &__right {
      justify-content: end;
      align-items: center;
      flex-direction: row;
      white-space: nowrap;
      text-align: end;
    }
  }
}
.uppercase::first-letter {
  text-transform: uppercase;
}

.dot {
  width: 10px;
  height: 10px;
  background: #5fc396;
  border-radius: 50%;
}
</style>
