<template>
  <div
    class="panel"
    :light="isLightTheme"
  >
    <div class="top-panel__logo">
      <div class="logo">
        <div class="cursor">
          <AuIcon
            v-if="!showMenu"
            icon="toggler"
            @click="showMenu=true"
          />
          <AuIcon
            v-else
            icon="close"
            @click="showMenu=false"
          />
        </div>
        <div
          class="line"
        />
        <AuIcon icon="logo.v2" />
        <span
          v-if="showTestnet"
          class="testnet-mobile"
          :dark="!isLightTheme"
        >
          Testnet
        </span>
      </div>
    </div>

    <div class="top-panel__center">
      <div
        v-if="loginAsTester"
        class="login-as-tester"
      >
        TEST_ROLE enabled
      </div>
      <div
        v-if="loginAsAdmin"
        class="login-as-admin"
      >
        Logged as ADMIN
      </div>
    </div>

    <div class="top-panel__right">
      <ThemeSwitcher />
      <Profile />
    </div>
    <div
      v-if="showMenu"
      class="menu-mobile"
    >
      <RouterLink
        v-for="section in allSections"
        :key="section.name"
        :to="section.route"
        @click="showMenu=false"
      >
        <div class="item">
          <AuIcon :icon="section.icon" />

          <div class="text">
            <div
              :green="isCurrent(section.name)"
              class="title"
            >
              {{ section.title }}
            </div>
          </div>
        </div>
      </RouterLink>
    </div>
  </div>
</template>


<script>
import _ from "lodash";

import Profile from "./components/Profile.vue";
import ThemeSwitcher from "./components/ThemeSwitcher";
import { mapGetters } from "vuex";

export default {
  components: {
    Profile,
    ThemeSwitcher
  },

  data() {
    return {
      showMenu: false
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      profile: "profile/profile"
    }),

    loginAsAdmin() {
      return this.$cookies.get("is_login_in");
    },
    loginAsTester() {
      return _.some(this.profile.roles, role => role == "ROLE_TEST");
    },

    allSections() {
      const general = [
        { name: "dashboard", route: "/dashboard", icon: "grid_round_green", title: this.$t("router.pages.dashboard") },
        { name: "briefcase", route: "/briefcase", icon: "bag_green", title: this.$t("router.pages.briefcase") },
        { name: "projects", route: "/projects", icon: "projectsc", title: this.$t("router.pages.project") },
        { name: "transactions", route: "/transactions", icon: "swap_vertical_green", title: this.$t("router.pages.transactions") },
        { name: "blockscan", route: "/blockscan", icon: "blockscan", title: this.$t("router.pages.blockScan") }
      ];
      if (this.$auth.user().type == "issuer") {
        general.splice(2, 0,
          { name: "my projects", route: "/my-projects", icon: "folder", title: this.$t("router.pages.projects") },
          { name: "companies", route: "/companies", icon: "company", title: this.$t("router.pages.companies") });
      }
      return general;
    },

    path() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      return _.get(parent, "path");
    },

    showTestnet() {
      return process.env.VUE_APP_SHOW_TESTNET == 1;
    }
  },

  methods: {
    isCurrent(path) {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      return parent.name == path;
    }
  }
};
</script>

<style scoped lang="scss">
.panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 25px;
  height: 66px;

  border-bottom: 1px solid var(--color-90);

  @media screen and (max-width:640px) {
    border-color: var(--color-70);
  };

  &[light=true] {
    background-color: white;
  }
  .top-panel__center {
    display: flex;
    align-items: start;
    gap: 12px;
    width: 100%;

    @media screen and (max-width:1024px) {
      margin-left: 25px;
    };
  }
  .top-panel__right {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.current-page {
  display: block;
  font-size: 16px;

  @media screen and (max-width:1024px) {
    display: none;
  }
}

.top-panel__logo {
  height: 66px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
}

.logo {
  height: 66px;
  display: none;

  @media screen and (max-width:1024px) {
    display: flex;
    align-items: center;
    >div:first-child{
      width: 46px;
    }
  }
}

.line {
  height: 100%;
  border-right: 1px solid var(--color-90);
  margin-right: 22px;
}

.cursor {
  >img{
    cursor: pointer;
  }
}

.section {
  font-size: 14px;
  text-decoration: none;
  color: var(--text-color);
  white-space: nowrap;

  &:hover {
    color: var(--color-60);
  }
}

.sections {
  display: flex;
  flex-direction: row;
  gap: 50px;

  @media screen and (max-width:1024px) {
    display: none;
  }

  &-mobile {
    display: none;
    position: relative;

    @media screen and (max-width:1024px) {
      display: block;
    }

    @media screen and (max-width:640px) {
      display: none;
    }
  }

  &-selector {
    margin-left: 10px;
  }

  &-menu {
    position: fixed;
    top: 60px;
    z-index: 100;
    background-color: var(--menu-color);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 17px;
  }
}

.router-link-active {
  color: #279063;
}
.testnet-mobile {
  margin-top: -14px;
  margin-left: 4px;
  font-size: 12px;
  color: #00000060;
  &[dark=true] {
    color: #FFFFFF80;
  }
}

.menu-mobile {
  display: none;
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
  height: calc(100% - 69px);
  background: var(--main-color);
  border-bottom: 1px solid var(--color-90);
  z-index: 2000;
  padding: 25px;
  @media screen and (max-width: 1024px){
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  .text {
    display: flex;
    flex-direction: column;
  }

  .item {
    display: flex;
    align-items: start;
    gap: 15px;
  }

  .title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 2px;
    text-decoration: none;
    color: var(--text-color);
    &[green=true] {
      color: #279063
    }
  }

  .sub-title {
    font-size: 12px;
    line-height: 12px;
    color: var(--color-50)
  }
}

.login-as-admin {
  color: #121212;
  font-size: 16px;
  background: red;
  padding: 2px 12px;
  border-radius: 4px;
}
.login-as-tester {
  color: #121212;
  font-size: 16px;
  background: yellow;
  padding: 2px 12px;
  border-radius: 4px;
}
</style>
