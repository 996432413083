<template>
  <div :style="style">
    <label
      v-if="selectLabel"
      class="au-select-label"
      :disabled="disabled"
    > {{ selectLabel }}</label>
    <VueMultiselect
      :model-value="currentValue"
      :placeholder="placeholder"
      :options="options"
      :track-by="trackBy"
      :label="label"
      :show-labels="false"
      :allow-empty="allowEmpty"
      class="select"
      :disabled="disabled"
      :class="{'req-error' : required && reqError}"
      @update:model-value="input"
    >
      <template #caret="{ toggle }">
        <AuIcon
          icon="chevron_down"
          class="select__chevron-icon"
          @mousedown.prevent.stop="toggle()"
        />
      </template>

      <template
        v-if="image"
        #singleLabel="{ option }"
      >
        <div class="d-flex align-items-center">
          <img
            :src="option.image"
            class="picture"
          >
          {{ option.label }}
        </div>
      </template>

      <template
        v-if="image"
        #option="{ option }"
      >
        <div class="d-flex align-items-center">
          <img
            :src="option.image"
            class="picture"
          >
          {{ option.label }}
        </div>
      </template>

      <template
        v-if="clearable && value"
        #clear="props"
      >
        <div
          class="multiselect__clear"
          @mousedown.prevent.stop="clear(props)"
        >
          ×
        </div>
      </template>

      <template #noResult>
        {{ $t('auSelect.noResult') }}
      </template>
      <template #noOptions>
        {{ $t('auSelect.noOptions') }}
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";

import _ from "lodash";
import AuIcon from "./AuIcon.vue";

export default {
  name: "AuSelect",

  components: {
    VueMultiselect,
    AuIcon
  },

  props: {
    placeholder: {
      type: String,
      default: ""
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    // массив вида [{display: "testDisplay", value: "testValue"}]
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Boolean, Number],
      default: ""
    },
    trackBy: {
      type: String,
      default: "value"
    },
    label: {
      type: String,
      default: "display"
    },
    selectLabel: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    image: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    borderRadius: {
      type: [Number, String],
      default: 12
    }
  },

  emits: ["input"],

  computed: {
    currentValue() {
      return _.find(this.options, option => option[this.trackBy] == this.value);
    },

    reqError() {
      return _.isEmpty(this.currentValue);
    },

    style() {
      return {
        "--border-radius": this.borderRadius + "px"
      };
    }
  },

  methods: {
    input(value) {
      this.$emit("input", value?.value);
    },
    clear() {
      this.$emit("clearValue");
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
.picture{
  height: 25px;
  margin-right: 5px;
}
.multiselect {
  width: auto;
  cursor: pointer;
  &--disabled {
    background: none;
    opacity: 1;
    .multiselect__tags {
      border: 1px solid var(--color-40) !important;
      .multiselect__placeholder {
        color: var(--color-40);
      }
    }
    .icon.select__chevron-icon {
      display: none;
    }
  }
  &.req-error {
    .multiselect__tags {
      border: 1px solid #ff000069;
    }

    .multiselect__content-wrapper {
      border-color: #ff000069;
    }
  }

  .select__chevron-icon {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 10px;
    top: 3px;
    text-align: center;
    padding-top: 13px;
    color: #505f79;
    font-size: 12px;
  }

  &__tags {
    background: #fff0;
    width: auto;
    height: 44px;
    border: 1px solid var(--color-70);
    border-radius: var(--border-radius);
    // border-bottom-left-radius: 40px !important;
    // border-bottom-right-radius: 40px !important;
    padding: 8px 40px 0px 20px;
  }

  &__content-wrapper {
    background: var(--main-color);
    border-color: var(--color-70);
    color: var(--text-color);
    font-size: 13px;
  }

  &__placeholder {
    font-size: 13px;
    color: var(--color-00);
    margin-bottom: 0px;
    padding-top: 4px;
  }

  &__input,
  &__single {
    background: #fff0;
    font-size: 13px;
    color: var(--text-color);
    padding-top: 4px;
    padding-left: 0px;
  }

  &__clear {
    position: absolute;
    font-size: 13px;
    right: 40px;
    top: calc(50% - 19.5px / 2);
    z-index: 10;
    color: var(--color-60)
  }

  &__tag {
    color: var(--text-color);
    margin: 2px;
    padding: 2px 26px 2px 4px;

    &-icon {
      border-radius: 3px;

      line-height: 17px;

      &, &:focus, &:hover, &:active {
          &:after {
            color: #0052cc;
          }
      }

      &:focus {
        background: inherit;
      }

      &:hover {
        background: #d1e5ff;
      }

      &:active {
        background: #caddf8;
      }
    }
  }

  &__option {
    line-height: 1;
    padding: 7px 9px;
    min-height: auto;
    font-size: inherit;
    color: var(--text-color);
    font-weight: inherit;

    &:hover {
      background: var(--color-90);
    }

    &:active {
      background: #ccdffa;
    }

    &--highlight {
      background: var(--color-90);
      color: inherit;
    }

    &--selected {
      background: #deebff;
      color: #000000;

      &.multiselect__option--highlight {
        background: #ccdffa;
        color: inherit;
      }

      &:hover {
        background: #ccdffa;
      }
    }
  }
}
.au-select-label {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--color-50);
  &[disabled="true"] {
    color: var(--color-40);
  }
}
</style>